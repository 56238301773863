import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useFetch from "use-http";

import PageQuery, { getQueryStringFromPageQuery } from "common/types/PageQuery";
import * as ROUTES from "common/routes.constants";
import { PagedResponse } from "common/types/PagedResponse";
import isShortid from "utils/isShortid";
import Context from "./context";
import { Project, UpdateProjectDTO } from "./types";
import useOrganizations from "../Organizations/useOrganizations";

export const initialPageQuery: PageQuery = {
	limit: 20,
	page: 1,
	search: {
		text: "",
		fields: [],
	},
	sort: "-meta.tsModified",
};

const ProjectsProvider: React.FC = ({ children }) => {
	const { pathname, search } = useLocation();
	const history = useHistory();

	const {
		current: currentOrganization,
		loaded: currentOrganizationLoaded,
		currentOrganizationPlan,
	} = useOrganizations();

	const [query, setQuery] = useState<PageQuery>(initialPageQuery);
	const [pagedResponse, setPagedResponse] = useState<
		PagedResponse<Project>
	>();
	const append = useRef<boolean>(false);

	const [projects, setProjects] = useState<Project[]>([]);
	const [current, setCurrent] = useState<Project | undefined>();

	const [wishlists, setWishlists] = useState<any[] | null>(null);

	const { get, response, loading, error } = useFetch(
		"projects/members/getByProjects",
		{},
	);
	const { get: getCurrent, response: responseCurrent } = useFetch(
		`/projects/${current?._id}`,
	);
	const { put: putCurrent, response: responsePutCurrent } = useFetch(
		`/projects/${current?._id}`,
	);
	const { get: getByShortid, response: responseGetByShortid } = useFetch(
		`/projects`,
	);

	const fetchProjects = async () => {
		const queryString = getQueryStringFromPageQuery(query);
		await get(`?${queryString}`);
		if (response.ok) {
			const results = append.current
				? [...projects, ...response.data.results]
				: response.data.results;
			setProjects(results);
			setPagedResponse(response.data);
			append.current = false;
		}
	};

	const { get: getWishlists, response: wishlistsResponse } = useFetch(
		"wishlist/scene",
		{},
	);

	const fetchWishlists = async (sceneId : string | undefined) => {
		if (!sceneId) {
			return;
		}
		setWishlists(null);

		await getWishlists(sceneId);
		if (wishlistsResponse.ok) {
			setWishlists(wishlistsResponse.data);
		}
	};

	// Try to find current project in the projects list, or by fetching by shortid
	const setCurrentProject = async (shortid?: string) => {
		logger.debug("[ProjectsProvider] setCurrentProject", {
			shortid,
			isShortid: isShortid(shortid),
		});

		if (!isShortid(shortid)) {
			setCurrent(undefined);

			if (
				/* TODO: check if it's still needed. */
				/* (pathname.includes(ROUTES.PROJECTS) &&
					pathname !== "/admin/manage-projects") || */
				// In case user is on /expired-subscription path and change to another organization
				pathname.includes(ROUTES.EXPIRED_SUBSCRIPTION)
			) {
				// Organization has changed -> redirect
				history.push({
					pathname: `/${ROUTES.PROJECTS}`,
					search,
				});
			}

			return;
		}

		const targetProject = projects?.find((p) => p.shortid === shortid);
		if (targetProject) {
			setCurrent(targetProject);
			return;
		}

		await getByShortid(shortid);
		if (responseGetByShortid.ok) {
			setCurrent(responseGetByShortid.data);
			setProjects([...projects, responseGetByShortid.data]);
		}
	};

	const refreshCurrent = async () => {
		await getCurrent();
		if (responseCurrent.ok) {
			const updatedProject: Project = responseCurrent.data;
			setCurrent(updatedProject);

			const updatedProjects = projects.map((p) =>
				p._id === updatedProject?._id ? updatedProject : p,
			);
			setProjects(updatedProjects);
		}
	};

	const updateCurrent = async (updateDto: UpdateProjectDTO) => {
		await putCurrent(updateDto);

		const updatedCurrent = {
			...current,
			meta: {
				...current?.meta,
				...updateDto.meta,
			},
		} as Project;

		if (responsePutCurrent.ok) {
			setCurrent(updatedCurrent);
			const updatedProjects = projects.map((p) =>
				p._id === updatedCurrent?._id ? updatedCurrent : p,
			);
			setProjects(updatedProjects);
		}
	};

	const loadMore = () => {
		if (!pagedResponse?.hasNextPage) {
			return;
		}

		append.current = true;
		setQuery((previousQuery) => ({
			...previousQuery,
			page: previousQuery.page + 1,
		}));
	};

	// Handle Organization change
	useEffect(() => {
		if (currentOrganization && currentOrganizationLoaded) {
			fetchProjects();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentOrganization, currentOrganizationLoaded]);

	// Handle Location change
	useEffect(() => {
		if (!currentOrganizationLoaded) {
			return;
		}

		const urlChunks = pathname.split("/");
		const [, requestedUrl] = urlChunks.filter((chunk) => chunk.length > 1);

		if (
			(!current && currentOrganizationPlan?.isExpired === false) ||
			current?.shortid !== requestedUrl
		) {
			setCurrentProject(requestedUrl);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, projects, currentOrganizationPlan]);

	useEffect(() => {
		if (currentOrganization && currentOrganizationLoaded) {
			fetchProjects();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	if (error) {
		logger.debug("[ProjectsProvider] Critical error", error);
		return <p>Error: {JSON.stringify(error)}</p>;
	}

	return (
		<Context.Provider
			value={{
				projects,
				current,
				loading,
				error,
				query,
				pagedResponse,
				setCurrentProject,
				setQuery,
				loadMore,
				refreshCurrent,
				updateCurrent,

				wishlists,
				fetchWishlists
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default ProjectsProvider;
