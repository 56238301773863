import { useState, useEffect } from "react";

/**
 * @example
 * const DummyComponent = () => {
 * 		const ref = useRef()
 * 		const isVisible = useOnScreen(ref)
 *
 * 		return <div ref={ref}>{isVisible && `Yep, I'm on screen`}</div>
 * }
 */
export default function useOnScreen(ref: React.MutableRefObject<any>) {
	const observerOptions = {
		// root: document.querySelector('#scrollArea'),
		rootMargin: "200px",
		threshold: 1.0,
	};

	const [isIntersecting, setIntersecting] = useState(false);

	useEffect(() => {
		if (!ref.current) {
			return;
		}
		const observer = new IntersectionObserver(
			([entry]) => setIntersecting(entry.isIntersecting),
			observerOptions,
		);
		observer.observe(ref.current);
		// Remove the observer as soon as the component is unmounted
		return () => {
			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isIntersecting;
}
