import React from "react";
import styled from "styled-components/macro";
import { Spinner } from "@hegias/ui-components";

interface LoadMoreContentProps {
	loading?: boolean;
	text?: string;
	disabled?: boolean;
	hasMore: boolean;
	loadMore: (...args: any) => void;
}

const LoadMoreButton = React.forwardRef(
	(props: LoadMoreContentProps, ref: React.ForwardedRef<any>) => {
		const {
			loading = false,
			text = "Load more",
			disabled = false,
			hasMore,
			loadMore,
		} = props;
		const displayText = hasMore ? text : "";

		return (
			<div ref={ref} style={{ marginBottom: "20px" }}>
				<StyledLoadMoreButton
					disabled={disabled || loading}
					onClick={loadMore}
				>
					{loading ? <Spinner maxSize="30px" /> : displayText}
				</StyledLoadMoreButton>
			</div>
		);
	},
);

const StyledLoadMoreButton = styled.button`
	background: none;
	width: 100%;
	height: 50px;
	padding: 0;
	border: none;
	cursor: pointer;
	margin-bottom: 20px;
	margin-top: 20px;
	outline: none;
`;

export default LoadMoreButton;
