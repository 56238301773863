import React from "react";
import styled from "styled-components/macro";
import { Spinner } from "@hegias/ui-components";

export interface SpinnerWithMessageProps
	extends React.HTMLAttributes<HTMLDivElement> {
	message?: string;
}

const SpinnerWithMessage = ({ children, message }: SpinnerWithMessageProps) => {
	return (
		<StyledContainer>
			<Spinner maxSize="80px" />
			<h2>{children || message}</h2>
		</StyledContainer>
	);
};

const StyledContainer = styled.div<SpinnerWithMessageProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	h2 {
		font-size: 25px;
		color: #91a436;
		font-weight: normal;
		max-width: 350px;
		text-align: center;
	}
`;

export default SpinnerWithMessage;
