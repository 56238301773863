import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import {
	Card,
	Container,
	ContainerWithSidebar,
	CardSkeleton,
	Icon,
	IconButton,
	Input,
	Button,
} from "@hegias/ui-components";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";

import { PROJECTS, SCENES } from "common/routes.constants";
import useProjects from "contexts/Projects/useProjects";
import { useFormatDistance, useFormat } from "hooks/useDateFns";
import { useModal } from "contexts/Modal";
import { useToaster } from "contexts/Toaster";
import { useScenes } from "contexts/Scenes";
import { Scene } from "contexts/Scenes/types";
import useDebounce from "hooks/useDebounce";
import UploadModal from "components/UploadModal/UploadModal";
import isSceneProcessing from "utils/isSceneProcessing";
import useOnScreen from "hooks/useOnScreen";
import LoadMoreButton from "components/LoadMoreButton/LoadMoreButton";
import useCan from "hooks/useCan";
import { useOrganizations } from "contexts/Organizations";
import useFileSizeLimit from "hooks/useFileSizeLimit";
import ProjectMenu from "../../components/ProjectMenu";
import CopyScene from "./components/CopyScene";
import PageHeader from "./components/PageHeader";
import { StyledProjectLayout } from "./components/Project.styles";
import AsideProject from "./components/AsideProject";
import BCFLogin from "./components/BCFLogin";
import AddCardPlaceholder from "../../components/AddCardPlaceholder";
import FilterAndSortModal from "./components/FilterAndSortModal";
import EditScene from "./components/EditScene";
import MoveScene from "./components/MoveScene";
import { AsideMenu } from "../../../../components";
import HegiasHeadsetIconWhite from "../../../../assets/illustrations/HegiasHeadsetIconWhite.png";

export interface SearchInputProps {
	open?: boolean;
	inputProps?: any;
}

const Project = () => {
	const history = useHistory();
	const { current, loading } = useProjects();
	const { member } = useOrganizations();
	const {
		scenes,
		query,
		pagedResponse,
		setQuery,
		loadMore,
		fetchScenes,
	} = useScenes();
	const formatDistance = useFormatDistance();
	const formatDate = useFormat();

	const { fileSizeLimit } = useFileSizeLimit();

	const { t } = useTranslation();
	const { search } = useLocation();

	const modal = useModal();
	const toaster = useToaster();
	const { post, response } = useFetch();

	const [selectedScene, setSelectedScene] = useState<Scene | null>(null);

	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showCopySceneModal, setShowCopySceneModal] = useState(false);
	const [showEditSceneModal, setShowEditSceneModal] = useState(false);
	const [showMoveSceneModal, setShowMoveSceneModal] = useState(false);
	const [filterAndSortOpen, setFilterAndSortOpen] = useState(false);

	const [searchText, setSearchText] = useState<string>(query.search.text);
	const [searchOpen, setSearchOpen] = useState<boolean>(false);
	const [projectMemberRole, setProjectMemberRole] = useState<
		string | undefined
	>(undefined);
	const searchInputField = useRef<HTMLInputElement>(null);

	const scrollContainer = useRef<HTMLElement>();
	const lastKnownScrollPosition = useRef<number>(0);
	const loadMoreRef = useRef<HTMLDivElement>(null);
	const loadMoreVisible = useOnScreen(loadMoreRef);

	// TODO: Include check `isSelfMade`: https://support.hegias.com/hc/de/articles/360006633737-Berechtigungen
	const canEditAndDelete = useCan(["junior"]);

	const debouncedQueryUpdate = useDebounce(
		(nextValue: string) =>
			setQuery({
				...query,
				search: {
					text: nextValue,
					fields: ["meta.name", "meta.description"],
				},
			}),
		500,
	);

	const handleSearchTextChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { value: nextValue } = event.target;

		setSearchText(nextValue);
		debouncedQueryUpdate(nextValue);
	};

	const deleteSceneModal = (id: string) => {
		modal.confirm({
			title: t("app.confirmDelete.modal.title"),
			content: t("app.confirmDelete.modal.content"),
			onOk: () => deleteScene(id),
		});
	};

	const deleteScene = async (id: string) => {
		const res = await post(`/projects/${current?._id}/scenes/${id}/trash`, {
			isTrash: true,
		});

		const sceneName = scenes.find(({ _id }) => {
			return _id === id;
		})?.meta.name;

		if (response.ok) {
			toaster.success({
				message: t("app.global.msg.title.success"),
				description: t("msg.scene.putInTrash", { var: sceneName }),
			});
		} else {
			toaster.error({
				message: t("app.global.msg.title.note"),
				description: res.translatedMessage,
			});
		}
		setQuery({ ...query });
	};

	const handleLoadMore = async () => {
		if (loading || !pagedResponse?.hasNextPage) {
			return;
		}

		// Update last known position when "load more button" is visible
		lastKnownScrollPosition.current =
			loadMoreRef.current?.parentElement?.scrollTop || 0;

		await loadMore();
	};

	useEffect(() => {
		if (loadMoreVisible) {
			// Save ref to scrollContainer
			if (!scrollContainer.current) {
				scrollContainer.current = loadMoreRef.current
					?.parentElement as HTMLElement;
			}

			handleLoadMore();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadMoreVisible]);

	useEffect(() => {
		if (current) {
			fetchScenes();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current]);

	useEffect(() => {
		if (current && member) {
			const projectMember: any = current.members.find(
				(m: any) => m.id === member.user._id,
			);

			if (projectMember) {
				setProjectMemberRole(projectMember.role);
			} else {
				// Use has no permission to see this page.
				history.push(`/${PROJECTS}/`, search);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current, member]);

	useLayoutEffect(() => {
		if (scrollContainer.current && lastKnownScrollPosition.current > 0) {
			scrollContainer.current.scrollTop = lastKnownScrollPosition.current;
		}
	}, [scrollContainer.current?.scrollHeight]);

	const handleCardOnclick = (scene: Scene) => {
		if (isSceneProcessing(scene)) {
			return;
		}

		history.push(
			`/${PROJECTS}/${current?.shortid}/${SCENES}/${scene.shortid}`,
			search,
		);
	};

	const renderSceneData = (sceneData: Scene) => {
		const isOnGoing = sceneData?.beautify?.status === "ongoing";
		const isBeautified = sceneData?.beautify?.status === "successful";

		const createDate = formatDate(
			new Date(sceneData.meta.tsCreated),
			"dd.MM.yyyy - h:m:s",
		);
		const flag = {
			state: isOnGoing || isBeautified,
			text: isBeautified
				? t("app.Project.card.subtitle.beautifyScene")
				: t("app.Project.card.flag.renderingProcess"),
		};
		const description =
			isOnGoing || isBeautified
				? t("app.Project.card.flag.availableOnStandAlone")
				: sceneData.meta.description;

		const modifiedDate = formatDistance(
			new Date(sceneData.meta.tsModified ?? sceneData.meta.tsCreated),
			new Date(),
		);

		const subtitle =
			isOnGoing || isBeautified
				? `${t("app.global.bcf.label.created")} ${createDate}`
				: `${t("app.Project.card.updated")} ${modifiedDate}`;

		return {
			subtitle,
			updateAt: modifiedDate,
			createdAt: createDate,
			description,
			flag,
			isOnGoing,
			isBeautified,
		};
	};

	const searchStartAdornment = () => {
		return searchOpen ? (
			<IconButton onClick={() => setSearchOpen(false)}>
				<Icon
					name="ArrowLeftIcon"
					color="grey800"
					hoverColor="primary"
				/>
			</IconButton>
		) : undefined;
	};

	return (
		<StyledProjectLayout>
			<ContainerWithSidebar
				collapsible={false}
				sidebar={
					<AsideMenu>
						<AsideProject />
						<BCFLogin projectMemberRole={projectMemberRole} />
					</AsideMenu>
				}
				size="300px"
			>
				<StyledContainer>
					<PageHeader
						title="Scenes"
						backEvent={() => {
							history.push(`/${PROJECTS}`);
						}}
						dialogs={
							<FilterAndSortModal
								formData={query}
								tagKind="project"
								open={filterAndSortOpen}
								onCancel={() => setFilterAndSortOpen(false)}
								onOk={setQuery}
							/>
						}
					>
						{canEditAndDelete && (
							<IconButton
								tooltipProps={{
									text: t("app.Project.btn.addScene"),
									position: "bottom",
								}}
								onClick={() => setShowUploadModal(true)}
							>
								<Icon
									name="PlusIcon"
									color="grey800"
									hoverColor="primary"
								/>
							</IconButton>
						)}
						<IconButton
							tooltipProps={{
								text: t("app.global.btn.filter"),
								position: "bottom",
							}}
							onClick={() => setFilterAndSortOpen(true)}
						>
							<Icon
								name="FilterIcon"
								color="grey800"
								hoverColor="primary"
							/>
						</IconButton>

						<StyledSearchIconContainer
							badge={
								searchText !== ""
									? {
											status: "info",
									  }
									: {}
							}
							tooltipProps={{
								text: t(
									"app.global.searchbar.placeholder.search",
								),
								position: "bottom",
							}}
							onClick={() => {
								setSearchOpen(!searchOpen);
								searchInputField?.current?.focus();
							}}
						>
							<Icon
								name="SearchIcon"
								color="grey800"
								hoverColor="primary"
							/>
						</StyledSearchIconContainer>

						<StyledInput
							inputProps={{
								ref: searchInputField,
							}}
							open={searchOpen}
							placeholder={t(
								"app.global.searchbar.placeholder.search",
							)}
							value={searchText}
							onChange={handleSearchTextChange}
							startAdornment={searchStartAdornment()}
							clearableInput
						/>
					</PageHeader>
					<StyledCardsWrapper>
						{!loading && !scenes?.length && canEditAndDelete && (
							<AddCardPlaceholder
								handleClick={() => setShowUploadModal(true)}
							>
								{t("app.Project.card.addScene")}
							</AddCardPlaceholder>
						)}
						{loading &&
							!scenes?.length &&
							[...Array.from(Array(10).keys())].map((id) => {
								return <CardSkeleton key={id} />;
							})}

						{current &&
							scenes?.map((scene: any) => {
								let thumbnailImage = `${process.env.REACT_APP_AWS_S3_URL}/scenes/${scene.shortid}/${scene.meta.thumbnail}`;

								const failedProcesses = scene?.pipelineStatus.some(
									(file: any) => file.processing === "failed",
								);

								if (failedProcesses) {
									thumbnailImage =
										"/images/HegiasIlluBCFtopicDefaultThumbnail.jpg";
								}

								const sceneData = renderSceneData(scene);
								const cardButton = !sceneData.isBeautified ? (
									<StyledButton
										type="button"
										onClick={() => {
											handleCardOnclick(scene);
										}}
									>
										{t("app.Project.card.openscene")}
									</StyledButton>
								) : (
									<StyledVRIllustration>
										<img
											src={HegiasHeadsetIconWhite}
											alt="VRHeadset icon"
										/>
									</StyledVRIllustration>
								);

								return (
									<Card
										key={scene.shortid}
										id={scene.shortid}
										title={scene.meta.name}
										flag={sceneData.flag}
										content={cardButton}
										subtitle={sceneData.subtitle}
										description={sceneData.description}
										onClick={() => {
											if (
												sceneData.isOnGoing ||
												sceneData.isBeautified
											) {
												return false;
											}
											handleCardOnclick(scene);
										}}
										image={thumbnailImage}
										loading={
											isSceneProcessing(scene) ||
											scene?.beautify?.status ===
												`ongoing`
										}
										extra={
											canEditAndDelete ? (
												<ProjectMenu
													button={
														<Icon
															name="EllipsisVerticalIcon"
															color="grey800"
														/>
													}
													content={[
														{
															label:
																"app.global.general.copy",
															action: () => {
																setSelectedScene(
																	scene,
																);
																setShowCopySceneModal(
																	true,
																);
															},
														},
														{
															label:
																"app.global.general.delete",
															action: () =>
																deleteSceneModal(
																	scene._id,
																),
														},
														{
															label:
																"app.global.general.edit",
															action: () => {
																setSelectedScene(
																	scene,
																);
																setShowEditSceneModal(
																	true,
																);
															},
														},
														{
															label:
																"app.global.general.move",
															action: () => {
																setSelectedScene(
																	scene,
																);
																setShowMoveSceneModal(
																	true,
																);
															},
														},
													]}
												/>
											) : (
												false
											)
										}
									/>
								);
							})}
					</StyledCardsWrapper>
					<LoadMoreButton
						ref={loadMoreRef}
						hasMore={pagedResponse?.hasNextPage || false}
						loading={loading}
						text="Load more projects"
						loadMore={handleLoadMore}
					/>
				</StyledContainer>
			</ContainerWithSidebar>
			<CopyScene
				visible={showCopySceneModal}
				onCancel={() => setShowCopySceneModal(false)}
				callback={() => setQuery({ ...query })}
				projectId={current?._id ?? ""}
				sceneId={selectedScene?._id ?? ""}
			/>
			<EditScene
				visible={showEditSceneModal}
				onCancel={() => setShowEditSceneModal(false)}
				callback={() => setQuery({ ...query })}
				projectId={current?._id ?? ""}
				scene={selectedScene}
			/>
			<MoveScene
				visible={showMoveSceneModal}
				onCancel={() => setShowMoveSceneModal(false)}
				callback={() => setQuery({ ...query })}
				projectId={current?._id ?? ""}
				sceneId={selectedScene?._id ?? ""}
			/>
			<UploadModal
				visible={showUploadModal}
				onCancel={() => setShowUploadModal(false)}
				projectId={current?._id ?? ""}
				callback={(res) => {
					if (res.ok) {
						toaster.success({
							message: t("app.global.msg.title.success"),
							description: res.data.translatedMessage,
						});
					} else {
						toaster.error({
							message: t("app.global.msg.title.note"),
							description: res.data.translatedMessage,
						});
					}
					setQuery({ ...query });
				}}
				kind="Scene"
				maxSize={fileSizeLimit}
			/>
		</StyledProjectLayout>
	);
};

const StyledSearchIconContainer = styled(IconButton)`
	@media (min-width: 999px) {
		display: none;
	}
`;

const StyledContainer = styled(Container)`
	flex-wrap: wrap;
	align-content: flex-start;
	height: initial;
	background-color: ${({ theme }) => theme.colors.grey200};
	&.-webkit-scrollbar {
		display: none;
	}
	padding: 30px;
	overflow: auto;
`;

const StyledCardsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
	grid-auto-rows: 1fr;
	gap: 25px;
	width: 100%;
	margin-bottom: 25px;
`;

const StyledInput = styled(Input)<SearchInputProps>`
	position: relative;
	width: 300px;

	@media (max-width: 999px) {
		display: none;
		z-index: 1;
		${(props) =>
			props.open === true &&
			`
			display: flex;
			width: 100%;
			position: absolute;
			> span button{
				margin: 0;
			}
		`}
	}
`;

const StyledButton = styled(Button)`
	&&& {
		background-color: rgba(255, 255, 255, 0.3);
		color: ${({ theme }) => theme.colors.white};
		height: initial;
	}
`;

const StyledVRIllustration = styled.div`
	cursor: default;
	height: 100%;
	width: 100%;
	img {
		max-width: 80px;
		margin-bottom: 15px;
	}
`;

export default Project;
