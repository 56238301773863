import { Modal } from "antd";
import styled from "styled-components/macro";
import React from "react";
import { Icon } from "@hegias/ui-components";

export interface HegiasModalProps extends React.ComponentProps<typeof Modal> {
	size?: string;
	justifycontent?: string;
}

export const StyledModal = styled(Modal)`
	max-width: ${({ size }: HegiasModalProps) => {
		if (size === "large") {
			return "1000px";
		}
		if (size === "medium") {
			return "750px";
		}
		if (size === "small") {
			return "350px";
		}
	}};
	${({ size }: HegiasModalProps) =>
		(size === "large" || size === "medium") &&
		`
        width: 90% !important;
        content:"${size}";
    `}
	.ant-modal-body {
		overflow: hidden;
		h5 {
			color: ${({ theme }) => theme.colors.grey800};
			font-weight: ${({ theme }) => theme.typography.weight.bold};
		}
	}
	.ant-modal-header {
		.ant-modal-title {
			color: ${({ theme }) => theme.colors.grey800};
			font-weight: ${({ theme }) => theme.typography.weight.bold};
		}
	}
	.ant-modal-close-x {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.ant-modal-footer {
		display: flex;
		justify-content: ${({ justifycontent }: HegiasModalProps) => {
			if (justifycontent === "left") {
				return "left";
			}
			return "flex-end";
		}};
	}
`;

const HegiasModal = (props: HegiasModalProps) => {
	return (
		<StyledModal
			{...props}
			closeIcon={
				<Icon name="CloseIcon" size="20px" hoverColor="primary" />
			}
		/>
	);
};

export default HegiasModal;
