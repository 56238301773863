import React from "react";
import styled from "styled-components/macro";
import { Icon } from "@hegias/ui-components";

interface AddCardPlaceholderProps {
	children: React.ReactNode;
	icon?: React.ReactNode;
	handleClick: () => void;
}

const AddCardPlaceholder: React.FC<AddCardPlaceholderProps> = ({
	handleClick,
	icon,
	children,
}) => {
	return (
		<StyledAddCardPlaceholderContainer onClick={handleClick}>
			<div className="content">
				{icon || (
					<Icon
						name="PlusIcon"
						size="50px"
						color="secondary"
						hoverColor="secondary"
					/>
				)}
				{children}
			</div>
		</StyledAddCardPlaceholderContainer>
	);
};

const StyledAddCardPlaceholderContainer = styled.header`
	width: 100%;
	height: 300px;
	background-color: white;

	display: grid;
	justify-items: center;
	align-items: center;

	.content {
		transition: all 0.3s;
		opacity: 0.5;
		display: grid;
		justify-items: center;
		align-items: center;
	}

	&:hover {
		cursor: pointer;
		.content {
			opacity: 1;
		}
	}
`;

export default AddCardPlaceholder;
