import { useState, useEffect } from "react";

import { useOrganizations } from "contexts/Organizations";

const useFileSizeLimit = () => {
	const { currentOrganizationPlan } = useOrganizations();

	const [fileSizeLimit, setFileSizeLimit] = useState(0);

	useEffect(() => {
		const customDefaultLimit = 999000000;

		if (
			currentOrganizationPlan?.subscriptionPlan?.maxUploadFileSize
				.number ||
			currentOrganizationPlan?.subscriptionPlan?.maxUploadFileSize
				.number === 0
		) {
			setFileSizeLimit(
				currentOrganizationPlan.subscriptionPlan.maxUploadFileSize
					.number,
			);
		} else {

			// @todo get custom maxUploadFileSize value from the backend (in custom object in DB plan record)

			// else
			setFileSizeLimit(customDefaultLimit);
		}
	}, [currentOrganizationPlan]);

	return { fileSizeLimit };
};

export default useFileSizeLimit;
