import React from "react";
import styled from "styled-components/macro";
import { Container } from "@hegias/ui-components";

interface InnerProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Inner = ({ children }: InnerProps) => {
	return <StyledInner>{children}</StyledInner>;
};

const StyledInner = styled(Container)`
	overflow: initial;
	padding: 0px 30px 60px 30px;

	@media (max-width: 767px) {
		padding: 0px 15px 60px 15px;
	}
`;
