export const EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,64}$/i;
export const NUMBERS_ONLY = /^\d+$/;
export const PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_'"+={};:<>,.?/\-\\])(?=.{8,})/;
// eslint-disable-next-line no-misleading-character-class
export const FILENAME = /^(?!.{256,})(?!(aux|clock\$|con|nul|prn|com[1-9]|lpt[1-9])(?:$|\.))[^ ][\u00C4\u0041\u0308 \u00e4\u0061\u0308 \u00CB\u0045\u0308 \u00EB\u0065\u0308 \u00CF\u0049\u0308 \u00EF\u0069\u0308 \u00d6\u004f\u0308 \u00f6\u006f\u0308 \u00dc\u0055\u0308 \u00fc\u0075\u0308 \u00df .\w-$()+=[\];#@~,&amp;']+[^. ]$/i;

// eslint-disable-next-line no-useless-escape
export const SUBDOMAIN = /^[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?$/;

// Any digit with optional leading "+" sign
export const PHONE_NUMBER = /^(?:[+\d].*\d|\d)$/;

export const ALPHANUMERIC_WITH_SPACES = /^[a-z\d\s]+$/i;
