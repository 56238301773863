export enum StreamingState {
	CLOSED_ON_AFK_ERROR = "closedOnAfkError",
	INITIALIZING_STREAMING = "initializingStreaming",
	LOADING_SCENE = "loadingScene",
	READY_TO_INIT = "readyToInit",
	SCENE_LOADED = "sceneLoaded",
	VIDEO_INITIALIZED = "videoInitialized",
	LOADING = "loading",
	WELCOME = "welcome",
}

export const HEGIAS_ORG_ID = "664db3a67a2ac29487b4c768";
export const BMS_ORG_ID = "5c4868857922384fb456af48";

export const WEBSOCKET_EVENT_CODE_NO_STATUS = 1005;
