import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Button, Typography } from "@hegias/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import useFetch from "use-http";

import { Modal } from "components";
import { useProjects } from "contexts/Projects";
import { useToaster } from "contexts/Toaster";
import useOnScreen from "hooks/useOnScreen";
import LoadMoreButton from "components/LoadMoreButton/LoadMoreButton";

export interface MoveSceneProps {
	visible: boolean;
	onCancel: () => void;
	callback: () => void;
	projectId: string;
	sceneId: string;
}

const MoveScene = ({
	visible,
	onCancel,
	callback,
	projectId,
	sceneId,
}: MoveSceneProps) => {
	const [selectedProjectId, setSelectedProjectId] = useState("");

	const {
		projects,
		pagedResponse,
		loadMore,
		loading: loadingProjects,
	} = useProjects();
	const { t } = useTranslation();

	const { post, response, loading } = useFetch();

	const toaster = useToaster();

	const onSubmit = async () => {
		const res = await post(
			`/projects/${projectId}/scenes/${sceneId}/move`,
			{
				project: selectedProjectId,
			},
		);

		if (response.ok) {
			toaster.success({
				message: t("app.global.msg.title.success"),
				description: res.translatedMessage,
			});
		} else {
			toaster.error({
				message: t("app.global.msg.title.note"),
				description: res.translatedMessage,
			});
		}

		onCancel();
		callback();
	};

	// TODO: fix infinite scroll

	const scrollContainer = useRef<HTMLElement>();
	const lastKnownScrollPosition = useRef<number>(0);
	const loadMoreRef = useRef<HTMLDivElement>(null);
	const loadMoreVisible = useOnScreen(loadMoreRef);

	const handleLoadMore = async () => {
		if (loadingProjects || !pagedResponse?.hasNextPage) {
			return;
		}

		// Update last known position when "load more button" is visible
		lastKnownScrollPosition.current =
			loadMoreRef.current?.parentElement?.scrollTop || 0;

		await loadMore();
	};

	useEffect(() => {
		if (loadMoreVisible) {
			// Save ref to scrollContainer
			if (!scrollContainer.current) {
				scrollContainer.current = loadMoreRef.current
					?.parentElement as HTMLElement;
			}

			handleLoadMore();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadMoreVisible]);

	useLayoutEffect(() => {
		if (scrollContainer.current && lastKnownScrollPosition.current > 0) {
			scrollContainer.current.scrollTop = lastKnownScrollPosition.current;
		}
	}, [scrollContainer.current?.scrollHeight]);

	return (
		<Modal
			title={t("app.moveScene.modal.title")}
			visible={visible}
			footer={
				<Button onClick={onSubmit} loading={loading}>
					{t("app.global.btn.move")}
				</Button>
			}
			size="medium"
			onCancel={onCancel}
		>
			<StyledContainer>
				{projects.map((project) => (
					<StyledLine
						active={project._id === selectedProjectId}
						onClick={() => setSelectedProjectId(project._id)}
					>
						<img
							src={`${process.env.REACT_APP_AWS_S3_URL}/projects/${project.shortid}/${project.meta.thumbnail}`}
							alt={`Project ${project.shortid} thumbnail`}
						/>
						<Typography as="h2">{project.meta.name}</Typography>
					</StyledLine>
				))}
				<LoadMoreButton
					ref={loadMoreRef}
					hasMore={pagedResponse?.hasNextPage ?? false}
					loading={loadingProjects}
					text="Load more projects"
					loadMore={handleLoadMore}
				/>
			</StyledContainer>
		</Modal>
	);
};

const StyledContainer = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	align-items: center;
	padding: 12px !important;
	max-height: 50vh;
	overflow-y: scroll;
`;

const StyledLine = styled.div<{ active: boolean }>`
	width: 100%;
	height: 80px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
	display: flex;
	align-items: center;
	img {
		margin-right: 20px;
		margin-left: 20px;
		width: 50px;
		height: 50px;
		object-fit: cover;
	}
	cursor: pointer;
	background-color: ${({ active, theme }) =>
		active ? theme.colors.grey100 : "initial"};
	:hover {
		background-color: ${({ theme }) => theme.colors.grey100};
	}
`;

export default MoveScene;
