import { createGlobalStyle } from "styled-components";

export const AppGlobalStyle = createGlobalStyle`
body,
html {
	font-family: Lato, Roboto, Helvetica, Arial, sans-serif;
}
.filter-and-sort-dialog {
	.ant-modal-body {
		padding: 0;
	}
	.ant-modal-content {
		border-radius: 4px;
		overflow: hidden;
	}
}


a {
	color: ${({ theme }) => theme.colors.grey700};
	text-decoration: none;
	display: inline;
	line-height: 20px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey700};
	&:hover {
		color: ${({ theme }) => theme.colors.secondary};
		border-color: ${({ theme }) => theme.colors.secondary};
		cursor: pointer;
	}
}

/* Radio button overrides */
.ant-radio-checked {
	.ant-radio-inner {
		border-color: ${({ theme }) => theme.colors.primary};
	}

	&::after {
		border: 1px solid ${({ theme }) => theme.colors.primary};
	}
}

.ant-radio-inner::after {
	background-color: ${({ theme }) => theme.colors.primary};
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: ${({ theme }) => theme.colors.primary};
}


/* Checkbox overrides */
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: ${({ theme }) => theme.colors.primary};
	border-color: ${({ theme }) => theme.colors.primary};
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
	border-color: ${({ theme }) => theme.colors.primary};
}

/* Tabs overrides */
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: ${({ theme }) => theme.colors.primary};
}

.ant-tabs-ink-bar {
	background-color: ${({ theme }) => theme.colors.primary};
}

.ant-tabs-tab:hover {
	color: ${({ theme }) => theme.colors.primary};
}

/* antd input tag overwrite */

.ant-select-item-option:not(.ant-select-item-option-selected) .ant-select-item-option-content {
	color: ${({ theme }) => theme.colors.grey800};
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: ${({ theme }) => theme.colors.grey100};

	> .ant-select-item-option-content {
		color: ${({ theme }) => theme.colors.primary};
	}

	.ant-select-item-option-state span svg {
		rect, polygon, path, circle, ellipse {
			fill: ${({ theme }) => theme.colors.primary};
		}
	}
}

/* Form */
input[type="checkbox"] {
	width: inherit !important;
	height: inherit !important;
}

.h-checkbox input {
	display: none !important;
}

.h-sidebar{
	> div:last-of-type{
		overflow: initial
	}
	@media (max-width: 865px){
		display: flex;
		> div:first-of-type{
			display: none
		}
	}
}

/* ANTDMODAL CONFIRM STYLE */
.ant-modal-confirm-body-wrapper{
	.ant-modal-confirm-body{
		> .anticon{
			display:none;
		}
		.ant-modal-confirm-content,
		.ant-modal-confirm-title{
			margin-left:0 !important;
			color: ${({ theme }) => theme.colors.grey800};
		}
		.ant-modal-confirm-title{
			font-weight: ${({ theme }) => theme.typography.weight.bold};
		}
	}
	.ant-modal-confirm-btns{
		.ant-btn{
			background-color: ${({ theme }) => theme.colors.grey400};
			color: ${({ theme }) => theme.colors.grey800};

			border-radius: 4px;
			font-weight: ${({ theme }) => theme.typography.weight.bold};
			border: 0;
			height: 37px;
			&:hover{
				background-color: ${({ theme }) => theme.colors.grey300};
			}
			&:last-of-type{
				background-color: ${({ theme }) => theme.colors.secondary};
				color: ${({ theme }) => theme.colors.white};
				&:hover{
					background-color: ${({ theme }) => theme.colors.secondary};
					filter: brightness(110%);
				}
			}
		}
	}
}

`;
