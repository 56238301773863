import { createContext } from "react";

import PageQuery from "common/types/PageQuery";
import { ScenesContext } from "./types";

const initialQuery: PageQuery = {
	filters: {},
	limit: 10,
	page: 0,
	search: {
		text: "",
		fields: [],
	},
	sort: "meta.name",
};

const context = createContext<ScenesContext>({
	current: undefined,
	currentLoading: false,
	scenes: [],
	loading: false,
	query: initialQuery,
	pagedResponse: undefined,
	currentBimcollab: undefined,
	fetchScenes: () => {
		throw new Error("fetchScenes not implemented");
	},
	setQuery: () => {
		throw new Error("setQuery not implemented");
	},
	setCurrentScene: () => {
		throw new Error("setCurrentScene not implemented");
	},
	lodValue: 0,
	setLodValue: () => {
		throw new Error("setLodValue not implemented");
	},
	availableLods: 0,
	loadMore: () => {
		throw new Error("loadMore not implemented");
	},
	createShareLink: () => {
		throw new Error("createShareLink not implemented");
	},
	setCurrentBimcollab: () => {
		throw new Error("setCurrentBimcollab not implemented");
	},
	setBIMIssues: () => {
		throw new Error("setBIMIssues not implemented");
	},
	setRefreshTopics: () => {
		throw new Error("setRefreshTopics not implemented");
	},
});

export default context;
