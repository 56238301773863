import styled from "styled-components/macro";

export const StyledSwitcherContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: column;
	border-right: 1px solid ${({ theme }) => theme.colors.grey300};
	padding-top: 9px;
	min-width: 200px;
	max-width: 400px;
	.label {
		position: absolute;
		top: 2px;
		line-height: 16px;
		font-size: 10px;
		color: rgb(156, 156, 156);
		text-align: left;
		display: block;
	}

	> div {
		> div {
			padding: 0 !important;
		}
	}
`;
