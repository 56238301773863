import React from "react";
import styled from "styled-components/macro";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
	padding?: boolean;
}

export const Section = ({
	children,
	padding = false,
	...otherProps
}: SectionProps) => {
	return (
		<StyledItemRowWrap {...otherProps} padding={padding}>
			{children}
		</StyledItemRowWrap>
	);
};

Section.Title = ({ children, ...otherProps }: SectionProps) => {
	return (
		<StyledItemSectionTitle {...otherProps}>
			{children}
		</StyledItemSectionTitle>
	);
};

Section.Row = ({ children, ...otherProps }: SectionProps) => {
	return (
		<StyledItemRowContainer {...otherProps}>
			{children}
		</StyledItemRowContainer>
	);
};
Section.Item = ({ children, ...otherProps }: SectionProps) => {
	return <StyledItemRow {...otherProps}>{children}</StyledItemRow>;
};

Section.Value = ({ children, ...otherProps }: SectionProps) => {
	return <StyledItemValue {...otherProps}>{children}</StyledItemValue>;
};

export const StyledItemRowWrap = styled.div<SectionProps>`
	background-color: ${({ theme }) => theme.colors.white};
	padding: ${({ padding }) => (padding === true ? `5px 20px` : "0")};
	border-radius: 4px;
	max-width: 400px;
	width: 100%;
`;

export const StyledItemSectionTitle = styled.div`
	margin-top: 20px;
	&:first-of-type {
		margin-top: 0;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: ${({ theme }) => theme.colors.grey800};
		font-size: ${({ theme }) => theme.typography.text.medium};
		margin-bottom: 10px;
	}
`;

export const StyledItemRowContainer = styled.div`
	&&& {
		display: flex;
		width: 100%;
		border-bottom: 1px solid ${({ theme }) => theme.border.color};
		margin: auto;
		max-width: 100%;
		&:first-child {
			> div:first-child {
			}
		}
		&:last-child {
			border-bottom: none;
		}
		> *:only-child {
			width: 100% !important;
		}
		> div:last-child,
		> label,
		> span {
			padding: 9px 0px;
			width: 50%;
			justify-content: left;
			align-items: center;
			display: flex;
			color: ${({ theme }) => theme.colors.grey800};
			div {
				label {
					display: none;
				}
				input {
					height: 30px;
					padding: 1px 14px;
				}
			}
			p:empty {
				display: none;
			}
			.ant-select {
				width: 100%;
			}
		}
		> label {
			display: flex;
			align-items: center;
			> span.ant-checkbox {
				top: 0;
			}
		}

		&:last-child {
			border-bottom: none;
		}
		.ant-select-selection__rendered {
			margin-left: 0;
		}
		> *:only-child {
			width: 100%;
			font-weight: ${({ theme }) => theme.typography.weight.bold};
			color: ${({ theme }) => theme.colors.grey700};
		}
	}
`;

export const StyledItemValue = styled.div`
	color: ${({ theme }) => theme.colors.grey800};
	width: 100%;
`;

export const StyledItemRow = styled.div`
	width: 50%;
	font-weight: ${({ theme }) => theme.typography.weight.bold};
	color: ${({ theme }) => theme.colors.grey700};
	padding: 9px 0;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
	> strong {
		color: ${({ theme }) => theme.colors.grey700};
	}
	/* > div {
		justify-content: left;
		&.small {
			button {
				width: 25px !important;
				height: 25px !important;
				svg {
					width: 12px !important;
				}
			}
		}
		&.green {
			button {
				background-color: ${({ theme }) => theme.colors.primary} !important;
				svg {
					path,
					polygon,
					rect {
						fill: ${({ theme }) => theme.colors.white} !important;
					}
				}
			}
		}
	}

	&:not(:only-child) {
		width: calc(60% - 10px);
		padding-right: 10px;
	}
	&:last-child {
		p {
			font-weight: ${({ theme }) => theme.typography.weight.normal};
			line-height: 30px;
			color: ${({ theme }) => theme.colors.grey700};
			margin: 0;
		}
	} */
`;
