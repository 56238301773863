import React from "react";
import styled from "styled-components/macro";
import { Icon } from "@hegias/ui-components";

export interface MenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
	thumbnail?: string;
	title: string;
}

const MenuItem = ({
	thumbnail,
	title,
	children,
	...otherProps
}: MenuItemProps) => {
	const fallbackThumbnail = "/images/hegias-logo.svg";
	const originalThumbnail = `${process.env.REACT_APP_AWS_S3_URL}/organizations/${thumbnail}`;

	return (
		<StyledContainer {...otherProps}>
			{thumbnail ? (
				<img
					src={originalThumbnail}
					alt={title}
					aria-hidden
					role="presentation"
					onError={(e: any) => {
						e.target.src = fallbackThumbnail;
						e.target.style = "object-fit: contain;";
					}}
				/>
			) : (
				<Icon name="LogoVerticalIcon" size="32px" />
			)}
			<span>{title || children}</span>
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	display: flex;
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
	padding: 8px 10px;
	cursor: pointer;

	img {
		display: block;
		overflow: hidden;
		height: 32px;
		width: 32px;
		object-fit: cover;
		user-select: none;
	}

	svg,
	img {
		margin-right: 10px;
		min-width: 32px;
	}

	span {
		width: 100%;
		margin: 0px;
		min-height: 32px;
		height: 32px;
		max-height: 32px;
		line-height: ${({ theme }) =>
			` calc(${theme.typography.text.normal} + 2px)`};
		position: relative;
		font-size: ${({ theme }) => theme.typography.text.normal};
		word-break: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		color: ${({ theme }) => theme.colors.grey800};

		/* Text ellipsis after second line of text */
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
`;

export default MenuItem;
