export default function isShortid(text: string | undefined): boolean {
	if (!text?.length) {
		return false;
	}

	// /^
	// (?=.*[a-z])       // should contain at least one lower case
	// (?=.*[A-Z])       // should contain at least one upper case
	// [a-zA-Z0-9]{8,}   // should contain at least 9-10 from the mentioned characters
	// $/
	const pattern = /^(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,15}$/;

	return pattern.test(text);
}
