import { LOCALHOST_ENVIRONMENT } from "common/environment";

// This will remove all subdomains, so "a.b.c.d.hegias.com" will become "hegias.com"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function getDomain(): string | undefined {
	// if (LOCALHOST_ENVIRONMENT) {
	// 	return undefined;
	// }

	const hostnameArray = window.location.hostname.split(".");
	const numberOfSubdomains = hostnameArray.length - 2;
	const domain =
		hostnameArray.length === 2
			? window.location.hostname
			: hostnameArray.slice(numberOfSubdomains).join(".");

	logger.debug("[AuthProvider] getDomain: ", {
		LOCALHOST_ENVIRONMENT,
		hostname: window.location.hostname,
		domain,
	});

	return domain;
}
