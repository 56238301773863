import { getRoleMinWeight, userRoleWeight } from "components/Can/Can";
import { useOrganizations } from "contexts/Organizations";
import { OrganizationMember, UserRole } from "contexts/Organizations/types";

interface Options {
	exact?: boolean;
	anyOf?: boolean;
}

const defaultOptions: Options = {
	exact: false,
	anyOf: false,
};

const useCan = (
	role: UserRole | UserRole[],
	options: Options = defaultOptions,
): boolean => {
	const { exact, anyOf } = options;
	const { member } = useOrganizations();
	if (!member) {
		// TODO: handle (un)expected behavior on pages outside Project
		// where initial `organizations` list is empty
		return false;
	}

	const currentUserWeight = userRoleWeight[(member as OrganizationMember).role];
	const minimumRequiredWeight = Array.isArray(role)
		? getRoleMinWeight(role)
		: role;

	let allowedWeights = [minimumRequiredWeight];
	if (anyOf && Array.isArray(role)) {
		allowedWeights = [
			minimumRequiredWeight,
			...role.map((r) => userRoleWeight[r]),
		];
	}

	if (exact) {
		// Current user role is exact match to required
		return allowedWeights.includes(currentUserWeight);
	}

	const allowed = currentUserWeight >= minimumRequiredWeight;

	return allowed;
};

export default useCan;
