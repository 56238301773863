import React from "react";
import { Select } from "@hegias/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

interface LanguageSwitcherProps
	extends React.SelectHTMLAttributes<HTMLSelectElement> {
	callback?: (language: string) => void;
}

const StylesLanguageContainer = styled.div``;

const LanguageSwitcher = ({ callback, ...rest }: LanguageSwitcherProps) => {
	const { i18n } = useTranslation();
	return (
		<StylesLanguageContainer style={rest.style}>
			<Select
				type="simple"
				defaultValue={i18n.language}
				options={[
					{ value: "en", label: `English` },
					{ value: "fr", label: `Français` },
					{ value: "it", label: `Italiano` },
					{ value: "de", label: `Deutsch` },
				]}
				onChange={(e) => {
					const { target: { value }} = e;
					callback?.(value); i18n.changeLanguage(value);
				}}
			/>
		</StylesLanguageContainer>
	);
};

export default LanguageSwitcher;
