import React from "react";
import { Button } from "@hegias/ui-components";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import useFetch from "use-http";

import { Modal } from "components";
import { renderInput } from "components/FormComponents";
import { useToaster } from "contexts/Toaster";

export interface CopySceneProps {
	visible: boolean;
	onCancel: () => void;
	callback: () => void;
	projectId: string;
	sceneId: string;
}

interface CopySceneFormField {
	name: string;
}

const CopyScene = ({
	visible,
	onCancel,
	callback,
	projectId,
	sceneId,
}: CopySceneProps) => {
	const { control, handleSubmit } = useForm<CopySceneFormField>({
		defaultValues: {
			name: "",
		},
	});
	const { t } = useTranslation();

	const { post, loading, response } = useFetch();

	const toaster = useToaster();

	const onSubmit = async ({ name }: CopySceneFormField) => {
		const res = await post(
			`/projects/${projectId}/scenes/${sceneId}/copy`,
			{
				meta: { name },
			},
		);
		if (response.ok) {
			toaster.success({
				message: t("app.global.msg.title.success"),
				description: res.translatedMessage,
			});
		} else {
			toaster.error({
				message: t("app.global.msg.title.note"),
				description: res.translatedMessage,
			});
		}

		onCancel();
		callback();
	};

	return (
		<Modal
			title={t("app.copyScene.modal.title")}
			visible={visible}
			footer={
				<Button onClick={handleSubmit(onSubmit)} loading={loading}>
					{t("app.global.btn.create")}
				</Button>
			}
			size="small"
			onCancel={onCancel}
		>
			<StyledContainer>
				<Controller
					render={renderInput({
						placeholder: t("app.copyScene.modal.placeholder"),
						style: { width: "80%" },
					})}
					name="name"
					control={control}
				/>
			</StyledContainer>
		</Modal>
	);
};

const StyledContainer = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	align-items: center;
	padding: 12px !important;
`;

export default CopyScene;
