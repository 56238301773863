import { Typography } from "@hegias/ui-components";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { Link } from "components";
import useOrganizations from "contexts/Organizations/useOrganizations";
import * as ROUTES from "../../common/routes.constants";
import OrganizationSwitcher from "../OrganizationSwitcher/OrganizationSwitcher";
import ProjectSwitcher from "../ProjectSwitcher/ProjectSwitcher";
import SceneSwitcher from "../SceneSwitcher/SceneSwitcher";
import UserMenu from "./UserMenu";

const Header = () => {
	const { search } = useLocation();
	const { current } = useOrganizations();
	const { t } = useTranslation();

	const originalThumbnail = `${process.env.REACT_APP_AWS_S3_URL}/organizations/${current?.shortid}/${current?.meta?.avatar}`;

	return (
		<StyledHeader
			data-component="Header"
			data-path="src/components/Header/Header.tsx"
		>
			<StyledOrganizationSwitcherContainer>
				{current && <OrganizationLogo url={originalThumbnail} />}
				<OrganizationSwitcher />
			</StyledOrganizationSwitcherContainer>
			<ProjectSwitcher />
			<SceneSwitcher />
			<span>{/* Do not delete this span */}</span>
			<Link
				to={{
					pathname: `/${ROUTES.LIBRARY}`,
					search,
				}}
			>
				<Typography variant="p">
					{t("app.MainNavigation.menu.library")}
				</Typography>
			</Link>
			<UserMenu />
		</StyledHeader>
	);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrganizationLogo = ({ url }: { url: string }) => {
	const fallbackThumbnail = "/images/hegias-logo.svg";

	return (
		<img
			src={url}
			alt="Organization logo"
			aria-hidden
			role="presentation"
			onError={(e: any) => {
				e.target.src = fallbackThumbnail;
				e.target.style = "object-fit: contain;";
			}}
		/>
	);
};

// Columns: [OrgSwitcher /w Logo] - [ProjectSwitcher] - [SceneSwitcher] - [ ... ] - [Library] [User]
const StyledHeader = styled.header`
	display: grid;
	grid-gap: 10px;
	grid-template-columns: auto 200px 200px 1fr 120px 110px;
	grid-template-areas: "logo organizations projects scenes spacing library user";
	align-items: center;

	background-color: #fff;
	height: 70px;
	padding: 0 25px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.07);
	position: sticky;
	top: 0;
	z-index: 999;
	> a {
		border: none;
		> p {
			color: ${({ theme }) => theme.colors.grey600};
			font-size: ${({ theme }) => theme.typography.text.big};
			cursor: pointer;
			font-weight: ${({ theme }) => theme.typography.weight.bold};
			line-height: 70px;
			transition: 0.3s;
			text-align: right;
			margin-right: 10px;
			&:hover {
				color: ${({ theme }) => theme.colors.primary};
			}
		}
	}
	@media (max-width: 999px) {
		display: flex;
		justify-content: space-between;
		> a {
			display: none;
		}
		.projectSwitcher,
		.sceneSwitcher {
			display: none;
		}
	}
`;

const StyledOrganizationSwitcherContainer = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: 999px) {
		width: 100%;
		> div {
			max-width: 100%;
		}
	}
	img {
		height: 30px;
		width: auto;
		padding: 0 10px 0 0;
	}
`;

export default Header;
