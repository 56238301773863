import React from "react";
import styled from "styled-components/macro";

const StyledErrorMessage = styled.p`
	color: red;
`;

type ErrorMessageProps = {
	message: string | null;
};

const HegiasErrorMessage = ({ message }: ErrorMessageProps) => (
	<StyledErrorMessage>{message}</StyledErrorMessage>
);

export default HegiasErrorMessage;
