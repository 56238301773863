import React from "react";
import styled from "styled-components/macro";

export const Tab = ({ children }: React.HTMLAttributes<HTMLDivElement>) => {
	return <StyledTabContent>{children}</StyledTabContent>;
};

const StyledTabContent = styled.div`
	height: 100%;
	width: 100%;
	overflow: auto;
	padding: 0 20px 20px 20px;
`;
