import { useState, useEffect } from "react";
import useFetch from "use-http";

import { useOrganizations } from "contexts/Organizations";

export type TagKind = "all" | "project" | "material" | "scene" | "object" | "light";

const useTags = (kind: TagKind = "all", isPrivate = true, isPublic = false) => {
	const { current } = useOrganizations();

	const [tags, setTags] = useState<string[]>([]);

	const { get, response } = useFetch();

	useEffect(() => {
		const fetchTags = async () => {
			if (current?._id) {
				await get(
					`tags?select=${kind}&filters[isPrivate]=${isPrivate}&filters[isPublic]=${isPublic}`,
				);

				if (response.ok) {
					setTags(response.data);
				}
			}
		};

		fetchTags();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current, kind, isPrivate, isPublic]);

	return { tags };
};

export default useTags;
