import { ButtonGroup } from "./ButtonGroup";
import { Section } from "./Section";
import { Tab } from "./Tab";
import { Title } from "./Title";
import { Inner } from "./Inner";

const Containers = {
	ButtonGroup,
	Section,
	Tab,
	Title,
	Inner,
};

export default Containers;
