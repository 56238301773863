/* eslint-disable import/no-duplicates */
import { useTranslation } from "react-i18next";
import { format, formatDistance } from "date-fns";
import { it, enGB, de, fr } from "date-fns/locale";

const getLocale = (lang: string) => {
	switch (lang) {
		case "de":
			return de;
		case "fr":
			return fr;
		case "it":
			return it;
		case "en":
		default:
			return enGB;
	}
};

export const useFormat = () => {
	const { i18n } = useTranslation();
	return (date: Date | number, form: string) =>
		format(date, form, { locale: getLocale(i18n.language) });
};

export const useFormatDistance = () => {
	const { i18n } = useTranslation();
	return (date: Date | number, baseDate: Date | number) =>
		formatDistance(date, baseDate, {
			locale: getLocale(i18n.language),
			addSuffix: true,
		});
};
