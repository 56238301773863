import { useCallback, useEffect, useRef } from "react";
import debounce from "lodash.debounce";

export default function useDebounce(
	callback: (...args: any) => any,
	delay: number,
) {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const memoizedCallback = useCallback(callback, []);
	const debouncedFn = useRef(debounce(memoizedCallback, delay));

	useEffect(() => {
		debouncedFn.current = debounce(memoizedCallback, delay);
	}, [memoizedCallback, debouncedFn, delay]);

	return debouncedFn.current;
}
