import { createContext } from "react";
import { AuthContext } from "./types";

const context = createContext<AuthContext>({
	auth: null,
	authenticated: false,
	me: null,
	loading: false,
	login: () => {
		throw new Error("login not implemented");
	},
	logout: () => {
		throw new Error("logout not implemented");
	},
	fetchUser: () => {
		throw new Error("userFetch not implemented");
	},
	setAuthData: () => {
		throw new Error("setAuthData not implemented");
	},
});

export default context;
