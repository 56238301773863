import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Select } from "antd";
import { Icon } from "@hegias/ui-components";

import { useTranslation } from "react-i18next";
import useProjects from "contexts/Projects/useProjects";
import { PROJECTS, SCENES } from "common/routes.constants";
import MenuItem from "components/MenuItem/MenuItem";
import MenuItemStyles from "components/MenuItem/MenuItem.styles";
import { StyledSwitcherContainer } from "common/styles/Switcher.styles";
import useScenes from "contexts/Scenes/useScenes";

const { Option } = Select;

const SceneSwitcher = () => {
	const { search } = useLocation();
	const history = useHistory();
	const { current: currentProject, loading: loadingProjects } = useProjects();
	const { scenes, current, loading, loadMore } = useScenes();
	const { t } = useTranslation();

	const [switcherState, setSwitcherState] = useState("");

	useEffect(() => {
		setSwitcherState(current?.shortid || "");
	}, [current]);

	if (!currentProject || loadingProjects) {
		return <span>{/* Do not delete this span */}</span>;
	}

	return (
		<StyledSwitcherContainer className="sceneSwitcher">
			<MenuItemStyles />
			<span className="label">
				{t("app.SceneSwitcher.label.switcher")}
			</span>
			<Select
				value={switcherState}
				style={{ width: "100%" }}
				onChange={(shortid: string) => {
					history.push({
						pathname: `/${PROJECTS}/${currentProject?.shortid}/${SCENES}/${shortid}`,
						search,
					});
				}}
				bordered={false}
				dropdownClassName="header-select-dropdown"
				suffixIcon={<Icon name="ArrowDownFilledIcon" />}
				className="header-select"
				virtual
				onPopupScroll={(
					event: React.UIEvent<HTMLDivElement, UIEvent>,
				) => {
					const {
						scrollTop,
						offsetHeight,
						scrollHeight,
					} = event.target as HTMLElement;
					const shouldLoadMore =
						scrollTop + offsetHeight === scrollHeight;

					if (shouldLoadMore && !loading) {
						loadMore();
					}
				}}
			>
				{loading && (
					<Option key="loading1" value="">
						<MenuItem title="Loading scenes" />
					</Option>
				)}
				<Option value="">
					<MenuItem title={t("app.SceneSwitcher.label.allScenes")} />
				</Option>
				{scenes?.map((scene) => (
					<Option key={scene.shortid} value={scene.shortid}>
						<MenuItem title={scene.meta.name} />
					</Option>
				))}
				{loading && (
					<Option key="loading2" value="">
						<MenuItem title="Loading scenes" />
					</Option>
				)}
			</Select>
		</StyledSwitcherContainer>
	);
};

export default SceneSwitcher;
