import { useContext } from "react";
import { ToasterContext } from "./ToasterContext";

const useToaster = () => {
	const context = useContext(ToasterContext);
	if (context === undefined) {
		throw new Error(`useAuth must be used within a ToasterProvider`);
	}
	return context;
};

export default useToaster;
