import { createContext } from "react";

import { FetchContext } from "./types";

const context = createContext<FetchContext>({
	headers: {},
	setHeader: () => {
		throw new Error("setHeader not implemented");
	},
	removeHeader: () => {
		throw new Error("removeHeader not implemented");
	},
});

export default context;
