import React, { useState, useEffect, ComponentProps, useMemo } from "react";
import styled from "styled-components/macro";
import { Modal, Dragger, FormErrorMessage /* InputTag */ } from "components";
import { Tabs, InputNumber } from "antd";
import {
	useTheme,
	Button,
	Typography,
	IconButton,
} from "@hegias/ui-components";
import { useTranslation } from "react-i18next";
import { UploadFile } from "antd/lib/upload/interface";
import { useModal } from "contexts/Modal";
import { MaterialTagCategoryMap } from "contexts/Library/types";
import useFetch from "use-http";
import { isImage, isObject3D, isMaterial, isBIN } from "common/filetypes";
import { ItemKinds } from "common/itemKinds";
import { Controller, useForm } from "react-hook-form";
import {
	renderInput,
	renderCheckbox,
	renderSlider,
	renderSelect,
} from "components/FormComponents";
import getDomain from "utils/getDomain";
import { useAuth } from "contexts/Auth";

const { TabPane } = Tabs;

enum TABS {
	FILES = "files",
	OPTIONS = "options",
}

enum INPUT_ELEMENTS {
	dropdown = "dropDown",
	checkbox = "checkBox",
	slider = "slider",
}

interface UploadModalProps extends ComponentProps<typeof Modal> {
	visible: boolean;
	onCancel: () => void;
	projectId?: string;
	sceneId?: string;
	filename?: string;
	kind: keyof typeof ItemKinds;
	callback: (response?: any) => void;
	maxSize?: number;
}

interface UploadOptions {
	element: INPUT_ELEMENTS;
	label: string;
	values: { label: any; value: string }[];
	min: number;
	max: number;
	step: number;
	key: string;
	hidden: boolean;
}

export interface UploadFormFields {
	name: string;
	description: string;
	scale: number;
	decimateFactor: number;
	useLibraryObjects: boolean;
	useHegiasMaterials: boolean;
	centerGeometry: boolean;
	categories: boolean;
	runShaderMap: string | number;
	doubleSided: boolean;
	generateUVs: boolean;
	repetitionFactorX?: number;
	repetitionFactorY?: number;
	useTransparentTextures: boolean;
}

const UploadModal = ({
	projectId,
	kind,
	callback,
	onCancel,
	sceneId,
	filename,
	maxSize,
	...otherProps
}: UploadModalProps) => {
	const [activeTab, setActiveTab] = useState<string>(TABS.FILES);
	const [files, setFiles] = useState<UploadFile[]>([]);
	const [materialsMap, setMaterialsMap] = useState<
		{ map: string; intensity?: string; file: UploadFile }[]
	>([]);
	const { control, handleSubmit, errors, reset } = useForm<UploadFormFields>({
		defaultValues: {
			name: "",
			description: "",
			scale: 1,
			decimateFactor: 0,
			useLibraryObjects: true,
			useHegiasMaterials: true,
			centerGeometry: true,
			categories: true,
			runShaderMap: "original",
			doubleSided: true,
			generateUVs: true,
			repetitionFactorX: 1,
			repetitionFactorY: 1,
			useTransparentTextures: true,
		},
		mode: "onChange",
	});

	const [fileWarnings, setFileWarnings] = useState<string[]>([]);
	const [fileErrors, setFileErrors] = useState<string[]>([]);

	const [uploadMapLoading, setUploadMapLoading] = useState<boolean>(false);
	const [defaultCategoriesList, setDefaultCategoriesList] = useState<
		{ value: string; translatedValue: string; icon: string }[]
	>();
	const [materialCategories, setMaterialCategories] = useState<string[]>([]);

	const { t } = useTranslation();

	const ConfirmModal = useModal();

	const theme = useTheme();

	const user = useAuth();

	const {
		post: createMaterial,
		response: createMaterialResponse,
	} = useFetch();

	const { get: getOptions, data: optionsData } = useFetch();

	const { post, put, loading, response } = useFetch((globalOptions: any) => {
		const newGlobalOptions = { ...globalOptions };
		delete newGlobalOptions.headers["Content-Type"];
		// newGlobalOptions.headers["Content-Type"] = "multipart/form-data";
		return newGlobalOptions;
	});

	const fetchOptions = async () => {
		// These kinds needs to be treaten as scene kind in order to fetch the correct options.
		const sceneKind = ["Replace", "SceneFile", "Reprocess"].includes(kind);
		const optionsKind = sceneKind ? "scene" : kind;

		const result = await getOptions(
			`/upload/options/${optionsKind.toLocaleLowerCase()}?staging=${
				// TODO: Make the check on BE and call the variable "environment" ?
				getDomain() === "app.hegias-stage.com"
			}&language=${user?.me?.preferences?.language}`,
		);
		return result;
	};

	const thumbnailUpload = (materialId: string, formData: any) => {
		post(`materials/${materialId}/thumbnail`, formData);
	};

	const uploadMap = (materialId: string, map: string, formData: any) => {
		return post(`upload/materials/${materialId}/map/${map}`, formData);
	};

	const handleTabChange = (key: string) => {
		if (fileWarnings.length > 0 && key === TABS.OPTIONS) {
			ConfirmModal.confirm({
				title: t("app.global.msg.warning"),
				content: `${fileWarnings.map((warning) => `${t(warning)}\n`)}`,
				onOk: () => setActiveTab(key),
				okText: t("app.global.btn.next"),
				cancelText: t("app.global.btn.back"),
			});
		} else {
			setActiveTab(key);
		}
	};

	const handleMaterialUpload = async (formData: FormData) => {
		const createMaterialResult = await createMaterial("materials", {
			meta: {
				name: formData.get("meta.name"),
				description: formData.get("meta.description"),
				category: materialCategories,
			},
			settings: {
				repetitionFactor: {
					x:
						(1 /
							Number(
								formData.get("settings.repetitionFactorX"),
							)) *
						1.5,
					y:
						(1 /
							Number(
								formData.get("settings.repetitionFactorY"),
							)) *
						1.5,
				},
			},
		});

		let uploadMaps;

		if (createMaterialResponse.ok) {
			setUploadMapLoading(true);
			const localMap: any = materialsMap as Record<string, any>;
			const materialId = createMaterialResult?.material._id;

			const thumbnailMap = localMap.find(
				(item: { map: string; file: any }) => item.map === "thumbnail",
			);

			const thumbnailFormData = new FormData();

			if (thumbnailMap) {
				thumbnailFormData.append("thumbnail", thumbnailMap.file);

				thumbnailUpload(materialId, thumbnailFormData);
			} else {
				// In case thumbnail map is not provided, use "diffuse", which is mandatory.
				const diffuseMap = localMap.find(
					(item: { map: string; file: any }) =>
						item.map === "diffuse",
				);

				thumbnailFormData.append("thumbnail", diffuseMap.file);

				thumbnailUpload(materialId, thumbnailFormData);
			}

			uploadMaps = localMap.map(
				(currentLocalMap: {
					map: string;
					intensity?: string;
					file: any;
				}) => {
					const newFormData = new FormData();

					newFormData.append("map", currentLocalMap.file);

					if (currentLocalMap?.intensity) {
						newFormData.set(
							"intensity",
							currentLocalMap?.intensity,
						);
					}

					let uploadMapResult;

					// Thumbnail is not a map, so avoid upload of it.
					if (currentLocalMap.map !== "thumbnail") {
						uploadMapResult = uploadMap(
							materialId,
							currentLocalMap.map,
							newFormData,
						);
					}

					return uploadMapResult;
				},
			);

			await Promise.all(uploadMaps).then(() => {
				setUploadMapLoading(false);
			});
		}

		return uploadMaps;
	};

	const handlers = {
		[ItemKinds.SceneFile as ItemKinds]: async (formData: FormData) =>
			post(`/upload/projects/${projectId}/scenes/${sceneId}`, formData),
		[ItemKinds.Scene as ItemKinds]: async (formData: FormData) =>
			post(`/upload/projects/${projectId}/scenes`, formData),
		[ItemKinds.Object as ItemKinds]: async (formData: FormData) => {
			formData.append(`meta.category`, "other");
			return post(`/upload/objects`, formData);
		},
		[ItemKinds.Material as ItemKinds]: async (formData: FormData) => {
			return handleMaterialUpload(formData);
		},
		[ItemKinds.Replace]: async (formData: FormData) =>
			put(
				`/upload/projects/${projectId}/scenes/${sceneId}/file/${filename}/replace`,
				formData,
			),
		[ItemKinds.Reprocess]: async (formData: FormData) =>
			post(
				`/upload/projects/${projectId}/scenes/${sceneId}/file/${filename}/reprocess`,
				formData,
			),
	};

	const onSubmit = async (data: UploadFormFields) => {
		const object: File[] = [];
		const texture: File[] = [];
		const material: File[] = [];
		const gltfBin: File[] = [];

		const formData = new FormData();

		files.forEach((file) => {
			if (isImage(file.name)) {
				texture.push(file.xhr);
			} else if (isObject3D(file.name)) {
				object.push(file.xhr);
			} else if (isMaterial(file.name)) {
				material.push(file.xhr);
			} else if (isBIN(file.name)) {
				gltfBin.push(file.xhr);
			}
		});

		object.forEach((item) => {
			formData.append("object", item as File);
		});
		texture.forEach((item) => {
			formData.append("texture", item);
		});
		material.forEach((item) => {
			formData.append("material", item);
		});
		gltfBin.forEach((item) => {
			formData.append("gltfbin", item);
		});

		if (
			kind === ItemKinds.Scene ||
			kind === ItemKinds.Object ||
			kind === ItemKinds.Material
		) {
			formData.append(`meta.name`, data.name);
			formData.append(`meta.description`, data.description);
		}

		if (kind === ItemKinds.Material) {
			formData.append(
				`settings.repetitionFactorX`,
				String(data.repetitionFactorX),
			);
			formData.append(
				`settings.repetitionFactorY`,
				String(data.repetitionFactorY),
			);
		}

		const optionsObject = {
			scale: {
				value: Number(data.scale),
			},
			decimateFactor: {
				value: data.decimateFactor,
			},
			useHegiasMaterials: {
				value: data.useHegiasMaterials,
			},
			useLibraryObjects: {
				value: data.useLibraryObjects,
			},
			centerGeometry: {
				value: true,	// hard coded to true from now on (April 2024)
			},
			categories: {
				value: data.categories,
			},
			runShaderMap: {
				value: data.runShaderMap,
			},
			doubleSided: {
				value: data.doubleSided,
			},
			generateUVs: {
				value: data.generateUVs,
			},
			useTransparentTextures: {
				value: data.useTransparentTextures,
			},
		};

		formData.append(`options`, JSON.stringify(optionsObject));

		// For objects, materialisation need to be set as "original", so default value.
		if (kind === ItemKinds.Object) {
			optionsObject.runShaderMap.value = "original";
		}

		await handlers[kind](formData);

		if (kind === ItemKinds.Material) {
			callback(createMaterialResponse);
			setMaterialCategories([]);
		} else {
			callback(response);
		}
		onCancel();
		setActiveTab(TABS.FILES);
	};

	const titles = {
		[ItemKinds.SceneFile]: t("app.uploadSceneFile.modal.title"),
		[ItemKinds.Object]: t("app.createObject.modal.title"),
		[ItemKinds.Material]: t("app.createMaterial.modal.title"),
		[ItemKinds.Scene]: t("app.createScene.modal.title"),
		[ItemKinds.Replace]: t("app.replaceSceneFile.modal.title"),
		[ItemKinds.Reprocess]: t("app.reprocessItem.modal.title"),
	};

	const showMetaInfo = useMemo(
		() =>
			kind === ItemKinds.Scene ||
			kind === ItemKinds.Object ||
			kind === ItemKinds.Material,
		[kind],
	);

	const handleCategoryChange = (newCategory: string) => {
		const shouldRemoveFromList = materialCategories.includes(newCategory);

		const newList = shouldRemoveFromList
			? materialCategories.filter(
					(category: string) => category !== newCategory,
			  )
			: [...materialCategories, newCategory];

		setMaterialCategories(newList);
	};

	const renderType = (data: UploadOptions) => {
		const { element, label, values, min, max, step, key } = data;

		if (element === INPUT_ELEMENTS.dropdown) {
			const options = values.map((option: any) => {
				return {
					value: option.value,
					label: option.label,
				};
			});

			return renderSelect({
				options,
				placeholder: t(`app.CreateItem.placeholder.${label}`),
			});
		}

		if (element === INPUT_ELEMENTS.slider) {
			return renderSlider({
				min,
				max,
				step,
				tipFormatter: (rawValue: string) => `${rawValue}%`,
			});
		}

		if (element === INPUT_ELEMENTS.checkbox) {
			return renderCheckbox({});
		}

		return renderInput({
			placeholder: t(`app.global.placeholder.${key}`),
		});
	};

	useEffect(() => {
		// Wait until user data are ready so fetchOptions can use user's langauge for the API.
		if (user?.me && optionsData === undefined) {
			fetchOptions();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, optionsData]);

	useEffect(() => {
		const list = MaterialTagCategoryMap.map((c) => ({
			...c,
			translatedValue: t(`app.global.option.material.${c.value}`),
		}));

		setDefaultCategoriesList(list);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// Upload of file is blocked for reprocess action so load the options as first thing.
		if (kind === ItemKinds.Reprocess) {
			handleTabChange(TABS.OPTIONS);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const resetUploadForm = () => {
		/* If user uploaded just one file, when move on second step of upload,
		   the name of the file will be set as DefaultHandler. */
		if (files.length === 1) {
			reset({
				name: files[0].name, // Filename.
				description: "",
				scale: 1,
				decimateFactor: 0,
				useLibraryObjects: true,
				useHegiasMaterials: true,
				centerGeometry: true,
				categories: true,
				runShaderMap: "original",
				doubleSided: true,
				generateUVs: true,
				repetitionFactorX: 1,
				repetitionFactorY: 1,
				useTransparentTextures: true,
			});
		}
	};

	return (
		<Modal
			title={titles[kind]}
			onCancel={() => {
				onCancel();
				setActiveTab(TABS.FILES);
			}}
			{...otherProps}
			size="large"
			destroyOnClose
			footer={
				<>
					{activeTab === TABS.FILES ? (
						<Button
							color="secondary"
							onClick={() => {
								handleTabChange(TABS.OPTIONS);
								resetUploadForm();
							}}
							disabled={
								files.length === 0 || fileErrors.length > 0
							}
						>
							{t("app.global.btn.next")}
						</Button>
					) : (
						<Button
							loading={loading || uploadMapLoading}
							onClick={handleSubmit(onSubmit)}
						>
							{t("app.global.btn.create")}
						</Button>
					)}
				</>
			}
		>
			<Tabs
				tabBarStyle={{ color: theme.colors.primary }}
				activeKey={activeTab}
				onChange={(key: string) => handleTabChange(key)}
			>
				<TabPane
					tab={t("app.fileUpload.modal.tab.dragger")}
					key={TABS.FILES}
					disabled={kind === ItemKinds.Reprocess}
				>
					<Dragger
						setFiles={setFiles}
						setWarnings={setFileWarnings}
						setErrors={setFileErrors}
						setMaterialsMap={(
							newList: {
								map: string;
								file: UploadFile;
							}[],
						) => {
							setMaterialsMap(newList);
						}}
						materialsMap={materialsMap}
						kind={kind}
						maxSize={maxSize}
					/>
				</TabPane>

				<TabPane
					tab={t("app.fileUpload.modal.tab.options")}
					key={TABS.OPTIONS}
					disabled={files.length === 0 || fileErrors.length > 0}
				>
					<StyledPanelInfoContainer showMetaInfo={showMetaInfo}>
						{showMetaInfo && (
							<StyledInfoColumn1>
								<Typography variant="h3">
									{t("app.createScene.modal.innerTitle")}
								</Typography>

								<div style={{ width: "100%" }}>
									<Controller
										name="name"
										render={renderInput({
											placeholder: t(
												"app.global.placeholder.name",
											),
											value:
												files.length === 1
													? files[0].name
													: undefined,
										})}
										control={control}
										rules={{
											required: showMetaInfo
												? (t(
														"app.form.error.requiredField",
												  ) as string)
												: false,
										}}
										style={{ width: "100%" }}
									/>
									<FormErrorMessage
										errors={errors}
										name="name"
									/>
								</div>

								<div style={{ width: "100%" }}>
									<Controller
										name="description"
										render={renderInput({
											placeholder: t(
												"app.global.placeholder.description",
											),
										})}
										control={control}
										style={{ width: "100%" }}
									/>
								</div>
							</StyledInfoColumn1>
						)}
						<StyledInfoColumn2>
							<Typography variant="h3">
								{t("app.CreateItem.label.advancedOptions")}
							</Typography>
							{/* Scene and Object */}
							{kind !== ItemKinds.Material &&
								optionsData &&
								optionsData.options &&
								optionsData?.options.map((data: any) => {
									const { label, key, hidden } = data;

									if (data.key === "centerGeometry") {
										// hardcoded hiding of centerGeometry option from April 2024 onwards
										return null;
									}

									if (!hidden) {
										return (
											<StyledLine>
												<Typography variant="span">
													{label}
												</Typography>
												<Controller
													name={key}
													render={renderType(data)}
													control={control}
												/>
											</StyledLine>
										);
									}
									return "";
								})}
							{/* Material */}
							{kind === ItemKinds.Material && (
								<div>
									<StyledLine>
										<Typography variant="span">
											{t(
												"app.EditCard.placeholder.repetitionFactor",
											)}{" "}
											X
										</Typography>
										<span className="styled-line-item-2">
											<Controller
												name="repetitionFactorX"
												control={control}
												rules={{
													required: t(
														"app.form.error.requiredField",
													) as string,
												}}
												render={({
													onChange,
													value,
												}) => {
													return (
														<StyledInputNumberContainer>
															<InputNumber
																step={0.1}
																value={value}
																onChange={
																	onChange
																}
																min={-100}
																max={100}
															/>
															<span className="unit">
																m
															</span>
														</StyledInputNumberContainer>
													);
												}}
											/>
											<FormErrorMessage
												errors={errors}
												name="repetitionFactorX"
											/>
										</span>
									</StyledLine>
									<StyledLine>
										<Typography variant="span">
											{t(
												"app.EditCard.placeholder.repetitionFactor",
											)}{" "}
											Y
										</Typography>
										<span className="styled-line-item-2">
											<Controller
												name="repetitionFactorY"
												control={control}
												rules={{
													required: t(
														"app.form.error.requiredField",
													) as string,
												}}
												render={({
													onChange,
													value,
												}) => {
													return (
														<StyledInputNumberContainer>
															<InputNumber
																step={0.1}
																value={value}
																onChange={
																	onChange
																}
																min={-100}
																max={100}
															/>
															<span className="unit">
																m
															</span>
														</StyledInputNumberContainer>
													);
												}}
											/>
											<FormErrorMessage
												errors={errors}
												name="repetitionFactorY"
											/>
										</span>
									</StyledLine>
									<StyledBox>
										<StyledCategoriesContainer>
											{Boolean(
												defaultCategoriesList?.length,
											) && (
												<div>
													<Typography
														variant="h5"
														color="grey800"
													>
														{t(
															`app.filter.modal.label.category`,
														)}
													</Typography>
													{defaultCategoriesList?.map(
														(c: any) => (
															<IconButton
																key={c.value}
																label={
																	c.translatedValue
																}
																variant="square"
																name={c.icon}
																selected={materialCategories.includes(
																	c.value,
																)}
																onClick={(
																	e,
																) => {
																	e.preventDefault();
																	handleCategoryChange(
																		c.value,
																	);
																}}
															/>
														),
													)}
												</div>
											)}
										</StyledCategoriesContainer>
									</StyledBox>
								</div>
							)}
						</StyledInfoColumn2>
					</StyledPanelInfoContainer>
				</TabPane>
			</Tabs>
		</Modal>
	);
};

const StyledPanelInfoContainer = styled.div<{ showMetaInfo: boolean }>`
	width: 100%;
	/* height: 100%; */
	display: grid;
	grid-template-columns: ${({ showMetaInfo }) =>
		showMetaInfo ? "30% 60%" : "1fr"};
	grid-gap: 10%;
`;

const StyledInfoColumn1 = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
`;
const StyledInfoColumn2 = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr;
`;

const StyledBox = styled.div`
	display: grid;
	grid-template-columns: 100%;
`;

const StyledCategoriesContainer = styled.div`
	/* border-right: 1px solid ${({ theme }) => theme.colors.grey400}; */
	margin-top: 20px;
`;

/* const StyledTagsContainer = styled.div`
	margin-top: 20px;
	margin-left: 30px;
`;
 */

const StyledLine = styled.div`
	display: grid;
	grid-template-columns: 40% 60%;
	align-items: center;
	justify-content: center;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.grey400};
	height: ${({ theme }) => theme.size.form.input + 10}px;

	.styled-line-item-2 {
		justify-self: end;
	}
`;

const StyledInputNumberContainer = styled.div`
	.unit {
		margin-left: 10px;
	}
`;

export default UploadModal;
