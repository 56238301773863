import styled from "styled-components/macro";
import { Upload } from "antd";
import React, { useState } from "react";
import { IconButton, Spinner, Icon } from "@hegias/ui-components";

export interface AvatarOnSubmit {
	(file: File, onSuccess: () => void, onError: () => void): void;
}

export interface AvatarUploadProps extends React.ComponentProps<typeof Upload> {
	circle?: boolean;
	onSubmit: AvatarOnSubmit;
	currentImageURL?: string;
	disabled?: boolean;
	size?: string;
}

const AvatarUpload = ({
	circle = false,
	onSubmit,
	size = "150px",
	disabled = false,
	currentImageURL = "/images/placeholderCardImage.png",
}: AvatarUploadProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isHover, setIsHover] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const submitAction = ({ file, onSuccess, onError }: any) => {
		setIsLoading(true);
		onSubmit?.(
			file,
			() => {
				onSuccess();
				setIsLoading(false);
			},
			() => {
				onError();
				setIsLoading(false);
			},
		);
	};

	const onMouseEnter = () => {
		if (!disabled) {
			setIsHover(true);
		}
	};

	const onMouseLeave = () => {
		setIsHover(false);
	};

	const addDefaultSrc = (evt: any) => {
		// eslint-disable-next-line no-param-reassign
		evt.target.src = "/images/placeholderCardImage.png";
	};

	return (
		<StyledUploadContainer size={size} circle={circle}>
			<Upload
				accept="image/*"
				className="avatar-uploader"
				showUploadList={false}
				customRequest={submitAction}
				disabled={disabled}
			>
				<StyledImageContainer
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					size={size}
				>
					<img
						src={currentImageURL}
						onError={addDefaultSrc}
						alt="avatar"
					/>
					{isLoading ? (
						<StyledSpinner>
							<Spinner />
						</StyledSpinner>
					) : (
						<StyledIcon hover={isHover} circle={circle}>
							<Icon
								name="PencilIcon"
								size="80%"
								color="grey800"
								hoverColor="primary"
							/>
						</StyledIcon>
					)}
				</StyledImageContainer>
			</Upload>
		</StyledUploadContainer>
	);
};

const StyledUploadContainer = styled.div<{ size: string; circle: boolean }>`
	width: ${({ size }) => size ?? "100px"};
	height: ${({ size }) => size ?? "100px"};
	border-radius: ${({ circle }) => (circle ? "50%" : "0")};

	overflow: hidden;
	border: 1px solid ${({ theme }) => theme.colors.grey400};
	transition: all 0.3s;

	cursor: pointer;
	&:hover {
		border-style: dashed;
	}
`;

const StyledIcon = styled(IconButton)<{
	hover: boolean | undefined;
	circle: boolean;
}>`
	position: absolute;
	transition: all 0.2s;
	opacity: ${({ hover }) => (hover ? 1 : 0)};
	cursor: ${({ hover }) => (hover ? "pointer" : "not-allowed")};
	top: ${({ circle }) => (circle ? "calc(50% - 18.5px)" : "initial")};
	left: ${({ circle }) => (circle ? "calc(50% - 18.5px)" : "initial")};
	right: ${({ circle }) => (circle ? "calc(50% - 18.5px)" : "5%")};
	bottom: ${({ circle }) => (circle ? "calc(50% - 18.5px)" : "5%")};
`;

const StyledSpinner = styled.div`
	position: absolute;

	width: 100%;
	height: 100%;
	top: 0;
`;

const StyledImageContainer = styled.div<{ size: string }>`
	position: relative;
	width: ${({ size }) => size ?? "100px"};
	height: ${({ size }) => size ?? "100px"};
	object-fit: contain;
	> img {
		object-fit: cover;
		min-width: ${({ size }) => size ?? "100px"};
		min-height: ${({ size }) => size ?? "100px"};
	}
`;

export default AvatarUpload;
