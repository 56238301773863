import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { Icon, Spinner } from "@hegias/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import useProjects from "contexts/Projects/useProjects";
import useOrganizations from "contexts/Organizations/useOrganizations";
import { PROJECTS, SCENES } from "common/routes.constants";
import { StyledSwitcherContainer } from "common/styles/Switcher.styles";
import MenuItemStyles from "components/MenuItem/MenuItem.styles";
import MenuItem from "components/MenuItem/MenuItem";

const { Option } = Select;

const ProjectSwitcher = () => {
	const history = useHistory();
	const { search, pathname } = useLocation();
	const {
		loading: loadingOrganizations,
		current: currentOrganization,
	} = useOrganizations();
	const { current, projects, loading, loadMore } = useProjects();
	const { t } = useTranslation();

	const [switcherState, setSwitcherState] = useState("");

	const handleProjectChange = (shortid = "") => {
		const destination = shortid
			? `/${PROJECTS}/${shortid}/${SCENES}`
			: `/${PROJECTS}`;

		history.push({
			pathname: destination,
			search,
		});
	};

	// click on "All projects" redirects to /projects
	// if current page is outside /projects (e.g. library, profile, etc)
	const handleAllProjectsClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		event.preventDefault();

		if (!pathname.includes(PROJECTS)) {
			history.push({
				pathname: `/${PROJECTS}`,
				search,
			});
		}
	};

	useEffect(() => {
		setSwitcherState(current?.shortid || "");
	}, [current]);

	if (loadingOrganizations) {
		return <span>{/* Do not delete this span */}</span>;
	}

	return (
		<StyledSwitcherContainer className="projectSwitcher">
			<MenuItemStyles />
			<span className="label">
				{t("app.ProjectSwitcher.label.switcher")}
			</span>
			<Select
				value={switcherState}
				style={{ width: "100%" }}
				onChange={handleProjectChange}
				loading={loading}
				bordered={false}
				dropdownClassName="header-select-dropdown"
				suffixIcon={<Icon name="ArrowDownFilledIcon" />}
				className="header-select scrolling-popup"
				virtual
				onPopupScroll={(
					event: React.UIEvent<HTMLDivElement, UIEvent>,
				) => {
					const {
						scrollTop,
						offsetHeight,
						scrollHeight,
					} = event.target as HTMLElement;
					const shouldLoadMore =
						scrollTop + offsetHeight === scrollHeight;

					if (shouldLoadMore && !loading) {
						loadMore();
					}
				}}
			>
				{loading && (
					<Option key="loading1" value="">
						<MenuItem title="Loading projects" />
					</Option>
				)}
				{currentOrganization && !loading && (
					<Option value="">
						<StyledMenuItem
							className="switcher-item"
							title={t("app.ProjectSwitcher.label.allProjects")}
							onClick={handleAllProjectsClick}
						/>
					</Option>
				)}
				{projects.map((project) => (
					<Option key={project._id} value={project.shortid}>
						<MenuItem title={project.meta.name} />
					</Option>
				))}
				{loading && (
					<Option key="loading2" value="">
						<div style={{ marginTop: "10px" }}>
							<Spinner maxSize="25px" />
						</div>
					</Option>
				)}
			</Select>
		</StyledSwitcherContainer>
	);
};

const StyledMenuItem = styled(MenuItem)`
	padding: 0 10px;
`;
export default ProjectSwitcher;
