import { createGlobalStyle } from "styled-components";

const MenuItemStyles = createGlobalStyle`
	.header-select-dropdown {
		padding: 12px 0;
		border-radius: 4px;
		.rc-virtual-list{
			padding: 0 10px;
		}
	}


	.header-select-dropdown .ant-select-item {
		padding: 0;
		&:last-of-type{
			> div > div{
				border-bottom: none;
			}
		}
		.switcher-item{
			padding: 8px 10px;
		}
	}

	/* rewrite ant to solve 2 rows text */
	.header-select-dropdown .ant-select-item-option-content {
		white-space: inherit;
		> div {
			color: ${({ theme }) => theme.colors.grey800};
			&:hover *{
				color: ${({ theme }) => theme.colors.primary};
			}
		}
	}

	/* Remove styles from selected dropdown items */
	.header-select-dropdown
		.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
		background-color: unset;
		.switcher-item,
		span{
			color: ${({ theme }) => theme.colors.primary};
		}
	}

	/* Selected item (outside dropdown) */
	.header-select .ant-select-selection-item {
		font-weight: 600;
		> div{
			padding: 10px 10px 0 0;
		}
		img,
		svg {
			display: none;
		}
	}

	/* Hover item in dropdown (it temporary gets class "ant-select-item-option-active") */
	.header-select-dropdown .ant-select-item-option-active {
		background-color: ${({ theme }) => theme.colors.grey100};
	}
`;

export default MenuItemStyles;
