import React, { useEffect, useState } from "react";
import useFetch from "use-http";
import { useCookies } from "react-cookie";

import {
	ACCESS_TOKEN_COOKIE,
	REFRESH_TOKEN_COOKIE,
} from "common/constants/cookies";
import useFetchContext from "contexts/FetchProvider/useFetchContext";
import getDomain from "utils/getDomain";
import Context from "./context";
import { AuthState, LoginResponseDTO, User } from "./types";

declare global {
	interface Window {
		$crisp: any;
		CRISP_WEBSITE_ID: any;
	}
}

const AuthProvider: React.FC = ({ children }) => {
	const [cookies, setCookie, removeCookie] = useCookies([
		ACCESS_TOKEN_COOKIE,
		REFRESH_TOKEN_COOKIE,
	]);
	const [auth, setAuth] = useState<AuthState | null>({
		token: cookies[ACCESS_TOKEN_COOKIE],
		refreshToken: cookies[REFRESH_TOKEN_COOKIE],
	});
	const [me, setMe] = useState<User | null>(null);

	const { setHeader } = useFetchContext();

	const { request, loading, response } = useFetch(
		"/auth/login",
		(globalOptions: any) => {
			const options = { ...globalOptions };
			delete options.headers?.Authorization;
			return options;
		},
	);

	const { get: getUser } = useFetch("/users/me");

	const setAuthData = (token: string, refreshToken: string) => {
		setHeader("Authorization", `Bearer ${token}`);

		setAuth({
			token,
			refreshToken,
		});

		setCookie(ACCESS_TOKEN_COOKIE, token, {
			path: "/",
			domain: getDomain(),
		});

		setCookie(REFRESH_TOKEN_COOKIE, refreshToken, {
			path: "/",
			domain: getDomain(),
		});
	};

	const login = async (email: string, password: string) => {
		await request.post("", { email, password });

		if (!response?.ok) {
			throw new Error(
				response.data?.message || "Error. Please try again.",
			);
		}
		const result = (await response.json()) as LoginResponseDTO;

		const {
			message: { token, refreshToken },
		} = result;

		setAuthData(token, refreshToken);

		window.$crisp.push(["do", "session:reset"]);

		return response.data;
	};

	const logout = async () => {
		setAuth(null);
		removeCookie(ACCESS_TOKEN_COOKIE, {
			path: "/",
			domain: getDomain(),
		});

		window.$crisp.push(["do", "session:reset"]);

		return Promise.resolve();
	};

	const fetchUser = async () => {
		const user: User = await getUser();
		if (!user || !user.profile) {
			return;
		}

		window?.$crisp?.push([
			"set",
			"user:nickname",
			[`${user.profile.name} ${user.profile.lastName}`],
		]);
		window?.$crisp?.push([
			"set",
			"user:avatar",
			[
				`${process.env.REACT_APP_AWS_S3_URL}/users/${user.shortid}/${user.profile.avatar}`,
			],
		]);
		window?.$crisp?.push(["set", "user:email", [user.profile.email[0]]]);

		setMe(user);
	};

	useEffect(() => {
		/* window.$crisp = [{}]; */
		window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_KEY ?? "";

		const d = document;
		const s = d.createElement("script");
		s.src = "https://client.crisp.chat/l.js";
		s.async = true;
		d.getElementsByTagName("head")[0].appendChild(s);

		if (auth?.token) {
			setHeader("Authorization", `Bearer ${auth.token}`);
			setCookie(ACCESS_TOKEN_COOKIE, auth.token, {
				path: "/",
				domain: getDomain(),
			});
			setCookie(REFRESH_TOKEN_COOKIE, auth.refreshToken, {
				path: "/",
				domain: getDomain(),
			});
			fetchUser();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth]);

	return (
		<Context.Provider
			value={{
				me,
				auth,
				authenticated: !!auth?.token,
				loading,
				login,
				logout,
				setAuthData,
				fetchUser,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default AuthProvider;
