import React, { useEffect } from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";

import * as ROUTES from "common/routes.constants";
import { useOrganizations } from "contexts/Organizations";
import { FullpageSpinner } from "components";
import useAuth from "../../contexts/Auth/useAuth";

const ProtectedRoute = (props: RouteProps) => {
	const history = useHistory();
	const { authenticated } = useAuth();
	const { search, pathname } = useLocation();
	const { member, currentOrganizationPlan } = useOrganizations();
	const { location: nextLocation } = props;

	const roleLevels = {
		OWNER_LEVEL: ["owner"],
		ADMIN_LEVEL: ["owner", "admin"],
		SENIOR_LEVEL: ["owner", "admin", "senior"],
		JUNIOR_LEVEL: ["owner", "admin", "senior", "junior"],
		GUEST_LEVEL: ["owner", "admin", "senior", "junior", "guest"],
	};

	const pagesRole = [
		{
			pathname: `/${ROUTES.ADMIN}`,
			minimumRoleLevelNeeded: [...roleLevels.ADMIN_LEVEL],
		},
		{
			pathname: `/${ROUTES.ADMIN}/${ROUTES.ORGANIZATION}`,
			minimumRoleLevelNeeded: roleLevels.ADMIN_LEVEL,
		},
		{
			pathname: `/${ROUTES.ADMIN}/${ROUTES.MEMBERS}`,
			minimumRoleLevelNeeded: roleLevels.ADMIN_LEVEL,
		},
		{
			pathname: `/${ROUTES.ADMIN}/${ROUTES.MANAGE_PROJECTS}`,
			minimumRoleLevelNeeded: roleLevels.ADMIN_LEVEL,
		},
		{
			pathname: `/${ROUTES.ADMIN}/${ROUTES.SUBSCRIPTION}`,
			minimumRoleLevelNeeded: roleLevels.ADMIN_LEVEL,
		},
	];

	const userGranted = (userRole: any) => {
		const nextPage = pagesRole.find(
			(page: { pathname: string; minimumRoleLevelNeeded: string[] }) => {
				return nextLocation?.pathname.includes(page.pathname);
			},
		);

		/* If user has the minimum role required for the page, show it.
		   If nextPage is null, it means that the nextlocation/nextPage has no role protection. */
		return nextPage
			? nextPage?.minimumRoleLevelNeeded.includes(userRole)
			: true;
	};

	const handleExpiredSubscriptionRedirect = () => {
		if (currentOrganizationPlan?.isExpired) {
			if (
				nextLocation &&
				["/admin/subscription"].includes(nextLocation?.pathname)
			) {
				if (member?.role === "owner") {
					history.push({
						pathname: nextLocation.pathname,
						search,
						state: { destinationPath: pathname },
					});
				} else {
					history.push({
						pathname: `/${ROUTES.EXPIRED_SUBSCRIPTION}`,
						search,
						state: { destinationPath: pathname },
					});
				}
			} else {
				history.push({
					pathname: `/${ROUTES.EXPIRED_SUBSCRIPTION}`,
					search,
					state: { destinationPath: pathname },
				});
			}
		}
	};

	// Authentication protection.
	if (!authenticated) {
		history.push({
			pathname: `/${ROUTES.LOGIN}`,
			search,
			state: { destinationPath: pathname },
		});
	}

	useEffect(() => {
		// Role protection, redirect to /projects if user has no rights.
		if (member) {
			if (!userGranted(member.role)) {
				history.push({
					pathname: `/${ROUTES.PROJECTS}`,
					search,
					state: { destinationPath: pathname },
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [member]);

	useEffect(() => {
		handleExpiredSubscriptionRedirect();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentOrganizationPlan, pathname]);

	// Show spinner (before redirect to /projects) if user has no rights to see the page.
	if (!userGranted(member?.role)) {
		return <FullpageSpinner />;
	}

	return <Route {...props} />;
};

export default ProtectedRoute;
