import React, { lazy } from "react";
import { Switch, useRouteMatch } from "react-router-dom";

import LibraryProvider from "contexts/Library";
import ScenesProvider from "contexts/Scenes";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import { Main as MainLayout } from "../../layout";
import { SCENES } from "../../common/routes.constants";

const SceneView = lazy(() => import("./views/Scene"));
const ProjectView = lazy(() => import("./views/Project"));
const ProjectsListView = lazy(() => import("./views/ProjectsList"));

const Projects: React.FC = () => {
	const { path } = useRouteMatch();

	return (
		<ScenesProvider>
			<LibraryProvider>
				<MainLayout>
					<Switch>
						<ProtectedRoute exact path={path}>
							<ProjectsListView />
						</ProtectedRoute>
						{/* TODO: Handle /projects/:id -> /projects/:id/scenes redirect */}
						<ProtectedRoute
							path={`${path}/:id`}
							render={({ match: { url } }) => (
								<>
									<ProtectedRoute
										exact
										path={`${url}/${SCENES}`}
									>
										<ProjectView />
									</ProtectedRoute>
									<ProtectedRoute
										exact
										path={`${url}/${SCENES}/:id`}
									>
										<SceneView />
									</ProtectedRoute>
								</>
							)}
						/>
					</Switch>
				</MainLayout>
			</LibraryProvider>
		</ScenesProvider>
	);
};

export default Projects;
