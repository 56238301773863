import { createContext } from "react";
import PageQuery from "../../common/types/PageQuery";
import { ProjectsContext } from "./types";

const initialQuery: PageQuery = {
	filters: {},
	limit: 10,
	page: 0,
	search: {
		text: "",
		fields: [],
	},
	sort: "meta.name",
};

const context = createContext<ProjectsContext>({
	current: undefined,
	projects: [],
	loading: false,
	query: initialQuery,
	pagedResponse: undefined,
	setQuery: () => {
		throw new Error("setQuery not implemented");
	},
	setCurrentProject: () => {
		throw new Error("setCurrentProject not implemented");
	},
	loadMore: () => {
		throw new Error("loadMore not implemented");
	},
	refreshCurrent: () => {
		throw new Error("refreshCurrent not implemented");
	},
	updateCurrent: () => {
		throw new Error("updateCurrent not implemented");
	},
});

export default context;
