// Unauthorized routes
export const LOGIN = "login";
export const FORGOT_PASSWORD = "forgot-password";
export const REGISTER = "register";
export const REGISTER_SUCCESS = "register-success";
export const INVITE = "invite";
export const VERIFY = "verify";
export const SET_NEW_PASSWORD = "password";
export const ERROR = "error";
export const CREATE_ORGANIZATION_SUCCESS = "create-organization-success";

// Account routes
export const ACCOUNT = "account";
export const ACCOUNT_PROFILE = "profile";
export const ACCOUNT_SETTINGS = "settings";
export const DEVICES = "devices";

// Admin routes
export const ADMIN = "admin";
export const SUBSCRIPTION = "subscription";
export const MANAGE_PROJECTS = "manage-projects";
export const MEMBERS = "members";
export const ORGANIZATION = "organization";

export const PROJECTS = "projects";
export const SCENES = "scenes";
export const OBJECTS = "objects";
export const LIBRARY = "library";
export const EXPIRED_SUBSCRIPTION = "expired-subscription";

export const CREDIT_CARD = "credit-card";

export const TRASH = "trash";
export const CREATE_ORGANIZATION = "create-organization";

export const OAUTH_CB = "oauth-callback";

export const WELCOMEPOPUP = "welcomepopup";

