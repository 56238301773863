import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import translationDE from "./assets/languages/de.json";
import translationEN from "./assets/languages/en.json";
import translationIT from "./assets/languages/it.json";
import translationFR from "./assets/languages/fr.json";

const resources = {
	en: {
		translation: translationEN,
	},
	de: {
		translation: translationDE,
	},
	it: {
		translation: translationIT,
	},
	fr: {
		translation: translationFR,
	},
};

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	//   .use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		fallbackLng: "en",
		resources,
		cleanCode: true,
		supportedLngs: ["en", "it", "de", "fr"],
		// keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			prefix: "*",
			suffix: "*",
			escapeValue: false, // react already safes from xss
		},
		// debug: true,
	});

export default i18n;
