import React, { useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Typography } from "@hegias/ui-components";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

import { Modal, InputTag } from "components";
import { renderInput } from "components/FormComponents";
import { Scene } from "contexts/Scenes/types";
import useTags from "hooks/useTags";
import { useToaster } from "contexts/Toaster";

export interface EditSceneProps {
	visible: boolean;
	onCancel: () => void;
	callback: () => void;
	projectId: string;
	scene: Scene | null;
}

interface EditSceneFormField {
	name: string;
	description: string;
	tags: string[];
}

const EditScene = ({
	visible,
	onCancel,
	callback,
	projectId,
	scene,
}: EditSceneProps) => {
	const { control, handleSubmit, reset } = useForm<EditSceneFormField>({
		defaultValues: {
			name: scene?.meta.name,
			description: scene?.meta.description,
			tags: scene?.meta.tags,
		},
	});
	const { t } = useTranslation();

	const { put, loading, response } = useFetch();

	const toaster = useToaster();

	const { tags: originalTags } = useTags("scene");

	const tagsList = useMemo(
		() => originalTags.map((tag: string) => ({ label: tag, value: tag })),
		[originalTags],
	);

	const onSubmit = async ({
		name,
		description,
		tags,
	}: EditSceneFormField) => {
		const updatedScene = {
			meta: {
				name,
				description,
				tags,
			},
		};
		const res = await put(
			`/projects/${projectId}/scenes/${scene?._id}`,
			updatedScene,
		);

		if (response.ok) {
			toaster.success({
				message: t("app.global.msg.title.success"),
				description: res.translatedMessage,
			});
		} else {
			toaster.error({
				message: t("app.global.msg.title.note"),
				description: res.translatedMessage,
			});
		}

		onCancel();
		callback();
	};

	useEffect(() => {
		if (scene?._id) {
			reset({
				name: scene?.meta.name,
				description: scene?.meta.description,
				tags: scene?.meta.tags,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scene]);

	return (
		<Modal
			title={t("app.editScene.modal.title")}
			visible={visible}
			footer={
				<Button loading={loading} onClick={handleSubmit(onSubmit)}>
					{t("app.global.btn.save")}
				</Button>
			}
			size="small"
			onCancel={onCancel}
		>
			<StyledContainer>
				<StyledSingleLine>
					<Controller
						render={renderInput({
							placeholder: t("app.global.placeholder.name"),
							style: { width: "100%" },
						})}
						name="name"
						control={control}
					/>
				</StyledSingleLine>

				<StyledSingleLine>
					<Controller
						render={renderInput({
							placeholder: t(
								"app.global.placeholder.description",
							),
							style: { width: "100%" },
						})}
						name="description"
						control={control}
					/>
				</StyledSingleLine>
				<StyledSingleLine>
					<Typography as="h5">
						{t("app.global.placeholder.tags")}
					</Typography>
					<Controller
						name="tags"
						control={control}
						render={({ onChange, value }) => (
							<InputTag
								onChange={onChange}
								value={value}
								mode="tags"
								tags={tagsList}
							/>
						)}
					/>
				</StyledSingleLine>
			</StyledContainer>
		</Modal>
	);
};

const StyledContainer = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	align-items: center;
`;

const StyledSingleLine = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	margin-top: 5px;
	margin-bottom: 5px;
	/* align-items: center; */
	/* justify-content: center; */
	/* justify-self: center; */
	width: 100%;
`;

export default EditScene;
