import { createContext } from "react";
import { ModalContextProps } from "./types";

const defaultValue = {
	error: () => { },
	info: () => { },
	warning: () => { },
	success: () => { },
	confirm: () => { },
};

export const ModalContext = createContext<ModalContextProps>(defaultValue);
