import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import useFetch, {
	Provider as FetchProvider,
	CachePolicies,
	Options,
} from "use-http";

import { ROUTES } from "common";
import {
	ACCESS_TOKEN_COOKIE,
	REFRESH_TOKEN_COOKIE,
} from "common/constants/cookies";
import getDomain from "utils/getDomain";
import Context from "./context";
import { ConfigurableHeader, ConfigurableHeaderName } from "./types";

const ConfiguredFetchProvider: React.FC = ({ children }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation();

	const [cookies, setCookie] = useCookies([
		ACCESS_TOKEN_COOKIE,
		REFRESH_TOKEN_COOKIE,
	]);

	// eslint-disable-next-line @typescript-eslint/ban-types
	const [headers, setHeaders] = useState<ConfigurableHeader | {}>({
		Accept: "application/json",
		"Content-Type": "application/json",
	});
	const {
		post: fetchRefreshToken,
		response: refreshTokenResponse,
	} = useFetch(`${process.env.REACT_APP_BASE_URL}/auth/token/refresh`, {
		body: {
			refreshToken: cookies[REFRESH_TOKEN_COOKIE],
		},
	});

	const getHttpOptions = (): Partial<Options> => {
		return {
			cachePolicy: CachePolicies.NO_CACHE,
			headers,
			retryOn: async ({ response }) => {
				if (response?.status === 401) {
					const responseJson = await response?.json();

					if (responseJson?.message !== "msg.token.expired") {
						history.push({
							pathname: `/${ROUTES.LOGIN}`,
							search,
						});
					}

					if (
						responseJson?.message !== "msg.auth.incorrectLoginData"
					) {
						logger.info("Auto-refreshing token in silence...");

						await fetchRefreshToken();

						if (refreshTokenResponse.ok) {
							const newLoginData = await refreshTokenResponse.json();
							setCookie(
								ACCESS_TOKEN_COOKIE,
								newLoginData.message.token,
								{
									path: "/",
									domain: getDomain(),
								},
							);
							setCookie(
								REFRESH_TOKEN_COOKIE,
								newLoginData.message.refreshToken,
								{
									path: "/",
									domain: getDomain(),
								},
							);

							window.location.reload();

							return true;
						}

						logger.error(
							"There was an error fetching refresh token - redirecting to the Login page",
						);
						history.push({
							pathname: `/${ROUTES.LOGIN}`,
							search,
						});

						return false;
					}
				}

				return false;
			},
			retries: 1,
			interceptors: {
				// Attempt to translate response message codes
				response: async ({ response }) => {
					const res: any = response;
					if (res.data?.message && res.data?.message?.includes(".")) {
						res.data.translatedMessage = t(
							res.data.message,
							res.data.message,
						);
					}

					return res;
				},
			},
		};
	};

	const removeHeader = (name: ConfigurableHeaderName) => {
		const newHeaders = { ...headers } as ConfigurableHeader;

		if (newHeaders[name]) {
			delete newHeaders[name];
		}

		setHeaders(newHeaders);
	};

	const setHeader = (name: ConfigurableHeaderName, value: string) => {
		setHeaders({
			...headers,
			[name]: value,
		});
	};

	return (
		<Context.Provider
			value={{
				removeHeader,
				setHeader,
				headers,
			}}
		>
			<FetchProvider
				options={getHttpOptions()}
				url={process.env.REACT_APP_BASE_URL}
			>
				{children}
			</FetchProvider>
		</Context.Provider>
	);
};

export default ConfiguredFetchProvider;
