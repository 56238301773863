import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { Checkbox, Radio, TreeSelect } from "antd";
import { Chip, IconButton, IconName, Select, Typography } from "@hegias/ui-components";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import useTags, { TagKind } from "hooks/useTags";

import { Modal } from "components/Modal";
import PageQuery, { PageSortOption } from "common/types/PageQuery";

const { SHOW_PARENT } = TreeSelect;

interface FilterAndSortModalProps {
	formData: PageQuery;
	open: boolean;
	categoriesList?: { value: any; icon: IconName }[];
	subCategoriesList?: { value: any; label: string }[];
	tagKind: TagKind;
	onCancel: () => void;
	onOk: (updatedPageQuery: PageQuery) => void;
}

interface RadioChangeEvent {
	checked: boolean;
	target: any;
	stopPropagation: () => void;
	preventDefault: () => void;
	nativeEvent: MouseEvent;
}

const FilterAndSortModal = ({
	formData = {} as PageQuery,
	open,
	categoriesList = [],
	subCategoriesList = [],
	tagKind = "all",
	onCancel,
	onOk,
}: FilterAndSortModalProps) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={
				<Typography variant="h4">
					{t("app.filter.modal.title")}
				</Typography>
			}
			visible={open}
			onCancel={onCancel}
			footer={null}
			wrapClassName="filter-and-sort-dialog"
			width={800}
		>
			<Form
				data={formData}
				categoriesList={categoriesList}
				subCategoriesList={subCategoriesList}
				tagKind={tagKind}
				onOk={onOk}
			/>
		</Modal>
	);
};

const Form = ({
	data,
	categoriesList,
	subCategoriesList,
	tagKind,
	onOk,
}: {
	data: PageQuery;
	categoriesList: { value: any; icon: IconName; translatedValue?: string }[];
	subCategoriesList: { value: any; label?: string }[];
	tagKind: TagKind;
	onOk?: (updatedPageQuery: PageQuery) => void;
}) => {
	const { t, i18n } = useTranslation();

	const sortOptions = [
		{
			label: t(`app.filter.modal.option.name`),
			value: "meta.name",
		},
		{
			label: t(`app.filter.modal.option.createdAt`),
			value: "meta.tsCreated",
		},
		{
			label: t(`app.filter.modal.option.updatedAt`),
			value: "meta.tsModified",
		},
	];

	const sortOrderOptions = [
		{
			label: t(`app.filter.modal.label.asc`),
			value: "asc",
		},
		{
			label: t(`app.filter.modal.label.desc`),
			value: "desc",
		},
	];

	const visibilityOptions = [
		{
			label: t(`app.global.option.private`),
			value: "isPrivate",
		},
		{
			label: t(`app.global.option.public`),
			value: "isPublic",
		},
	];

	// From DTO to local state
	const destructureSort = (pq: PageQuery) => {
		const { sort } = pq;
		const sortOrder = sort.includes("-") ? "desc" : "asc";

		return {
			sortBy: sortOrder === "asc" ? sort : sort.replace("-", ""),
			sortOrder,
		};
	};

	// From local state to DTO
	const structureSort = (sortBy: string, sortOrder: string) =>
		(sortOrder === "asc" ? sortBy : `-${sortBy}`) as PageSortOption;

	// From DTO to local state
	const destructureFilters = (
		pq: PageQuery,
		// TODO: add our new level1 (subcategoryEN) and potentially also BMS levels 2, 3, and 4
	): { visibility: string[]; category: string[]; subCategory1: string[], subCategory2: string[], subCategory3f: string[], tag: string[] } => {
		const { filters = { category: [], subCategory1: [], subCategory2: [], subCategory3f: [], tag: [] } } = pq;

		const filterState = {
			visibility: Object.keys(filters).filter((key) =>
				["isPrivate", "isPublic"].includes(key),
			),
			category: filters.category,
			subCategory1: filters.subCategory1,
			subCategory2: filters.subCategory2,
			subCategory3f: filters.subCategory3f,
			tag: filters.tag,
		};

		logger.info("[FilterAndSortMotal] destructureFilters", {
			pq,
			filterState,
		});
		return filterState;
	};

	// From local state to DTO
	const structureFilters = ({
		visibility,
		category,
		subCategory1,
		subCategory2,
		subCategory3f,
		tag,
	}: {
		visibility: Array<string>;
		category: Array<string>;
		subCategory1: Array<string>;
		subCategory2: Array<string>;
		subCategory3f: Array<string>;
		tag: Array<string>;
	}) => {
		if (!visibility.length) {
			return {
				category,
				subCategory1,
				subCategory2,
				subCategory3f,
				tag,
			};
		}

		return {
			isPublic: visibility.includes("isPublic"),
			isPrivate: visibility.includes("isPrivate"),
			category,
			subCategory1: subCategory1?.length > 0 ? subCategory1 : undefined,
			subCategory2: subCategory2?.length > 0 ? subCategory2 : undefined,
			subCategory3f: subCategory3f?.length > 0 ? subCategory3f : undefined,
			locale: i18n.language,
			tag,
		};
	};

	const visibilityFiltersLocal = useMemo(
		() => destructureFilters(data).visibility,
		[data],
	);

	const categoryFilterLocal = useMemo(
		() => destructureFilters(data).category,
		[data],
	);
	const subCategory1FilterLocal = useMemo(
		() => destructureFilters(data).subCategory1,
		[data],
	);
	const subCategory2FilterLocal = useMemo(
		() => destructureFilters(data).subCategory2,
		[data],
	);
	const subCategory3fFilterLocal = useMemo(
		() => destructureFilters(data).subCategory3f,
		[data],
	);
	const tagFilterLocal = useMemo(() => destructureFilters(data).tag, [data]);

	const { sortBy, sortOrder } = useMemo(() => destructureSort(data), [data]);
	const [sortValue, setSortValue] = useState(sortBy);
	const [tValue, setTValue] = useState(['0-0-0']);
	const [sortOrderValue, setSortOrderValue] = useState(sortOrder);
	const sortDTO = useMemo(() => structureSort(sortValue, sortOrderValue), [
		sortValue,
		sortOrderValue,
	]);

	const [visibilityValue, setVisibilityValue] = useState<Array<string>>(
		visibilityFiltersLocal,
	);
	const [categoryValue, setCategoryValue] = useState<Array<string>>(
		categoryFilterLocal,
	);
	const [subCategory1Value, setSubCategory1Value] = useState<string[]>(
		subCategory1FilterLocal,
	);
	const [subCategory2Value, setSubCategory2Value] = useState<string[]>(
		subCategory2FilterLocal,
	);
	const [subCategory3fValue, setSubCategory3fValue] = useState<string[]>(
		subCategory3fFilterLocal,
	);
	const [tagValue, setTagValue] = useState<Array<string>>(tagFilterLocal);
	const filtersDTO = useMemo(
		() =>
			structureFilters({
				visibility: visibilityValue,
				category: categoryValue,
				subCategory1: subCategory1Value,
				subCategory2: subCategory2Value,
				subCategory3f: subCategory3fValue,
				tag: tagValue,
			}),
		[visibilityValue, categoryValue, subCategory1Value, subCategory2Value, subCategory3fValue, tagValue],
	);

	const handleSortChange = (event: RadioChangeEvent) => {
		setSortValue(event.target.value);
	};

	const handleSortOrderChange = (event: RadioChangeEvent) => {
		setSortOrderValue(event.target.value);
	};

	const handleVisibilityChange = (checkedValue: CheckboxValueType[]) => {
		if (!checkedValue.length) {
			return;
		}

		setVisibilityValue(checkedValue as string[]);
	};

	const handleCategoryChange = (category: string) => {
		const shouldRemoveFromList = categoryValue.includes(category);
		const updatedList = shouldRemoveFromList ? [] : [category];

		// Reset subcategory selection
		setSubCategory1Value([]);
		setSubCategory2Value([]);
		setSubCategory3fValue([]);

		// Set new category selection
		setCategoryValue(updatedList);
	};

	const handleSubCategory1Change = (subCategory1: string) => {
		if (!subCategory1) {
			// Reset subcategory selection
			setSubCategory1Value([]);
			setSubCategory2Value([]);
			setSubCategory3fValue([]);
		}
		else {
			// Set new subcategory selection
			setSubCategory1Value([subCategory1]);
			setSubCategory2Value([]);
			setSubCategory3fValue([]);
		}
	};
	const handleSubCategory2Change = (subCategory2: string) => {
		if (!subCategory2) {
			// Reset subcategory selection
			setSubCategory2Value([]);
			setSubCategory3fValue([]);
		}
		else {
			// Set new subcategory selection
			setSubCategory2Value([subCategory2]);
			setSubCategory3fValue([]);
		}
	};
	const handleSubCategory3fChange = (subCategory3f: string) => {
		const shouldRemoveFromList = subCategory3fValue.includes(subCategory3f);
		if (shouldRemoveFromList) {
			setSubCategory3fValue([]);
		}
		else {
			// Set new subcategory selection
			setSubCategory3fValue([subCategory3f]);
		}
	};

	const handleTagChange = (tag: string) => {
		const shouldRemoveFromList = tagValue.includes(tag);
		const updatedList = shouldRemoveFromList
			? tagValue.filter((c) => c !== tag)
			: [...tagValue, tag];

		setTagValue(updatedList);
	};

	const { tags: tagsList } = useTags(
		tagKind,
		visibilityValue.includes("isPrivate"),
		visibilityValue.includes("isPublic"),
	);

	useEffect(() => {
		onOk?.({
			...data,
			filters: filtersDTO,
			sort: sortDTO,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortValue, sortOrderValue, visibilityValue, categoryValue, subCategory1Value, subCategory2Value, subCategory3fValue, tagValue]);

	const level0categories = categoriesList.map((c, index) => {
		return {
			title: c.translatedValue,
			name: c.value,
			value: `0-${index}`,
			key: `0-${index}`,
			children: [],
		};
	});
	const level2categories = {
		en: [
			"Sanitär Armaturen",
			"Duschsysteme",
			"Sifons",
			"Badewannen Stahl",
			"Badewannen Kunststoff",
			"Badewannen Whirlpool",
			"Badheizkörper",
			"Bidets",
			"Garnituren-Programme",
			"Duschenrinnen",
			"Duschenwannen Stahl",
			"Duschenwannen Mineralwerkstoff",
			"Ausgussbecken, Waschtröge Kunststoff",
			"Brauseschläuche",
			"Klosettsitze",
			"Kosmetikspiegel",
			"Lichtspiegel",
			"Spiegel",
			"Spiegelschränke",
			"Badezimmermöbel",
			"Trennwände für Duschen",
			"Armaturenzubehör",
			"Waschtische Keramik",
			"Wandklosetts Keramik UP",
			"Einzel- und Industriegarnituren",
			"Vorhangstangen",
			"Abdeckplatten zu Einbauspülkästen",
		],
		de: [
			"Sanitär Armaturen",
			"Duschsysteme",
			"Sifons",
			"Badewannen Stahl",
			"Badewannen Kunststoff",
			"Badewannen Whirlpool",
			"Badheizkörper",
			"Bidets",
			"Garnituren-Programme",
			"Duschenrinnen",
			"Duschenwannen Stahl",
			"Duschenwannen Mineralwerkstoff",
			"Ausgussbecken, Waschtröge Kunststoff",
			"Brauseschläuche",
			"Klosettsitze",
			"Kosmetikspiegel",
			"Lichtspiegel",
			"Spiegel",
			"Spiegelschränke",
			"Badezimmermöbel",
			"Trennwände für Duschen",
			"Armaturenzubehör",
			"Waschtische Keramik",
			"Wandklosetts Keramik UP",
			"Einzel- und Industriegarnituren",
			"Vorhangstangen",
			"Abdeckplatten zu Einbauspülkästen",
		],
		fr: [
			"Robinetterie sanitaire",
			"Systèmes de douche",
			"Siphons",
			"Baignoires en acier",
			"Baignoires en matière synthétique",
			"Baignoires Whirlpool",
			"Radiateurs de bain",
			"Bidets",
			"Programmes d'accessoires",
			"Rigoles de douche",
			"Receveurs de douche en acier",
			"Receveurs de douche en coulée minérale",
			"Vidoirs, bacs à laver en matière synthétique",
			"Tuyaux de douche",
			"Sièges de WC",
			"Miroirs cosmétiques",
			"Miroirs lumineux",
			"Miroirs",
			"Armoires de toilette",
			"Meubles pour salle de bains",
			"Séparations pour douches",
			"Accessoires pour robinetterie",
			"Lavabos en céramique",
			"Cuvettes murales en céramique UP",
			"Accessoires seuls et d'industrie",
			"Tringles pour rideaux de douche",
			"Plaques de fermeture pour chasses à encastrer",
		],
		it: [
			"Rubinetteria sanitario",
			"Sisteme da doccia",
			"Sifoni",
			"Vasche da bagno in acciaio",
			"Vasche da bagno in materia sintetica",
			"Vasche da bagno Whirlpool",
			"Radiatori da bagno",
			"Bidet",
			"Programmi accessori",
			"Canali da doccia",
			"Vasche da doccia in acciaio",
			"Vasche da doccia colata minerale",
			"Vuotatoi, lavatoi in materia sintetica",
			"Tubi flessibili",
			"Sedili per WC",
			"Specchi cosmetici",
			"Specchi con illuminazione",
			"Specchi",
			"Armadietti da bagno",
			"Mobili da bagno",
			"Separazioni per vasche da doccia",
			"Accessori per rubinetteria",
			"Lavabi in ceramica",
			"Vasi sospesi in ceramica UP",
			"Accessori singoli e per industria",
			"Aste per tende da doccia",
			"Placce di copertura per casette ad incasso",
		]
	};

	const levels34categories = {
		en : [
			"Gétaz-Richner-Reco Grupp",
			"Gétaz-Richner-Reco Gruppe",
			"Bühlmann",
			"Regulierventile",
			"Bodenschatz",
			"Handtuchhalter/Handtuchhaken",
			"Reinigungsgeräte",
			"Abfallbehälter",
			"Duschablagen",
			"Papierhalter",
			"Reserverollenhalter",
			"Haltegriffe",
			"Laufen",
			"Geberit",
			"Schmidlin",
			"ProCasa Cinque",
			"ProCasa Uno",
			"ProCasa Tre",
			"ProCasa",
			"ProCasa Uno E",
			"ProCasa Tre E",
			"ProCasa Cinque E",
			"Duschenpunktabläufe",
			"Senzes",
			"ProCasa Tre LED",
			"ProCasa Cinque LED",
			"ProCasa Uno LED",
			"Spiegelschrank ProCasa Cinque",
			"ProCasa Cinque rahmenlos",
			"ProCasa Cinque teilgerahmt",
			"ProCasa Cinque rahmenlos Plus",
			"Gétza-Richner-Reco Gruppe",
			"Chic 22",
			"Innox",
			"Zubehör Adesio",
			"Lindo",
			"Nia",
			"Nia Adesio",
			"Liv",
			"Signa",
			"Moderna Plus",
			"Omega",
			"Sigma",
			"Pro S",
			],
		de : [
			"Gétaz-Richner-Reco Grupp",
			"Gétaz-Richner-Reco Gruppe",
			"Bühlmann",
			"Regulierventile",
			"Bodenschatz",
			"Handtuchhalter/Handtuchhaken",
			"Reinigungsgeräte",
			"Abfallbehälter",
			"Duschablagen",
			"Papierhalter",
			"Reserverollenhalter",
			"Haltegriffe",
			"Laufen",
			"Geberit",
			"Schmidlin",
			"ProCasa Cinque",
			"ProCasa Uno",
			"ProCasa Tre",
			"ProCasa",
			"ProCasa Uno E",
			"ProCasa Tre E",
			"ProCasa Cinque E",
			"Duschenpunktabläufe",
			"Senzes",
			"ProCasa Tre LED",
			"ProCasa Cinque LED",
			"ProCasa Uno LED",
			"Spiegelschrank ProCasa Cinque",
			"ProCasa Cinque rahmenlos",
			"ProCasa Cinque teilgerahmt",
			"ProCasa Cinque rahmenlos Plus",
			"Gétza-Richner-Reco Gruppe",
			"Chic 22",
			"Innox",
			"Zubehör Adesio",
			"Lindo",
			"Nia",
			"Nia Adesio",
			"Liv",
			"Signa",
			"Moderna Plus",
			"Omega",
			"Sigma",
			"Pro S",
			],
		fr : [
			"Gétaz-Richner-Reco Grupp",
			"Gétaz-Richner-Reco Gruppe",
			"Bühlmann",
			"Robinets de réglage",
			"Bodenschatz",
			"Porte-linges/Crochets",
			"Ustensiles de nettoyage",
			"Boites à ordures",
			"Tablettes de douche",
			"Porte-papiers",
			"Porte-papiers de réserve",
			"Barres d'appui",
			"Laufen",
			"Geberit",
			"Schmidlin",
			"ProCasa Cinque",
			"ProCasa Uno",
			"ProCasa Tre",
			"ProCasa",
			"ProCasa Uno E",
			"ProCasa Tre E",
			"ProCasa Cinque E",
			"Ecoulement de douche",
			"Senzes",
			"ProCasa Tre LED",
			"ProCasa Cinque LED",
			"ProCasa Uno LED",
			"Armoire de toilette ProCasa Cinque",
			"ProCasa Cinque sans cadre",
			"ProCasa Cinque cadre partiel",
			"ProCasa Cinque sans cadre Plus",
			"Gétza-Richner-Reco Gruppe",
			"Chic 22",
			"Innox",
			"Accessoires Adesio",
			"Lindo",
			"Nia",
			"Nia Adesio",
			"Liv",
			"Signa",
			"Moderna Plus",
			"Omega",
			"Sigma",
			"Pro S",
			],
		it : [
			"Gétaz-Richner-Reco Grupp",
			"Gétaz-Richner-Reco Gruppe",
			"Bühlmann",
			"Rubinetti di regolaggio",
			"Bodenschatz",
			"Portasalviette/Ganci",
			"Attrezzi di pulitura",
			"Contenitori spazzatura",
			"Ripiani da doccia",
			"Portacarte",
			"Portacarte di riserva",
			"Corrimani",
			"Laufen",
			"Geberit",
			"Schmidlin",
			"ProCasa Cinque",
			"ProCasa Uno",
			"ProCasa Tre",
			"ProCasa",
			"ProCasa Uno E",
			"ProCasa Tre E",
			"ProCasa Cinque E",
			"Scarico di doccia",
			"Senzes",
			"ProCasa Tre LED",
			"ProCasa Cinque LED",
			"ProCasa Uno LED",
			"Armadietto da bagno ProCasa Cinque",
			"ProCasa Cinque senza telaio",
			"ProCasa Cinque telaio parziale",
			"ProCasa Cinque senza telaio Plus",
			"Gétza-Richner-Reco Gruppe",
			"Chic 22",
			"Innox",
			"Accessori Adesio",
			"Lindo",
			"Nia",
			"Nia Adesio",
			"Liv",
			"Signa",
			"Moderna Plus",
			"Omega",
			"Sigma",
			"Pro S",
			],
	};


	// console.log("level1 subCatList", subCategoriesList);

	const level1categories = subCategoriesList.map((c, index) => {
		return {
			title: c.label,
			name: c.value,
			value: `0-${index}`,
			key: `0-${index}`,
			children: [],
		};
	});

	// console.log("level1categories:", level1categories);
/*
	for (let i = 0; i < level2categories.length; i += 1) {
		const newLevel2Item = {
			title: level2categories[i],
			name: level2categories[i],
			value: `0-6-${i}`,
			key: `0-6-${i}`,
			children: []
		};
		level0categories.find((c) => c.name === 'bathroom')?.children.push(newLevel2Item);

		for (let j = 0; j < level3categories.length; j += 1) {
			const newLevel3Item = {
				title: level3categories[j],
				name: level3categories[j],
				value: `0-6-${i}-${j}`,
				key: `0-6-${i}-${j}`,
				children: []
			}
			newLevel2Item.children.push(newLevel3Item);

			for (let k = 0; k < level4categories.length; k += 1) {
				const newLevel4Item = {
					title: level4categories[k],
					name: level4categories[k],
					value: `0-6-${i}-${j}-${k}`,
					key: `0-6-${i}-${j}-${k}`,
					children: []
				}
				newLevel3Item.children.push(newLevel4Item);
			}
		}
	}

	const onTreeSelectChange = (newValue: string[]) => {
		console.log('treeSelect onChange ', newValue);
		setTValue(newValue);
		if (newValue?.[0]) {
			let valueName = level0categories.find((c) => c.value === newValue[0])?.name || "";
			if (!valueName) {
				valueName = level2categories[parseInt(newValue[0].split('-')[2], 10)];
			}
			console.log('treeSelect onChange name =>', valueName);

			handleCategoryChange(valueName);
		}
		else if (newValue?.[0] === "") {
			handleCategoryChange("");
		}
	};

	const tProps = {
		treeData: level0categories.find((c) => c.value === 'bathroom')?.children || [],
		tValue,
		onChange: onTreeSelectChange,
		treeCheckable: true,
		showCheckedStrategy: SHOW_PARENT,
		placeholder: 'Please select',
		style: {
			width: '100%',
		},
		treeDefaultExpandAll: false,
	};

*/
/* console.log("categoryValue:", categoryValue,
			"subCategory1Value:", subCategory1Value,
			"subCategory2Value:", subCategory2Value,
			"subCategory3fValue:", subCategory3fValue );
*/

	return (
		<StyledForm>
			<header>
				{!!visibilityValue.length && (
					<section>
						<Typography variant="span" color="grey800">
							{t(`app.filter.modal.label.visibility`)}
						</Typography>
						<Checkbox.Group
							options={visibilityOptions}
							value={visibilityValue}
							onChange={handleVisibilityChange}
						/>
					</section>
				)}

				<section>
					<Typography variant="span" color="grey800">
						{t(`app.filter.modal.label.sortBy`)}
					</Typography>
					<Radio.Group
						name="sort_by"
						onChange={(e) => {
							handleSortChange(e as RadioChangeEvent);
						}}
						value={sortValue}
					>
						{sortOptions.map((option) => (
							<Radio key={option.value} value={option.value}>
								{option.label}
							</Radio>
						))}
					</Radio.Group>
				</section>

				<section>
					<Typography variant="span" color="grey800">
						{t(`app.filter.modal.label.sortOrder`)}
					</Typography>
					<Radio.Group
						name="sort_order"
						onChange={(e) => {
							handleSortOrderChange(e as RadioChangeEvent);
						}}
						value={sortOrderValue}
					>
						{sortOrderOptions.map((option) => (
							<Radio key={option.value} value={option.value}>
								{option.label}
							</Radio>
						))}
					</Radio.Group>
				</section>
			</header>
			<main>
				{Boolean(categoriesList?.length) && (
					<div className="panel category">
						<Typography variant="h5" color="grey800">
							{t(`app.filter.modal.label.category`)}
						</Typography>
						{categoriesList.map((c) => (
							<IconButton
								key={c.value}
								label={c.translatedValue}
								variant="square"
								name={c.icon}
								selected={categoryValue.includes(c.value)}
								onClick={(e) => {
									e.preventDefault();
									handleCategoryChange(c.value);
								}}
							/>
						))}
					</div>
				)}
				{false && Boolean(categoriesList?.length) && (
					<div className="panel category">
						<Typography variant="h5" color="grey800">
							{t(`app.filter.modal.label.category`)}
						</Typography>
						<TreeSelect
							{...tProps}
							maxTagCount={2}
							showSearch
						/>
					</div>
				)}

				{Boolean(true) && (
					<div className="panel">
						{/* TODO subcategory selection starting from level 1 down to level 4, only for bathroom */}
						<Typography variant="h5" color="grey800">
							{t(`app.filter.modal.label.subcategory`)}
						</Typography>

						<Select
							value={subCategoriesList.find((item) => item.label === subCategory1Value?.[0])?.value}
							onChange={(e) => {
								if (e.target.value) {
									handleSubCategory1Change(t(`app.global.option.objectsubcategory.${e.target.value}`));
								} else {
									handleSubCategory1Change("");
								}
							}}
							disabled={!(categoryValue.includes("bathroom"))}
							fullWidth
							// type="simple"
							/*
							style={{
								width: '100%',
								textAlign: 'center',
								color: 'black',
								backgroundColor: 'red',
								border: '0',
								borderRadius: '6px',
							}}
							*/
							options={categoryValue.includes("bathroom") ? [{value: "", label: t("app.global.btn.all")}, ...(subCategoriesList.sort((a, b) => a.label?.localeCompare(b.label || "z") || 1))] : []}
						/>

						<div style={{ height: 16 }} />

						{Boolean(subCategory1Value?.length > 0) && !!(level2categories?.[i18n.language as keyof typeof level2categories]) && (
							<Select
								value={subCategory2Value?.[0] || ""}
								onChange={(e) => {
									if (e.target.value) {
										handleSubCategory2Change(e.target.value);
									} else {
										handleSubCategory2Change("");
									}
								}}
								disabled={!(categoryValue.includes("bathroom"))}
								fullWidth
								// type="simple"
								/*
								style={{
									width: '100%',
									textAlign: 'center',
									color: 'black',
									backgroundColor: 'red',
									border: '0',
									borderRadius: '6px',
								}}
								*/
								options={categoryValue.includes("bathroom") ? [
									{value: "", label: t("app.global.btn.all")},
									...level2categories[i18n.language].sort((a, b) => a.localeCompare(b)).map((c, index) => ({value: c, label: c}))
								] : []}
							/>
						)}

						{/*
						<Typography variant="h5" color="grey800">
							Unter-Unter-Unterkategorie (Level 3)
						</Typography>
						<Select
							// value={extractMonth(date)}
							onChange={(e) => {
								if (e.target.value) {
									// handleSubCategoryChange(t(`app.global.option.objectsubcategory.${e.target.value}`));
								} else {
									// handleSubCategoryChange("");
								}
								// handleSubCategoryChange(e.target.value);
							}}
							disabled={!(categoryValue.includes("bathroom"))}
							fullWidth
							// type="simple"
							options={categoryValue.includes("bathroom") ? [
								{value: "", label: "Alle Hersteller"},
								...level3categories.map((c, index) => ({value: index.toString(), label: c}))
							] : []}
						/>

						<Typography variant="h5" color="grey800">
							Unter-Unter-Unter-Unterkategorie (Level 4)
						</Typography>
						<Select
							// value={extractMonth(date)}
							onChange={(e) => {
								if (e.target.value) {
									// handleSubCategoryChange(t(`app.global.option.objectsubcategory.${e.target.value}`));
								} else {
									// handleSubCategoryChange("");
								}
								// handleSubCategoryChange(e.target.value);
							}}
							disabled={!(categoryValue.includes("bathroom"))}
							fullWidth
							// type="simple"
							options={categoryValue.includes("bathroom") ? [
								{value: "", label: "Alle Designlinien"},
								...level4categories.map((c, index) => ({value: index.toString(), label: c}))
							] : []}
						/>
						*/}

						<div style={{ height: 16 }} />

						{Boolean(subCategory1Value?.length > 0) && Boolean(levels34categories[i18n.language]) && (
							<div
								style={{
									maxHeight: 225,
									overflowY: "auto",
								}}
							>
								{levels34categories[i18n.language].map((i) => (
									<Chip
										key={i}
										style={{ marginRight: 6, marginBottom: 7 }}
										selected={subCategory3fValue?.includes(i)}
										onClick={(e) => {
											e.preventDefault();
											handleSubCategory3fChange(i);
										}}
									>
										{i}
									</Chip>
								))}
							</div>
						)}


						{/* <TreeSelect
							{...tProps}
							maxTagCount={2}
							showSearch
						/>
						*/}
					</div>
				)}
			</main>

				{Boolean(tagsList?.length) && (
					<div className="panel">
						<Typography variant="h5" color="grey800">
							{t(`app.filter.modal.label.tag`)}
						</Typography>
						{tagsList.map((i) => (
							<Chip
								key={i}
								style={{ marginRight: 7, marginBottom: 7 }}
								selected={tagValue.includes(i)}
								onClick={(e) => {
									e.preventDefault();
									handleTagChange(i);
								}}
							>
								{i}
							</Chip>
						))}
					</div>
				)}
		</StyledForm>
	);
};

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;

	header {
		height: 70px;
		width: 100%;
		display: flex;
		padding-left: 24px;
		padding-right: 24px;
		place-items: center;
		background-color: ${(props) => props.theme.colors.grey400};
	}

	main {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
	}

	section {
		display: flex;
		flex-direction: column;

		&:not(:first-child) {
			margin-left: 20px;
		}
	}

	.panel {
		width: 100%;
		padding: 18px;

		&.category {
			border-right: 1px solid ${(props) => props.theme.colors.grey400};
		}
	}

	.lower-panel {
		width: 100%;
		padding: 18px;

		&.category {
			border-right: 1px solid ${(props) => props.theme.colors.grey400};
		}
	}
`;

export default FilterAndSortModal;
