import React from "react";
import { ToasterContextProps } from "./types";

const defaultValue = {
	error: () => { },
	info: () => { },
	warning: () => { },
	success: () => { },
	toaster: () => { },
};

export const ToasterContext = React.createContext<ToasterContextProps>(
	defaultValue,
);
