import { createContext } from "react";
import PageQuery from "common/types/PageQuery";
import { LibraryContext } from "./types";

const initialQuery: PageQuery = {
	filters: {},
	limit: 24,
	page: 0,
	search: {
		text: "",
		fields: [],
	},
	sort: "meta.name",
};

const context = createContext<LibraryContext>({
	objects: [],
	objectsQuery: initialQuery,
	pagedObjects: undefined,
	currentObject: undefined,
	loadingObjects: false,
	errorObjects: null,
	setCurrentObject: () => {
		throw new Error("setCurrentObject not implemented");
	},
	resetCurrentObject: () => {
		throw new Error("resetCurrentObject not implemented");
	},
	setObjectsQuery: () => {
		throw new Error("setObjectsQuery not implemented");
	},
	loadMoreObjects: () => {
		throw new Error("loadMoreObjects not implemented");
	},
	allObjects: [],
	fetchAllObjects: () => {
		throw new Error("fetchAllObjects not implemented");
	},


	materials: [],
	materialsQuery: initialQuery,
	pagedMaterials: undefined,
	currentMaterial: undefined,
	loadingMaterials: false,
	errorMaterials: null,
	setCurrentMaterial: () => {
		throw new Error("setCurrentMaterial not implemented");
	},
	setMaterialsQuery: () => {
		throw new Error("setMaterialsQuery not implemented");
	},
	loadMoreMaterials: () => {
		throw new Error("loadMoreMaterials not implemented");
	},
	allMaterials: [],
	fetchAllMaterials: () => {
		throw new Error("fetchAllMaterials not implemented");
	},

	lights: [],
	lightsQuery: initialQuery,
	pagedLights: undefined,
	currentLight: undefined,
	loadingLights: false,
	errorLights: null,
	setCurrentLight: () => {
		throw new Error("setCurrentLight not implemented");
	},
	setLightsQuery: () => {
		throw new Error("setLightsQuery not implemented");
	},
	loadMoreLights: () => {
		throw new Error("loadMoreLights not implemented");
	},
	allLights: [],
	fetchAllLights: () => {
		throw new Error("fetchAllLights not implemented");
	},


});

export default context;
