import React from "react";

import useOrganizations from "contexts/Organizations/useOrganizations";
import { OrganizationMember, UserRole } from "contexts/Organizations/types";

export interface Role {
	weight: number;
	id: UserRole;
}

export const userRoleWeight: { [key in UserRole]: number } = {
	guest: 10,
	junior: 20,
	senior: 30,
	admin: 40,
	owner: 50,
};

export interface CanProps {
	userRole: UserRole | UserRole[];
	exact?: boolean;
	anyOf?: boolean;
}

/**
 * Render children depending on user role
 *
 * @param role Minimum required role in order to render Children
 * @param {boolean} anyOf
 * @param {boolean} exact require **exact** role
 * @returns children or null
 * ```
 * // Set minimum required role to "admin"
 * <Can userRole={"admin"}>
 *   <button>Admin and owner will see this</button>
 * </Can>
 *
 * // Set multiple allowed roles
 * <Can userRole={["guest", "admin"]} anyOf={true}>
 * <button>Both "guest" and "admin" will see this</button>
 * </Can>
 *
 * // Require exactly "junior" role
 * <Can userRole="junior" exact={true}>
 * <button>only "junior" will see this</button>
 * </Can>
 * ```
 */
const Can: React.FC<CanProps> = ({
	children,
	userRole,
	anyOf = false,
	exact = false,
}): any => {
	const { member } = useOrganizations();
	if (!member) {
		// TODO: handle (un)expected behavior on pages outside Project
		// where initial `organizations` list is empty
		return null;
	}

	const currentUserWeight =
		userRoleWeight[(member as OrganizationMember).role];

	const minimumRequiredWeight = Array.isArray(userRole)
		? getRoleMinWeight(userRole)
		: userRole;

	let allowedWeights = [minimumRequiredWeight];
	if (anyOf && Array.isArray(userRole)) {
		allowedWeights = [
			minimumRequiredWeight,
			...userRole.map((r) => userRoleWeight[r]),
		];
	}

	if (exact) {
		// Current user role is exact match to required
		const exactMatch = allowedWeights.includes(currentUserWeight);
		return exactMatch ? children : null;
	}

	const allowed = currentUserWeight >= minimumRequiredWeight;

	return allowed ? children : null;
};

export const getRoleMinWeight = (roles: UserRole[]): number => {
	const [lowest] = roles.sort(
		(a, b) => userRoleWeight[a] - userRoleWeight[b],
	);
	return userRoleWeight[lowest];
};

export default Can;
