import React from "react";
import { ControllerRenderProps } from "react-hook-form";
import { Input, Checkbox, InlineEditor, Select } from "@hegias/ui-components";
import { Slider } from "components";

export const renderInput = (extraProps?: any) => ({
	onChange,
	value,
	onBlur,
	name,
}: ControllerRenderProps) => (
	<Input
		onChange={onChange}
		value={value}
		onBlur={onBlur}
		{...extraProps}
		name={name}
	/>
);

export const renderCheckbox = (extraProps?: any) => ({
	onChange,
	onBlur,
	value,
	name,
}: ControllerRenderProps) => (
	<Checkbox
		onBlur={onBlur}
		onChange={(e) => onChange(e.target.checked)}
		defaultChecked={value}
		name={name}
		{...extraProps}
	/>
);

export const renderInlineEditor = (extraProps?: any) => ({
	onChange,
	onBlur,
	value,
	name,
}: ControllerRenderProps) => (
	<InlineEditor
		onChange={onChange}
		value={value}
		onBlur={onBlur}
		{...extraProps}
		name={name}
		style={{ border: "none" }}
	/>
);

export const renderSlider = (extraProps?: any) => ({
	onChange,
	value,
	name,
}: {
	onChange: (value: number) => void;
	value: number;
	name: string;
}) => <Slider onChange={onChange} value={value} name={name} {...extraProps} />;

export const renderSelect = (extraProps?: any) => ({
	onChange,
	onBlur,
	value,
	name,
}: ControllerRenderProps) => (
	<Select
		onChange={onChange}
		value={value}
		onBlur={onBlur}
		{...extraProps}
		name={name}
	/>
);
