import React, { useState } from "react";

import Logger from "utils/Logger";
import { DEV_ENVIRONMENT, PROD_ENVIRONMENT } from "common/environment";
import DevToolsDialog from "./Dialog/DevToolsDialog";
import Context from "./context";

declare global {
	interface Window {
		debug: () => void;
		logger: Logger;
	}
}

window.logger = new Logger({
	level: DEV_ENVIRONMENT ? "info" : "error",
	off: PROD_ENVIRONMENT,
});

const debugModeAvailable = process.env.REACT_APP_DEBUG === "true";

const DevToolsProvider: React.FC = ({ children }) => {
	const [debugEnabled, setDebugEnabled] = useState<boolean>(DEV_ENVIRONMENT);

	window.debug = () => setDebugEnabled(!debugEnabled);

	if (!debugModeAvailable) {
		return <>{children}</>;
	}

	return (
		<Context.Provider
			value={{
				highlightComponents: false,
			}}
		>
			<>
				{debugEnabled && <DevToolsDialog />}
				{children}
			</>
		</Context.Provider>
	);
};

export default DevToolsProvider;
