import { createContext } from "react";
import { TenantContext } from "./types";

const context = createContext<TenantContext>({
	tenant: undefined,
	loading: true,
	subdomainsSupported: process.env.REACT_APP_SUBDOMAINS_SUPPORTED?.includes("true") || false,
	subdomain: undefined,
});

export default context;
