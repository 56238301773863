import React from "react";
import styled from "styled-components/macro";

import SpinnerWithMessage, {
	SpinnerWithMessageProps,
} from "./SpinnerWithMessage";

type FullpageSpinnerProps = SpinnerWithMessageProps;

const FullpageSpinner: React.FC<FullpageSpinnerProps> = ({ children }) => {
	return (
		<StyledContainer>
			<SpinnerWithMessage>{children}</SpinnerWithMessage>
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	height: 100vh;
	width: 100vw;
	z-index: 10;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default FullpageSpinner;
