import qs from "query-string";

export type MembersSort = "-user.profile.name";

export type OrganizationSort =
	| "meta.tsModified"
	| "meta.name"
	| "meta.tsCreated"
	| "-meta.tsModified"
	| "-meta.name"
	| "-meta.tsCreated";

export type PageSortOption = OrganizationSort | MembersSort;

export default interface PageQuery {
	limit: number;
	page: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filters?: any;

	// Eg. search:{text:'Matteo', fields:['meta.name', 'meta.description']}
	search: {
		text: string;
		fields: string[];
	};

	sort: PageSortOption;
}

/**
 * Combine query-string with custom logic to parse `PageQuery` to URL string
 * @example - Returns string like: limit=10&page=1&search[text]=Project&search[fields]=meta.name&sort=meta.name
 */
export const getQueryStringFromPageQuery = (pq: PageQuery): string => {
	const { search, filters = {}, ...rest } = pq;

	const pqString = qs.stringify({ ...rest });
	const searchFields = search.fields
		.map((f) => `search[fields]=${f}`)
		.join("&");

	const searchString =
		search.text.length && searchFields.length
			? `&search[text]=${search.text}&${searchFields}`
			: "";

	const filterFields = Object.keys(filters)
		// eslint-disable-next-line array-callback-return
		.filter((field) => {
			if (Array.isArray(filters[field])) {
				if (filters[field]?.length) {
					return field;
				}
			} else {
				return field;
			}

		})
		.map((f) => filters[f] ? `filters[${f}]=${filters[f]}` : "")
		.join("&");
	const filter = Object.keys(filters)?.length ? `&${filterFields}` : "";
	return `${pqString}${searchString}${filter}`;
};
