import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { Typography, IconButton, Icon } from "@hegias/ui-components";

interface PageHeaderProps {
	title: string;
	dialogs?: any;
	children?: any;
	backEvent?: any;
}

const PageHeader = ({
	title,
	children,
	backEvent,
	dialogs,
}: PageHeaderProps) => {
	const { t } = useTranslation();
	return (
		<StyledHeaderContainer>
			<StyledNav>
				{backEvent ? (
					<IconButton
						tooltipProps={{
							text: t("app.global.btn.back"),
							position: "bottom",
						}}
						onClick={backEvent}
					>
						<Icon
							name="ArrowLeftIcon"
							color="secondary"
							hoverColor="primary"
						/>
					</IconButton>
				) : (
					<span> </span>
				)}
				<Typography variant="h1" color="primary">
					{title}
				</Typography>
				{children}
			</StyledNav>

			<>{dialogs}</>
		</StyledHeaderContainer>
	);
};

const StyledNav = styled.nav`
	display: flex;
	align-items: center;
	flex-basis: 100%;
	position: relative;
	h1 {
		margin-right: 30px;
		font-weight: ${({ theme }) => theme.typography.weight.thin};
		align-items: center;
		display: flex;
		line-height: 50px;
		margin-bottom: 0;
		@media (max-width: 999px) {
			font-size: ${({ theme }) => theme.typography.text.big};
			font-weight: ${({ theme }) => theme.typography.weight.normal};
		}
	}

	button {
		margin-right: 10px;
	}
	> div:first-child {
		display: none;
		@media (max-width: 999px) {
			display: inline-flex;
		}
	}

	@media (max-width: 999px) {
		> *:nth-child(3) {
			margin-left: auto;
		}
		> div:last-child {
			button {
				margin-right: none;
			}
		}
	}
`;

const StyledHeaderContainer = styled.div`
	justify-self: flex-start;
	margin-bottom: 30px;
	width: 100%;
`;

export default PageHeader;
