import React from "react";
import styled from "styled-components/macro";
import { Typography, IconButton, Icon } from "@hegias/ui-components";

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
	padding?: boolean;
	backEvent?: any;
}

export const Title = ({
	title,
	backEvent,
	children,
	...otherProps
}: TitleProps) => {
	return (
		<StyledPageHeader {...otherProps}>
			{backEvent ? (
				<IconButton onClick={backEvent}>
					<Icon
						name="ArrowLeftIcon"
						color="secondary"
						hoverColor="primary"
					/>
				</IconButton>
			) : (
				<span> </span>
			)}
			<Typography variant="h1" color="primary">
				{title}
			</Typography>
			{children}
		</StyledPageHeader>
	);
};

const StyledPageHeader = styled.header`
	border-bottom: ${(props) => `1px solid ${props.theme.colors.grey300}`};

	width: 100%;

	display: flex;
	align-items: center;
	flex-basis: 100%;
	position: relative;
	margin-bottom: 15px;
	> button:first-child {
		margin-right: 10px;
		display: none;
	}
	h1 {
		margin: 30px 15px 30px 0px;
	}
	@media (max-width: 999px) {
		position: sticky;
		top: 70px;
		background-color: ${({ theme }) => theme.colors.grey200};
		z-index: 5;
		h1 {
			font-size: ${({ theme }) => theme.typography.text.big};
			margin: 15px 0px;
		}

		> button:first-child {
			margin-right: 10px;
			display: block;
		}

		> *:nth-child(3) {
			margin-left: auto;
		}
		> div:last-child {
			button {
				margin-right: none;
			}
		}
	}

	@media (max-width: 999px) {
	}
`;
