import React, { useState } from "react";
import { Popover, Menu } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

const StyledMenuItem = styled(Menu.Item)`
	&& {
		width: 150px;
		margin-top: 0;
		margin-bottom: 0;
		line-height: 22px;
		padding: 5px 12px;
		height: initial;
		color: ${({ theme }) => theme.colors.grey700};
		&:hover {
			background-color: ${({ theme }) => theme.colors.grey100} !important;
			color: ${({ theme }) => theme.colors.primary};
		}
		&.ant-menu-item-selected {
			background-color: transparent;
		}
	}
`;

interface ProjectMenuProps extends React.HTMLAttributes<HTMLDivElement> {
	button: React.ReactNode;
	content: { label: string; action: () => void }[];
}

const ProjectMenu = ({ button, content }: ProjectMenuProps) => {
	const { t } = useTranslation();
	const [visible, setVisibility] = useState(false);

	const mapContent = (menu: { label: string; action: () => void }[]) => {
		return (
			<Menu style={{ borderRight: "none" }}>
				{menu.map((item) => (
					<StyledMenuItem
						key={item.label}
						onClick={() => {
							setVisibility(false);
							item.action();
						}}
						style={{}}
					>
						{t(item.label)}
					</StyledMenuItem>
				))}
			</Menu>
		);
	};

	return (
		<Popover
			content={mapContent(content)}
			trigger="click"
			visible={visible}
			onVisibleChange={setVisibility}
			placement="topRight"
		>
			{button}
		</Popover>
	);
};

export default ProjectMenu;
