import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import useFetch from "use-http";

import { FullpageSpinner } from "../../components";
import Context from "./context";

const APP_VERSION = process.env.REACT_APP_VERSION || "0.0.0";

const TenantProvider: React.FC = ({ children }) => {
	const { search } = useLocation();
	const parsed = qs.parse(search);

	// eslint-disable-next-line prefer-const
	let requestedTenant = parsed?.tenant; // Organization SHORT ID

	const params = qs.stringify({
		tenant: requestedTenant,
		fallback: process.env.REACT_APP_DEFAULT_DEV_TENANT,
	});

	const subdomain: string | undefined = React.useMemo(() => {
		const { host } = window.location;
		const chunks = host.split(".");

		const isSubdomain = chunks.length === 3 || chunks.length === 4;

		// const isHegiasDomain = chunks[1]?.includes?.("hegias");

		if (!isSubdomain /*  || !isHegiasDomain */) {
			return undefined;
		}

		return chunks[0];

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.host]);

	/**
	 * Priorities when resolving Tenant:
	 * 1. `tenant` - Organization SHORT ID in query params
	 * 2. `x-tenant-host` - Host  where the request is coming from
	 * 3. `fallback` - Organization SHORT ID in query params
	 */
	const { loading, error, data, get } = useFetch(`/tenant?${params}`, {
		headers: {
			...(subdomain && { "x-tenant-host": subdomain }),
		},
	});

	useEffect(() => {
		document.body.setAttribute("data-version", APP_VERSION);
	}, []);

	useEffect(() => {
		get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <FullpageSpinner />;
	}

	if (error) {
		logger.debug("[TenantProvider] Critical error", error);
		return (
			<div>
				<h5>TenantProvider: Unexpected error</h5>
				<pre style={{ padding: 20, backgroundColor: "yellow" }}>
					{JSON.stringify(error)}
				</pre>
			</div>
		);
	}

	return (
		<Context.Provider
			value={{
				tenant: data?.result,
				error,
				loading,
				subdomain,
				subdomainsSupported:
					process.env.REACT_APP_SUBDOMAINS_SUPPORTED?.includes(
						"true",
					) || false,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default TenantProvider;
