import React from "react";
import styled from "styled-components/macro";

export type AsideMenuProps = React.HTMLAttributes<HTMLDivElement>;
export type AsideMenuListProps = React.HTMLAttributes<HTMLUListElement>;
export type AsideMenuItemProps = React.HTMLAttributes<HTMLLIElement>;

const AsideMenu = ({ children, ...otherProps }: AsideMenuProps) => {
	return <StyledContainer {...otherProps}>{children}</StyledContainer>;
};

AsideMenu.List = ({ children, ...otherProps }: AsideMenuListProps) => {
	return <StyledUl {...otherProps}>{children}</StyledUl>;
};

AsideMenu.Item = ({ children, ...otherProps }: AsideMenuItemProps) => {
	return <StyledLi {...otherProps}>{children}</StyledLi>;
};
AsideMenu.ItemBack = ({ children, ...otherProps }: AsideMenuItemProps) => {
	return (
		<StyledLi {...otherProps}>
			<div className="back">{children}</div>
		</StyledLi>
	);
};

export const StyledContainer = styled.div`
	margin-top: 50px;
	width: 100%;
	top: 20px;
	position: sticky;
	align-self: flex-start;
`;

export const StyledUl = styled.ul`
	padding: 0;
	position: relative;
	max-height: 100vh;
	overflow: auto;
`;

export const StyledLi = styled.li`
	list-style-type: none;
	a {
		font-size: ${({ theme }) => theme.typography.text.big};
		font-weight: ${({ theme }) => theme.typography.weight.bold};
		padding: 8px 35px;
		width: 100%;
		margin: 0 !important;
		color: ${({ theme }) => theme.colors.grey800};
		display: block;
		border: none;
		&:hover {
			color: ${({ theme }) => theme.colors.primary};
		}
	}

	> div.back {
		color: ${({ theme }) => theme.colors.grey800};
		padding: 0 0 35px 0;
		margin-left: -14px;
		a {
			font-weight: ${({ theme }) => theme.typography.weight.normal};
			font-size: ${({ theme }) => theme.typography.text.normal};
			svg {
				max-width: 10px;
				margin-right: 5px;
				path {
					fill: ${({ theme }) => theme.colors.grey800};
				}
			}
			&:hover {
				svg {
					path {
						fill: ${({ theme }) => theme.colors.primary};
					}
				}
			}
		}
	}
`;

export default AsideMenu;
