import React, { useEffect, useState } from "react";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import { getDomain } from "tldts";

import useAuth from "contexts/Auth/useAuth";
import useTenant from "contexts/Tenant/useTenant";
import useFetchContext from "contexts/FetchProvider/useFetchContext";
import useLocalStorage from "hooks/useLocalStorage";
import { User } from "contexts/Auth/types";
import { Organization, OrganizationMember, Plan } from "./types";
import Context from "./context";

const OrganizationsProvider: React.FC = ({ children }) => {
	const [persistedCurrent, setPersistedCurrent] = useLocalStorage(
		"selected-organization",
		"",
	);

	const [plan, setPlan] = useState<null | Plan>(null);

	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [current, setCurrent] = useState<Organization | undefined>();
	const [member, setMember] = useState<OrganizationMember>();
	const {
		tenant,
		loading: tenantLoading,
		subdomainsSupported,
		subdomain,
	} = useTenant();
	const { authenticated } = useAuth();
	const [user, setUser] = useState<User | null>(null);
	const { setHeader, removeHeader } = useFetchContext();
	const { i18n } = useTranslation();

	const { get, loading, response, error } = useFetch(
		"/organizations?limit=500&sort=meta.shortname",
	);

	const { get: getMember, response: memberResponse } = useFetch(
		`/organizations/${current?._id}/members/me`,
	);

	const { get: getPlan, response: planResponse } = useFetch(
		`/organizations/${current?._id}/plans/active`,
	);

	const { get: getUser } = useFetch("/users/me");

	const { get: getById, response: responseById } = useFetch("/organizations");

	const fetchCurrentUser = async () => {
		const userResponse: User = await getUser();
		setUser(userResponse);
	};

	const fetchCurrentOrg = async () => {
		await getById(`/${current?._id}`);
		if (responseById.ok) {
			setCurrent(responseById.data);
			setOrganizations(
				organizations.map((o) =>
					o._id === current?._id ? responseById.data : o,
				),
			);
		}
	};

	const setCurrentOrganization = (_id?: string) => {
		const targetOrg = organizations?.find((o) => o._id === _id);

		const fallbackOrg = organizations?.find(
			(o) => o._id === process.env.REACT_APP_DEFAULT_ORG,
		);

		const curr = targetOrg || fallbackOrg;
		setCurrent(curr);

		// TODO: Revisit this logic with Josip&Matteo:
		// when and which org to set for x-org
		// eslint-disable-next-line no-unused-expressions
		if (curr?._id) {
			setHeader("x-org", curr?._id);
			setPersistedCurrent(curr?._id);
		} else {
			removeHeader("x-org");
		}

		fetchCurrentUser();
	};

	const fetchOrganizations = async () => {
		const initialOrganizations = await get();
		if (response.ok) {
			setOrganizations(initialOrganizations?.results);
		}
	};

	const fetchCurrentMember = async () => {
		const currentMember = await getMember();
		if (memberResponse.ok) {
			setMember(currentMember);
		}
	};

	const fetchPlan = async () => {
		const currentPlan = await getPlan();
		if (planResponse.ok) {
			setPlan(currentPlan);
		}
	};

	useEffect(() => {
		if (authenticated && tenant?._id && !tenantLoading) {
			fetchOrganizations();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authenticated]);

	useEffect(() => {
		if (organizations?.length) {
			const targetOrgBySubdomain = organizations.find(
				/* If org is not found, it means that user does not belong to it. */
				(o) => o.meta.subdomain?.toLowerCase() === subdomain,
			);

			const shouldUsePersistedOrg =
				persistedCurrent && !subdomainsSupported;

			if (shouldUsePersistedOrg) {
				setCurrentOrganization(persistedCurrent);
				return;
			}

			if (!targetOrgBySubdomain && subdomainsSupported && subdomain) {
				const domain = getDomain(window.location.toString());

				const fallbackOrganization = organizations.find(
					({ _id }) =>
						_id === persistedCurrent ||
						_id === process.env.REACT_APP_DEFAULT_ORG,
				);

				window.location.assign(
					`${window.location.protocol}//${fallbackOrganization?.meta?.subdomain}.${domain}/projects`,
				);

				return;
			}

			setCurrentOrganization(tenant?._id);
		}
		// TODO: Else - principal?

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizations, tenant?._id, subdomain]);

	useEffect(() => {
		if (current?._id && user?._id) {
			fetchCurrentMember();
			fetchPlan();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [current?._id, user?._id]);

	useEffect(() => {
		if (user && user?.preferences?.language !== i18n.language) {
			i18n.changeLanguage(user.preferences.language);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	if (error) {
		logger.debug("[OrganizationsProvider] Critical error", error);
		return <p>OrganizationsProvider Error: {JSON.stringify(error)}</p>;
	}

	return (
		<Context.Provider
			value={{
				current,
				organizations,
				error,
				loading,
				loaded: !loading && !!response?.data,
				member,
				user,
				currentOrganizationPlan: plan,
				fetchCurrentUser,
				fetchCurrentMember,
				fetchOrganizations,
				setCurrentOrganization,
				fetchCurrentOrg,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default OrganizationsProvider;
