import React from "react";
import styled from "styled-components/macro";
import { IconButton, Input } from "@hegias/ui-components";

interface ClosableInputProps extends React.ComponentProps<typeof Input> {
	handleClose: () => void;
}

const ClosableInput = ({ handleClose, ...otherProps }: ClosableInputProps) => {
	return (
		<StyledInputContainer>
			<Input
				{...otherProps}
				endAdornment={
					<StyledIconButton name="CloseIcon" onClick={handleClose} />
				}
			/>
		</StyledInputContainer>
	);
};

const StyledInputContainer = styled.div`
	display: flex;
	width: 100%;
	> div {
		span:last-child {
			width: initial;
		}
	}
`;

const StyledIconButton = styled(IconButton)`
	/* margin-left: 8px; */
	max-width: 50px;
`;

export default ClosableInput;
