import { Modal } from "antd";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ModalContext } from "./ModalContext";
import { ModalProps } from "./types";

const { useModal } = Modal;

const ModalProvider: React.FunctionComponent = ({ children }) => {
	const [modal, contextHolder] = useModal();
	const { t } = useTranslation();

	const confirm = useCallback(
		({
			okText = t("app.global.btn.yes"),
			cancelText = t("app.global.btn.no"),
			...otherProps
		}: ModalProps) => {
			modal.confirm({ okText, cancelText, ...otherProps });
		},
		[modal, t],
	);

	// Info icon on modal
	const info = useCallback(
		({
			okText = t("app.global.btn.yes"),
			cancelText = t("app.global.btn.no"),
			...otherProps
		}: ModalProps) => {
			modal.info({ okText, cancelText, ...otherProps });
		},
		[modal, t],
	);

	// Success icon on modal
	const success = useCallback(
		({
			okText = t("app.global.btn.yes"),
			cancelText = t("app.global.btn.no"),
			...otherProps
		}: ModalProps) => {
			modal.success({ okText, cancelText, ...otherProps });
		},
		[modal, t],
	);

	// Warning icon of modal
	const warning = useCallback(
		({
			okText = t("app.global.btn.yes"),
			cancelText = t("app.global.btn.no"),
			...otherProps
		}: ModalProps) => {
			modal.warning({ okText, cancelText, ...otherProps });
		},
		[modal, t],
	);

	// Error icon of modal
	const error = useCallback(
		({
			okText = t("app.global.btn.yes"),
			cancelText = t("app.global.btn.no"),
			...otherProps
		}: ModalProps) => {
			modal.error({ okText, cancelText, ...otherProps });
		},
		[modal, t],
	);

	const providerValue = useMemo(
		() => ({ error, warning, info, confirm, success }),
		[error, warning, info, confirm, success],
	);

	return (
		<ModalContext.Provider value={providerValue}>
			{contextHolder}
			{children}
		</ModalContext.Provider>
	);
};

export default ModalProvider;
