import { useEffect, useState } from "react";
import useFetch from "use-http";
import { useLocation } from "react-router-dom";
import { IconType } from "antd/lib/notification";
import parse from "html-react-parser";
import { useToaster } from "contexts/Toaster";
import useInterval from "hooks/useInterval";
import useLocalStorage from "hooks/useLocalStorage";
import useOrganizations from "contexts/Organizations/useOrganizations";

type NotificationType = {
	_id: string;
	content: string;
	author: string;
	starts: Date;
	ends: Date;
	status: "active" | "pending" | "archived";
	severity: IconType;
};

const { NOTIFICATIONS_POOLING_INTERVAL = 5 } = process.env;
const POOLING_INTERVAL = Number(NOTIFICATIONS_POOLING_INTERVAL) * 60000;

const AppNotifications = () => {
	const [, setLastRead] = useLocalStorage<number | null>(
		"notifications-lastread",
		null,
	);
	const [notificationsReadList, setNotificationsReadList] = useLocalStorage<
		string[]
	>("notifications-readlist", []);

	const { current: currentOrganization } = useOrganizations();
	const { get } = useFetch("");
	const { pathname } = useLocation();

	const toaster = useToaster();

	const [notifications, setNotifications] = useState<NotificationType[]>([]);
	const [message, setMessage] = useState<NotificationType | null>(null);

	const fetchNotifications = async () => {
		if (!currentOrganization) {
			return;
		}

		try {
			const pagedResponse = await get(
				`/notifications?status=active?size=100`,
			);

			setNotifications(pagedResponse.results);
		} catch (error) {
			logger.debug("Error fetching notifications", error);
		}
	};

	/**
	 * Conditions to satisy in order to display notification
	 * - Today must be inside range: notification.start and notification.end date
	 * - notification.status === active
	 * - localstorage says notification has not been read
	 */
	const getActiveNotification: (
		list: NotificationType[],
	) => NotificationType | null = (list) => {
		const isDateInsideRange = (el: NotificationType) =>
			new Date(el.starts) < new Date() && new Date() < new Date(el.ends);
		const latestListRaw = notificationsReadList;

		const latestNotificationsReadList = latestListRaw;

		const isNotSeen = (el: NotificationType) =>
			!latestNotificationsReadList.includes(el._id);

		return list.filter(isDateInsideRange).find(isNotSeen) ?? null;
	};

	// Check for unread notifications after every fetch
	useEffect(() => {
		const latestMessage = getActiveNotification(notifications);
		setMessage(latestMessage);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications]);

	// severity options: 'success', 'info', 'warning', 'error'
	const openNotification: (severity: IconType) => void = (
		severity = "info",
	) => {
		if (message) {
			toaster[severity]({
				message: parse(message.content),
				description: "",
				duration: 0,
				// Update read notifications (unique only)
				onClose: () => {
					setLastRead(new Date().valueOf());
					if (message) {
						setNotificationsReadList(
							Array.from(
								new Set([
									...notificationsReadList,
									message._id,
								]),
							),
						);
					}
					setMessage(null);
				},
			});
		}
	};

	useEffect(() => {
		const notSharedScene = !pathname.startsWith("/scenes/");

		if (message && notSharedScene) {
			// toaster.destroy();
			openNotification(message.severity);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [message?._id]);

	useEffect(() => {
		fetchNotifications();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentOrganization]);

	useInterval(() => {
		if (message) {
			return;
		}

		fetchNotifications();
	}, POOLING_INTERVAL);

	return null;
};

export default AppNotifications;
