import { useEffect, useState } from "react";
// import { useCookies } from "react-cookie";
import { useGA4React } from "ga-4-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import getDomain from "utils/getDomain";

const PageTitles = () => {
	const ga = useGA4React(process.env.REACT_APP_GOOGLE_ANALYTICS);

	const { t } = useTranslation();

	const [title, setTitle] = useState(document.title);
	// const [cookies, setCookie] = useCookies(["existSidebar"]);

	const location = useLocation();

	const { pathname } = location;

	useEffect(() => {
		document.title = title;
	}, [title]);

	useEffect(() => {
		if (ga && !process.env.REACT_APP_DEBUG) {
			if (pathname.includes("projects/")) {
				if (pathname.includes("scenes/")) {
					ga.pageview("projects/:id/scenes/:id");
				} else {
					ga.pageview("projects/:id/scenes");
				}
			} else if (pathname.includes("library/")) {
				const libraryTokens = pathname.split("/");
				libraryTokens.pop();
				libraryTokens.push(":id");

				const libraryPath = libraryTokens.join("/");
				ga.pageview(libraryPath);
			} else if (pathname.includes("/password")) {
				ga.pageview("password");
			} else if (pathname.includes("/verify")) {
				ga.pageview("verify");
			} else if (pathname.includes("/invite")) {
				ga.pageview("invite");
			} else {
				ga.pageview(pathname);
			}
		}

		let tokens = pathname.split("/").filter((token) => token);
		if (pathname.includes("scenes/")) {
			tokens = ["scene"];
		} else if (pathname.includes("projects/")) {
			tokens = ["project"];
		} else if (pathname.includes("library")) {
			tokens = ["library"];
		} else if (pathname.includes("/password")) {
			tokens = ["password"];
		} else if (pathname.includes("/verify")) {
			tokens = ["verify"];
		} else if (pathname.includes("/invite")) {
			tokens = ["invite"];
		}

		setTitle(t(`page.meta.${tokens.join(".")}.title`));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, ga]);

	return null;
};

export default PageTitles;
