export const logLevels = {
	debug: 1,
	info: 2,
	warn: 3,
	error: 4,
}

export type LogLevel = keyof typeof logLevels;

interface LoggerOptions {
	off: boolean;
	level: LogLevel;
}

export const defaultLoggerOptions: LoggerOptions = {
	off: false,
	level: "info",
}

class Logger {
	private instance: any;

	private opts: LoggerOptions;

	constructor(options?: LoggerOptions) {
		if (!this.instance) {
			this.instance = this;
		}

		this.opts = options || defaultLoggerOptions

		return this.instance;
	}

	private invokeConsoleMethod = (handler: (...args: any) => void, messageArgs: any) => {
		Function.prototype.apply.call(handler, console, messageArgs);
	};

	private isEnabledFor = (level: LogLevel): boolean => {
		if (this.opts.off) {
			return false;
		}

		if (logLevels[this.opts.level] > logLevels[level]) {
			return false;
		}

		return true;
	}

	private invoke = (level: LogLevel, args: any) => {
		if (this.isEnabledFor(level)) {
			// eslint-disable-next-line no-console
			this.invokeConsoleMethod(console[level], args)
		}
	}

	getOptions = () => this.opts;

	updateOptions = (options: LoggerOptions) => {
		this.opts = options;
	};

	log = (...args: any) => this.invoke("info", args);

	debug = (...args: any) => this.invoke("debug", args);

	info = (...args: any) => this.invoke("info", args);

	warn = (...args: any) => this.invoke("warn", args);

	error = (...args: any) => this.invoke("error", args);
}


export default Logger;
