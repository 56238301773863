import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Typography, Select, Button, Spinner } from "@hegias/ui-components";
import { useForm, Controller } from "react-hook-form";
import { useFetch } from "use-http";

import HegiasErrorMessage from "components/ErrorMessage/ErrorMessage";
import { useOrganizations } from "contexts/Organizations";
import { renderInput } from "components/FormComponents";
import { useProjects } from "contexts/Projects";
import BCFOAuthForm from "./BCFOAuthForm";

interface BCFLoginrProps {
	projectMemberRole: string | undefined;
}

const BCFLogin = ({ projectMemberRole }: BCFLoginrProps) => {
	const [playgroundVersion] = useState<string>("2.1");
	const [bimSpace, setBimSpace] = useState<string | undefined>(undefined);
	const [bimBaseUrl, setBimBaseUrl] = useState<string | undefined>(undefined);
	const [userBimToken, setUserBimToken] = useState<string | undefined>(
		undefined,
	);
	const [visibiltyCheck, setVisibilityCheck] = useState<boolean>(false);
	const [BIMProjects, setBIMprojects] = useState<[] | undefined>(undefined);
	const [tokenExpired, setTokenExpired] = useState<boolean>(false);
	const [userConnected, setUserConnected] = useState<boolean>(false);
	const [currentBIMProject, setCurrentBIMProject] = useState<any>();
	const [errorFetchBIMProject, setErrorFetchBIMProject] = useState<boolean>(
		false,
	);
	const [isFetchingBIMProjects, setIsFetchingBIMProjects] = useState<boolean>(
		false,
	);

	const { t } = useTranslation();

	const { control, watch } = useForm<{
		bimSpace: string;
	}>({
		defaultValues: {
			bimSpace: "",
		},
	});
	const {
		current: currentProject,
		refreshCurrent: refreshCurrentProject,
	} = useProjects();
	const { member, fetchCurrentMember, current, currentOrganizationPlan } = useOrganizations();

	const { put: putProject } = useFetch();

	const {
		post: refreshBimcollabToken,
		response: refreshBimcollabTokenResponse,
	} = useFetch("/bimcollab-refresh");

	const {
		delete: deleteBimCollabTokenFromUser,
		response: deleteBimCollabTokenResponse,
	} = useFetch("/bimcollab-delete");

	const onBCFLoginSuccess = async () => {
		if (currentProject?._id && bimSpace) {
			const url = `https://${bimSpace}.bimcollab.com`;

			setBimBaseUrl(url);

			// Update project with URL only if owner/admin, otherwise is already there.
			if (
				projectMemberRole &&
				["owner", "admin"].includes(projectMemberRole)
			) {
				handlePutProject(url, undefined);
			} else {
				/* A fetch for non-owner/admin is needed so the token will be available.
				   It is also needed to trigger handleBIMTokenToUse. */

				fetchCurrentMember();
			}
		}
	};

	const handlePutProject = async (url?: string, guid?: string) => {
		const body = {
			meta: {},
			bimBaseUrl: url && url,
			bimProjectGuid: guid && guid,
		};

		await putProject(`/projects/${currentProject?._id}`, body);
		refreshCurrentProject();
		fetchCurrentMember();
	};

	const handleBIMTokenToUse = () => {
		if (member?.user?.bimCollabTokens?.length > 0) {
			const tokenToUse = member?.user.bimCollabTokens.filter(
				({ URLendpoint }: any) => {
					return URLendpoint === bimBaseUrl;
				},
			);

			if (tokenToUse.length > 0) {
				const token = tokenToUse[0].tokenData.access_token;
				setUserBimToken(token);
				setUserConnected(true);
			} else {
				// User has put some invalid BIM space, so into project there is a space that is not matching the token.
				handlePutProject("", "");
			}
		}
	};

	const fetchBIMProjects = () => {
		setIsFetchingBIMProjects(true);

		const url = bimBaseUrl;
		const token = userBimToken;

		if (url && token) {
			fetch(`${url}/bcf/${playgroundVersion}/projects/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
			})
				.then((res) => {
					if (res.status === 401) {
						setTokenExpired(true);
					}

					return res.json();
				})
				.then((data) => {
					setBIMprojects(data);
					setErrorFetchBIMProject(false);
				})
				.catch(() => {
					setErrorFetchBIMProject(true);
				})
				.finally(() => {
					setIsFetchingBIMProjects(false);
				});
		}
	};

	const refreshToken = async () => {
		await refreshBimcollabToken({
			currentURLendpoint: bimBaseUrl,
		});

		if (refreshBimcollabTokenResponse.ok) {
			fetchCurrentMember();
			setTokenExpired(false);
			fetchBIMProjects();
		}

		setTokenExpired(false);
	};

	const handleDeleteBimCollabTokenFromUser = async () => {
		await deleteBimCollabTokenFromUser({
			userId: member?.user._id,
			URLEndpoint: bimBaseUrl,
		});

		if (deleteBimCollabTokenResponse.ok && bimBaseUrl) {
			setTimeout(() => {
				setUserBimToken(undefined);
				setBimSpace(bimBaseUrl.replace(/.+\/\/|www.|\..+/g, ""));
			}, 300);
		}
	};

	useEffect(() => {
		if (bimBaseUrl && member) {
			handleBIMTokenToUse();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bimBaseUrl, member]);

	useEffect(() => {
		if (userBimToken) {
			fetchBIMProjects();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userBimToken]);

	useEffect(() => {
		if (tokenExpired) {
			refreshToken();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenExpired]);

	useEffect(() => {
		if (
			currentProject?.bimBaseUrl ||
			currentBIMProject?.bimBaseUrl !== ""
		) {
			setBimBaseUrl(currentProject?.bimBaseUrl);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentProject]);

	useEffect(() => {
		if (BIMProjects && BIMProjects.length > 0 && currentProject) {
			const currentBIMproject = BIMProjects?.find((prj: any) => {
				return prj.project_id === currentProject?.bimProjectGuid;
			});

			if (currentBIMproject) {
				setCurrentBIMProject(currentBIMproject);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BIMProjects, currentProject]);

	useEffect(() => {
		if (watch("bimSpace") || watch("bimSpace") === "") {
			// Set the endpoint of user, remove spaces in case user put some.
			setBimSpace(watch("bimSpace").trim());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch("bimSpace")]);

	useEffect(() => {
		if (
			(bimBaseUrl &&
				currentProject?.bimBaseUrl &&
				currentProject?.bimBaseUrl !== "" &&
				bimBaseUrl !== currentProject?.bimBaseUrl) ||
			(bimBaseUrl && currentProject?.bimBaseUrl === "")
		) {
			setUserConnected(false);
			setBIMprojects([]);
			setUserBimToken(undefined);

			setBimBaseUrl(currentProject?.bimBaseUrl);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bimBaseUrl, currentProject]);

	useEffect(() => {
		if (projectMemberRole) {
			setVisibilityCheck(
				(bimBaseUrl &&
					currentBIMProject &&
					!["owner", "admin"].includes(projectMemberRole)) ||
					["owner", "admin"].includes(projectMemberRole),
			);
		}

		if (
			projectMemberRole &&
			bimBaseUrl &&
			/* ["owner", "admin"].includes(projectMemberRole) && */
			!userBimToken
		) {
			setBimSpace(bimBaseUrl.replace(/.+\/\/|www.|\..+/g, ""));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectMemberRole, bimBaseUrl, currentBIMProject]);

	if (!projectMemberRole) {
		return <Spinner />;
	}

	return (
		true && (
			<StyledBCFLogin>
				{true && (
					<>
						<Typography
							className="BCFLoginTitle"
							color="primary"
							variant="h4"
						>
							{t("app.BCFLogin.title")}
						</Typography>
						<Typography color="grey800" className="info">
							{t("app.BCFLogin.intro")}
						</Typography>
						<StyledSelect>
							<Select
								options={[
									{ value: "BIMCollab", label: "BIM Collab" },
								]}
								fullWidth
								disabled
							/>
						</StyledSelect>
					</>
				)}
				<StyledURl>
					{true && (
						<Controller
							control={control}
							render={renderInput({
								startAdornment: (
									<StyledAdornment>{`${window.location.protocol}//`}</StyledAdornment>
								),
								endAdornment: (
									<StyledAdornment>
										.bimcollab.com
									</StyledAdornment>
								),
								inputProps: {
									disabled:
										userConnected ||
										(!userBimToken &&
											!["owner", "admin"].includes(
												projectMemberRole,
											)),
									placeholder:
										/* !userBimToken && */
										bimBaseUrl?.replace(
											/.+\/\/|www.|\..+/g,
											"",
										),
								},
							})}
							name="bimSpace"
							rules={{
								required: t(
									"app.form.error.requiredField",
								) as string,
							}}
						/>
					)}
					{/* {BIMProjects && (bimBaseUrl || currentProject?.bimBaseUrl)} */}
				</StyledURl>
				{!userConnected && (
					<BCFOAuthForm
						useBimCollab={current?.useBIMCollab ||
							currentOrganizationPlan?.subscriptionPlan?.BCF ||
							currentOrganizationPlan?.custom?.BCF}
						bimSpace={bimSpace}
						onBCFLoginSuccess={onBCFLoginSuccess}
						bimBaseUrl={currentProject?.bimBaseUrl}
						projectMemberRole={projectMemberRole}
					/>
				)}
				{isFetchingBIMProjects ? (
					<Spinner />
				) : (
					BIMProjects &&
					BIMProjects.length > 0 && (
						<StyledSelect>
							<Select
								disabled={
									!["owner", "admin"].includes(
										projectMemberRole,
									)
								}
								placeholder={
									currentBIMProject?.name ||
									`${t(
										"app.global.bcf.placeholder.selectProject",
									)}`
								}
								options={BIMProjects?.map((project: any) => {
									return {
										label: project.name,
										value: project.project_id,
									};
								})}
								fullWidth
								onChange={(e) => {
									const bimProjectGuid = e.target.value;
									handlePutProject(undefined, bimProjectGuid);
								}}
							/>
						</StyledSelect>
					)
				)}
				{errorFetchBIMProject && (
					<HegiasErrorMessage
						message={`${t("app.BCFLogin.fetchBIMProjects.error")}`}
					/>
				)}
				{userConnected && !isFetchingBIMProjects && (
					<StyledButton
						color="red"
						onClick={() => {
							setUserConnected(false);
							if (
								["admin", "owner"].includes(projectMemberRole)
							) {
								handlePutProject("", "");
								setBimBaseUrl(undefined);
							} else {
								handleDeleteBimCollabTokenFromUser();
							}
							setBIMprojects([]);
							setErrorFetchBIMProject(false);
						}}
					>
						{t("app.BCFLogin.disconnectButton")}
					</StyledButton>
				)}
			</StyledBCFLogin>
		)
	);
};

const StyledBCFLogin = styled.div`
	margin: 10px;
	padding: 10px;
	background: ${({ theme }) => theme.colors.white};

	.BCFLoginTitle {
		margin-bottom: 10px;
	}

	.info {
		margin-bottom: 30px;
	}
`;

const StyledSelect = styled.div`
	margin-top: 10px;
	color: red;
`;

const StyledURl = styled.div`
	margin-top: 10px;
`;

const StyledButton = styled(Button)`
	margin-top: 10px;
`;

const StyledAdornment = styled.div`
	padding: 0 5px;
	font-weight: ${({ theme }) => theme.typography.weight.bold};
	color: ${({ theme }) => theme.colors.grey900};
	background-color: ${({ theme }) => theme.colors.grey400};
`;

export default BCFLogin;
