export enum welcomePopupKinds {
	Register = "register",
	RegisterByInvite = "registerByInvite",
	OrganizationInvite = "organizationInvite",
	CreateOrganization = "createOrganization",
}

export type WelcomePopupTypes =
	| welcomePopupKinds.Register
	| welcomePopupKinds.RegisterByInvite
	| welcomePopupKinds.OrganizationInvite
	| welcomePopupKinds.CreateOrganization;

export enum welcomeActionKinds {
	InviteMember = "InviteMember",
	FirstUpload = "FirstUpload",
	CreateOrg = "CreateOrg",
	VROnBoarding = "VROnBoarding",
	/* LinkToSupportPage = "LinkToSupportPage", */
}

export type WelcomeActionTypes =
	| welcomeActionKinds.InviteMember
	| welcomeActionKinds.FirstUpload
	| welcomeActionKinds.CreateOrg
	| welcomeActionKinds.VROnBoarding;
/* | welcomeActionKinds.LinkToSupportPage; */
