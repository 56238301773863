import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";

export interface LinkProps extends React.ComponentProps<typeof Link> {
	underline?: boolean;
}

const StyledLink = styled(Link)<LinkProps>`
	color: ${({ theme }) => theme.colors.grey700};
	text-decoration: none;
	display: inline;
	line-height: 20px;
	border-bottom: ${({ underline, theme }) =>
		underline !== false ? `1px solid ${theme.colors.grey700}` : "none"};
	${({ underline }) =>
		underline !== false
			? `padding-bottom: 2px;`
			: `padding-bottom: inherit`};

	&:hover {
		color: ${({ theme }) => theme.colors.secondary};
		border-color: ${({ theme }) => theme.colors.secondary};
	}
`;

const HegiasLink = (props: LinkProps) => <StyledLink {...props} />;

export default HegiasLink;
