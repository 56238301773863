import { createContext } from "react";
import { OrganizationContext } from "./types";

const context = createContext<OrganizationContext>({
	current: undefined,
	organizations: [],
	loading: false,
	loaded: false,
	member: undefined,
	user: null,
	fetchOrganizations: () => {
		throw new Error("fetchOrganizations not implemented");
	},
	setCurrentOrganization: () => {
		throw new Error("setCurrentOrganization not implemented");
	},
	fetchCurrentUser: () => {
		throw new Error("fetchCurrentUser not implemented");
	},
	fetchCurrentMember: () => {
		throw new Error("fetchCurrentMember not implemented");
	},
	fetchCurrentOrg: () => {
		throw new Error("fetchCurrentOrg not implemented");
	},
	currentOrganizationPlan: null,
});

export default context;
