import React from "react";
import styled from "styled-components/macro";

export interface ButtonGroupProps extends React.HTMLAttributes<HTMLDivElement> {
	type?: "vertical" | "horizontal";
	align?: "right" | "left" | "center";
}

export const ButtonGroup = ({
	type = "horizontal",
	align = "left",
	children,
	...otherProps
}: ButtonGroupProps) => {
	return (
		<StyledButtonGroupContainer {...otherProps} type={type} align={align}>
			{children}
		</StyledButtonGroupContainer>
	);
};

const StyledButtonGroupContainer = styled.div<ButtonGroupProps>`
	display: flex;
	flex-flow: ${({ type }) => (type === "horizontal" ? "inherit" : "column")};
	justify-content: ${({ align }) => (align === "right" ? "flex-end" : align)};
	width: 100%;
	${({ type }) =>
		type === "vertical" &&
		`
		> * {
			&:not(:only-child) {
				margin: 5px 0;
			}
			&:first-of-type {
				margin-top: 0;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	`};
	${({ type }) =>
		type === "horizontal" &&
		`
		> * {
			&:not(:only-child) {
				margin: 0 5px;
				margin-bottom: 10px;
			}
			&:first-of-type {
				margin-left: 0;
			}
			&:last-of-type {
				margin-right: 0;
			}
		}
	`};
`;
