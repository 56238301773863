import React from "react";
import { Select } from "antd";
import styled from "styled-components/macro";
import { Icon } from "@hegias/ui-components";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useFetch } from "use-http";
import getDomain from "utils/getDomain";
import useOrganizations from "contexts/Organizations/useOrganizations";
import { StyledSwitcherContainer } from "common/styles/Switcher.styles";
import MenuItemStyles from "components/MenuItem/MenuItem.styles";
import MenuItem from "components/MenuItem/MenuItem";
import useTenant from "contexts/Tenant/useTenant";
import { Organization } from "contexts/Organizations/types";
import { CREATE_ORGANIZATION, PROJECTS } from "common/routes.constants";
import { WELCOME_MODAL_CASE } from "common/constants/cookies";
import { welcomePopupKinds } from "components/WelcomeModal/types";

const { Option } = Select;

const OrganizationSwitcher = () => {
	const {
		current,
		organizations,
		loading,
		setCurrentOrganization,
		member,
	} = useOrganizations();
	const { subdomainsSupported } = useTenant();

	const { t } = useTranslation();
	const history = useHistory();
	const { search } = useLocation();

	const [, setCookie] = useCookies([WELCOME_MODAL_CASE]);

	const { put } = useFetch("users/me");

	const handleAddOrganizationClick = () => {
		history.push({
			pathname: `/${CREATE_ORGANIZATION}`,
			search,
		});
	};

	const handleChangeOrganizationClick = async (id: string) => {
		setCurrentOrganization(id);

		/* User is logging for the first time into
		   an organization that is not welcome org. */
		if (
			id !== process.env.REACT_APP_DEFAULT_ORG &&
			member?.user?.isFirstOrganizationLogin
		) {
			setCookie(
				WELCOME_MODAL_CASE,
				welcomePopupKinds.OrganizationInvite,
				{
					path: "/",
					domain: `.${getDomain()}`,
				},
			);

			/* Once that user see the welcome popup,
			   the flag of first login organizaton can be set to false. */
			await put({
				profile: { email: member?.user?.profile?.email },
				isFirstOrganizationLogin: false,
			});
		}

		if (subdomainsSupported) {
			const nextOrganization = organizations.find(
				(o) => o._id === id,
			) as Organization;

			const domain = getDomain();
			const nextLocation = `${window.location.protocol}//${nextOrganization.meta.subdomain}.${domain}`;

			logger.info(
				"[OrganizationSwitcher] handleChangeOrganizationClick",
				{
					nextOrganization,
					nextLocation,
				},
			);

			window.location.assign(nextLocation);
			return false;
		}

		history.push({
			pathname: `/${PROJECTS}`,
			search,
		});
	};

	return (
		<StyledSwitcherContainer>
			<MenuItemStyles />
			<span className="label">
				{t("app.OrganizationSwitcher.label.switcher")}
			</span>
			<Select
				value={current?._id}
				style={{ width: "100%" }}
				onChange={handleChangeOrganizationClick}
				loading={loading}
				bordered={false}
				dropdownClassName="header-select-dropdown"
				suffixIcon={<Icon name="ArrowDownFilledIcon" />}
				className="header-select"
				dropdownRender={(menu) => (
					<>
						{menu}
						<StyledAddNewButton>
							<button
								type="button"
								onClick={handleAddOrganizationClick}
							>
								<span>
									<Icon name="PlusIcon" size="15px" />
								</span>
								{t(`app.global.btn.addItem`)}
							</button>
						</StyledAddNewButton>
					</>
				)}
			>
				{organizations?.map((org) => (
					<Option key={org._id} value={org._id}>
						<MenuItem
							className="switcher-item"
							title={org.meta.shortname || org.meta.name}
							thumbnail={`${org.shortid}/${org.meta.avatar}`}
						/>
					</Option>
				))}
			</Select>
		</StyledSwitcherContainer>
	);
};

const StyledAddNewButton = styled.div`
	padding-top: 12px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	border-top: 1px solid ${({ theme }) => theme.colors.grey300};
	> button {
		background-color: transparent;
		border: none;
		display: inline-flex;
		align-items: center;
		outline: none;
		&:hover {
			cursor: pointer;
			color: ${({ theme }) => theme.colors.primary};
		}
		span {
			align-items: center;
			justify-content: center;
			pointer-events: auto;
			border: none;
			overflow: hidden;
			outline: none;
			color: ${({ theme }) => theme.colors.grey800};
			background-color: ${({ theme }) => theme.colors.grey400};
			display: inline-flex;
			padding: 5px;
			border-radius: 50%;
			margin-right: 5px;
		}
	}
`;

export default OrganizationSwitcher;
