import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components/macro";
import useFetch from "use-http";

import { renderInlineEditor } from "components/FormComponents";
import AvatarUpload, {
	AvatarOnSubmit,
} from "components/AvatarUpload/AvatarUpload";
import { useScenes } from "contexts/Scenes";
import { useProjects } from "contexts/Projects";

const AsideProject = () => {
	const { t } = useTranslation();
	const { current, refreshCurrent, updateCurrent } = useProjects();
	const [updatedThumbnailPath, setUpdatedThumbnailPath] = useState<
		string | null
	>(null);

	const thumbnailSrc = useMemo(() => {
		switch (true) {
			case Boolean(updatedThumbnailPath):
				return updatedThumbnailPath;

			case Boolean(current?.meta.thumbnail):
				return `${process.env.REACT_APP_AWS_S3_URL}/projects/${current?.shortid}/${current?.meta.thumbnail}`;

			default:
				return "/images/placeholderCardImage.png";
		}
	}, [
		current?.meta.thumbnail,
		current?.shortid,
		updatedThumbnailPath,
	]) as string;

	const { control, handleSubmit, reset } = useForm({
		defaultValues: {
			name: current?.meta?.name,
			description: current?.meta?.description,
		},
	});

	const { pagedResponse } = useScenes();

	const { post, response } = useFetch((globalOptions: any) => {
		const newGlobalOptions = { ...globalOptions };
		delete newGlobalOptions.headers["Content-Type"];
		return newGlobalOptions;
	});

	const updateProjectThumbnail: AvatarOnSubmit = async (
		file,
		onSuccess,
		onError,
	) => {
		const data = new FormData();
		data.append("thumbnail", file);
		await post(`projects/${current?._id}/thumbnail`, data);

		if (response.ok) {
			await refreshCurrent();
			setUpdatedThumbnailPath(
				`${process.env.REACT_APP_AWS_S3_URL}/projects/${current?.shortid}/${response.data.filename}`,
			);
			onSuccess();
		} else {
			onError();
		}
	};

	const updateProjectMeta = async ({
		name,
		description,
	}: {
		name: string;
		description: string;
	}) => {
		await updateCurrent({
			meta: {
				name,
				description,
			},
		});
	};

	useEffect(() => {
		reset({
			name: current?.meta?.name,
			description: current?.meta?.description,
		});
		setUpdatedThumbnailPath(null);
	}, [current, reset]);

	if (!current) {
		return null;
	}

	return (
		<StyledAside
			style={{
				height: "100%",
				paddingTop: "50px",
				overflow: "hidden",
			}}
		>
			<div className="info-container">
				<AvatarUpload
					size="100%"
					onSubmit={updateProjectThumbnail}
					currentImageURL={thumbnailSrc}
				/>
				<form onBlur={handleSubmit(updateProjectMeta)}>
					<Controller
						control={control}
						render={renderInlineEditor({
							fontSize: 24,
							color: "primary",
							placeholder: t("app.Project.leftContent.newTitle"),
						})}
						rules={{
							required: t(
								"app.form.error.requiredField",
							) as string,
						}}
						name="name"
					/>
					<Controller
						control={control}
						render={renderInlineEditor({
							placeholder: t(
								"app.Project.leftContent.newDescription",
							),
						})}
						rules={{
							required: t(
								"app.form.error.requiredField",
							) as string,
						}}
						name="description"
					/>
				</form>
				{pagedResponse && (
					<p style={{ paddingLeft: 5 }}>
						{t("app.Project.leftContent.availableScenes")}:{" "}
						{pagedResponse.totalResults}
					</p>
				)}
			</div>
		</StyledAside>
	);
};

const StyledAside = styled.aside`
	/* height: 100%; */
	.info-container {
		margin: 10px;
		padding: 10px;
		background: ${(props) => props.theme.colors.white};
	}
`;

export default AsideProject;
