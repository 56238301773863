/* eslint-disable react/destructuring-assignment */
import {
	Button,
	Checkbox,
	Input,
	Modal,
	Table,
	Tabs,
	Tooltip,
	Typography,
	message,
	Tag,
	Radio,
} from "antd";
import {
	SettingOutlined,
	BugOutlined,
	CodeOutlined,
	CopyOutlined,
	GlobalOutlined,
	InfoCircleOutlined,
	BranchesOutlined,
	CodeSandboxOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import GitInfo from "react-git-info/macro";
import Link from "antd/lib/typography/Link";
import { defaultLoggerOptions, LogLevel } from "utils/Logger";
import { highlightComponents, clearHighlights } from "../componentHighlighter";

const { TabPane } = Tabs;
const { Text } = Typography;
const gitInfo = GitInfo();

const envVariablesData = [
	...Object.keys(process.env).map((e) => ({
		name: e,
		value: process.env[e],
	})),
];

const logLevelOptions: { label: string; value: LogLevel }[] = [
	{ label: "Debug", value: "debug" },
	{ label: "Info", value: "info" },
	{ label: "Warn", value: "warn" },
	{ label: "Error", value: "error" },
];

const DevToolsDialog = () => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [highlightUIComponents, setHighlightUIComponents] = useState(false);
	const [loggingDisabled, setLoggingDisabled] = useState(
		defaultLoggerOptions.off,
	);
	const [logLevel, setLogLevel] = useState(defaultLoggerOptions.level);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const envVariablesColumns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (text: any) => (
				<>
					<Text code>{text}</Text>
					{!text.includes("REACT_APP_") && (
						<Tag color="volcano">SYSTEM</Tag>
					)}
				</>
			),
		},
		{
			title: "Value",
			dataIndex: "value",
			key: "value",
			render: (text: any = "") => (
				<div style={{ display: "flex" }}>
					<Input
						defaultValue={text}
						readOnly
						style={{ marginRight: 5 }}
					/>
					<Tooltip title="Copy value">
						<Button
							icon={
								<CopyOutlined
									style={{ color: "rgba(0,0,0,.45)" }}
								/>
							}
							onClick={() => {
								navigator.clipboard.writeText(text);
								message.info("Copied to clipboard");
							}}
						/>
					</Tooltip>
				</div>
			),
		},
	];

	useEffect(() => {
		logger.updateOptions({
			level: logLevel,
			off: loggingDisabled,
		});
	}, [loggingDisabled, logLevel]);

	useEffect(() => {
		if (highlightUIComponents) {
			highlightComponents();
		} else {
			clearHighlights();
		}
	}, [highlightUIComponents]);

	return (
		<>
			<Button
				type="primary"
				shape="circle"
				icon={<SettingOutlined />}
				size="large"
				onClick={showModal}
				style={{
					position: "fixed",
					bottom: 20,
					right: 20,
					zIndex: 20,
				}}
			/>
			<Modal
				title={
					<span>
						<SettingOutlined /> HEGIAS Dev Tools
					</span>
				}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleOk}
				okText="Close"
				width={800}
			>
				<Tabs defaultActiveKey="0">
					<TabPane
						tab={
							<span>
								<InfoCircleOutlined />
								Info
							</span>
						}
						key="0"
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 10,
								marginBottom: 20,
							}}
						>
							<Typography.Text type="secondary">
								APP
							</Typography.Text>
							<Typography.Text code>
								{process.env.REACT_APP_VERSION}
							</Typography.Text>
							<p>
								<BranchesOutlined />
								<Typography.Text code>
									{gitInfo.branch}
								</Typography.Text>
								<Typography.Text type="secondary">
									{gitInfo.commit.message}
								</Typography.Text>{" "}
								<Link
									href={`https://gitlab.com/hegias/hegias-cms/-/commit/${gitInfo.commit.hash}`}
									target="_blank"
								>
									{gitInfo.commit.shortHash}
								</Link>
							</p>

							<p>
								<CodeSandboxOutlined />{" "}
								<Typography.Text type="secondary">
									@hegias/ui-components
								</Typography.Text>{" "}
								<Typography.Text code>
									{String(
										process.env.REACT_APP_HEGIAS_UI_VERSION,
									)}
								</Typography.Text>
							</p>

							<p>
								<CodeSandboxOutlined />{" "}
								<Typography.Text type="secondary">
									@hegias/3D
								</Typography.Text>{" "}
								<Typography.Text code>
									{String(
										process.env.REACT_APP_HEGIAS_3D_VERSION,
									)}
								</Typography.Text>
							</p>
						</div>
					</TabPane>
					<TabPane
						tab={
							<span>
								<BugOutlined />
								Debug
							</span>
						}
						key="1"
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 10,
								marginBottom: 20,
							}}
						>
							<Typography.Text type="secondary">
								LOGGING
							</Typography.Text>
							<div>
								<Checkbox
									checked={loggingDisabled}
									onChange={() =>
										setLoggingDisabled(!loggingDisabled)
									}
								>
									Logging disabled
								</Checkbox>
							</div>
							<Radio.Group
								options={logLevelOptions}
								onChange={(event) =>
									setLogLevel(event.target.value)
								}
								value={loggingDisabled ? "" : logLevel}
								optionType="button"
								disabled={loggingDisabled}
							/>
						</div>

						<Typography.Text type="secondary">
							OPTIONS
						</Typography.Text>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								gap: 10,
								marginTop: 10,
							}}
						>
							<div>
								<Checkbox
									checked={highlightUIComponents}
									onChange={() =>
										setHighlightUIComponents(
											!highlightUIComponents,
										)
									}
								>
									Highlight UI Components
								</Checkbox>
							</div>
						</div>
					</TabPane>
					<TabPane
						tab={
							<span>
								<CodeOutlined />
								Environment variables
							</span>
						}
						key="2"
					>
						<Table
							size="small"
							columns={envVariablesColumns}
							dataSource={envVariablesData}
							pagination={{
								pageSize: 100,
								hideOnSinglePage: true,
							}}
							scroll={{
								y: 400,
							}}
						/>
					</TabPane>
					<TabPane
						tab={
							<span>
								<GlobalOutlined />
								Localization
							</span>
						}
						key="3"
						disabled
					>
						<div>Localization options</div>
					</TabPane>
				</Tabs>
			</Modal>
		</>
	);
};

export default DevToolsDialog;
