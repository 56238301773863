import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Typography } from "@hegias/ui-components";

const renderFunction = ({ message }: { message: string }) => (
	<Typography color="red" style={{ textAlign: "left" }}>
		{message}
	</Typography>
);

type FormErrorMessageProps = React.ComponentProps<typeof ErrorMessage>;

const FormErrorMessage = (props: FormErrorMessageProps) => (
	<ErrorMessage render={renderFunction} {...props} />
);

export default FormErrorMessage;
