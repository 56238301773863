import React from "react";
import { Menu, Dropdown } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { DropdownMenuButton } from "@hegias/ui-components";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Link } from "components";

import * as ROUTES from "common/routes.constants";
import useAuth from "contexts/Auth/useAuth";
import useOrganizations from "contexts/Organizations/useOrganizations";
import useCan from "hooks/useCan";

const UserMenu = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { search } = useLocation();
	const { logout } = useAuth();
	const { member, user } = useOrganizations();

	const adminMenuVisible = useCan(["admin", "owner"], { anyOf: true });

	const handleLogout = async () => {
		await logout();
		history.push({
			pathname: `/${ROUTES.LOGIN}`,
			search,
		});
	};

	const buildAbbrName = () => {
		const abbrName = user?.profile.name ? user?.profile.name.charAt(0) : "";
		const abbrLastname = user?.profile.lastName
			? user?.profile.lastName.charAt(0)
			: "";
		return `${abbrName}${abbrLastname}`;
	};

	const avatarProps = user?.profile?.avatar?.length
		? {
				src: `${process.env.REACT_APP_AWS_S3_URL}/users/${user?.shortid}/${user?.profile.avatar}`,
		  }
		: { text: buildAbbrName() };

	const menu = (
		<StyledMenu>
			<Menu.Item key={ROUTES.LIBRARY} className="mobileMenu">
				<Link
					to={{
						pathname: `/${ROUTES.LIBRARY}`,
						search,
					}}
				>
					{t("app.MainNavigation.menu.library")}
				</Link>
			</Menu.Item>
			<Menu.Divider className="mobileMenu" />
			{adminMenuVisible && (
				<Menu.Item key={ROUTES.ADMIN}>
					<Link
						to={{
							pathname: `/${ROUTES.ADMIN}`,
						}}
					>
						{t("app.AvatarNavigation.menu.admin")}
					</Link>
				</Menu.Item>
			)}
			<Menu.Item key={ROUTES.ACCOUNT}>
				<Link
					to={{
						pathname: `/${ROUTES.ACCOUNT}`,
					}}
				>
					{t("app.AvatarNavigation.menu.account")}
				</Link>
			</Menu.Item>
			{/* <Menu.Item key={ROUTES.TRASH}>
				<Link
					to={{
						pathname: `/${ROUTES.TRASH}`,
					}}
				>
					{t("app.AvatarNavigation.menu.trash")}
				</Link>
				</Menu.Item> */}
			<Menu.Divider />
			<Menu.Item key={ROUTES.TRASH} onClick={handleLogout}>
				<a href="/">{t("app.AvatarNavigation.menu.logout")}</a>
			</Menu.Item>
		</StyledMenu>
	);

	const menuTrigger = isMobile ? "click" : "hover";

	return (
		<StyledDropdown overlay={menu} trigger={[menuTrigger]}>
			<span onClick={(e) => e.preventDefault()}>
				<DropdownMenuButton
					maxWidth="100px"
					name={user?.profile.name || ""}
					title={member?.role || ""}
					avatarProps={avatarProps}
				/>
			</span>
		</StyledDropdown>
	);
};

const StyledDropdown = styled(Dropdown)`
	cursor: pointer;
	> div {
		align-items: center;
		overflow: hidden;

		> div:first-child {
			min-width: 33px;
			height: 33px;
			width: 33px;
			min-height: 33px;
		}
		> div:last-child {
			margin-right: 5px;
			> * {
				line-height: 16px;
			}
			h4 {
				font-size: 14px;
			}
		}
	}

	@media (max-width: 415px) {
		> div > div:last-child {
			display: none;
		}
	}
`;
const StyledMenu = styled(Menu)`
	padding: 10px !important;

	li:not(.ant-dropdown-menu-item-divider) {
		padding: 5px 12px;
		&:hover {
			background-color: ${({ theme }) => theme.colors.grey100};
		}
	}
	a {
		color: ${({ theme }) => theme.colors.grey700};
		&:hover {
			color: ${({ theme }) => theme.colors.primary};
			border-color: transparent;
		}
		border-color: transparent;
	}
	.mobileMenu {
		display: none;
	}

	@media (max-width: 999px) {
		.mobileMenu {
			display: flex;
		}
	}
`;

export default UserMenu;
