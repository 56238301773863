import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import OauthPopup from "react-oauth-popup";
import { useCookies } from "react-cookie";
import { IconButton, Icon } from "@hegias/ui-components";

import { LOCALHOST_ENVIRONMENT } from "common/environment";
import getDomain from "utils/getDomain";
import { useOrganizations } from "contexts/Organizations";

interface BCFOAuthFormProps {
	bimSpace: string | undefined;
	onBCFLoginSuccess: () => void;
	bimBaseUrl: string | undefined;
	projectMemberRole: string | undefined;
	useBimCollab: boolean | undefined;
}

const BCFOAuthForm = ({
	bimSpace,
	onBCFLoginSuccess,
	bimBaseUrl,
	projectMemberRole,
	useBimCollab,
}: BCFOAuthFormProps) => {
	const BIM_CLIENT_ID = "491213fb-f734-4c4d-9b13-bad14dfbf4c1";
	const BIM_CLIENT_SECRET = "286b01b1-95e8-4368-a979-ef77f6d37d06";
	const BIM_REDIRECT_URI = LOCALHOST_ENVIRONMENT
		? "http://localhost:5000/Callback"
		: `https://app.${getDomain()}/api/v3/auth/callback`;

	const [URLendpoint, setURLendpooint] = useState<string | undefined>(
		undefined,
	);

	const { t } = useTranslation();
	const { member } = useOrganizations();

	const [, setCookie] = useCookies(["currentBIMendpoint, userId"]);

	const onCode = async (res: any) => {
		if (res !== "undefined") {
			onBCFLoginSuccess();
		}
	};

	const onClose = () => {
		if (
			!bimBaseUrl ||
			bimBaseUrl === "" ||
			// Need to trigger also when user non-admin/owner is trying to connect.
			(bimBaseUrl &&
				projectMemberRole &&
				!["owner", "admin"].includes(projectMemberRole))
		) {
			onBCFLoginSuccess();
		}
	};

	useEffect(() => {
		if (bimSpace) {
			setURLendpooint(`https://${bimSpace}.bimcollab.com`);
		}
	}, [bimSpace]);

	return (
		<div>
			<StyledOuterDiv disabled={bimSpace === "" || !useBimCollab}>
				<OauthPopup
					// eslint-disable-next-line react/jsx-curly-brace-presence
					url={`${URLendpoint}/identity/connect/authorize?client_id=${BIM_CLIENT_ID}&redirect_uri=${BIM_REDIRECT_URI}&response_type=code&client_secret=${BIM_CLIENT_SECRET}&scope=openid offline_access bcf`}
					onCode={onCode}
					onClose={onClose}
					title="ok"
					width={800}
					height={600}
				>
					<StyledCustomButton
						disabled={bimSpace === "" || !useBimCollab}
						onClick={() => {
							setCookie("currentBIMendpoint", URLendpoint, {
								path: "/",
								domain: `${getDomain()}`,
							});
							setCookie("userId", member?.user._id, {
								path: "/",
								domain: `${getDomain()}`,
							});
						}}
					>
						{t("app.BCFLogin.connectButton")}
					</StyledCustomButton>
				</OauthPopup>
			</StyledOuterDiv>
			{!useBimCollab &&
				projectMemberRole &&
				!["guest"].includes(projectMemberRole) && (
					<StyledHegiasSalesPageLink>
						<Trans i18nKey="app.BCFLogin.BIMfeatureNotSet">
							<a
								href="https://hegias.com/sales/"
								target="_blank"
								rel="noreferrer"
								className="link"
							>
								{{
									HegiasSalesPage: t(
										"app.BCFLogin.HegiasSalesPageLink",
									),
								}}
							</a>
						</Trans>
					</StyledHegiasSalesPageLink>
				)}
			{!bimSpace &&
				useBimCollab &&
				projectMemberRole &&
				!["admin", "owner", "guest"].includes(projectMemberRole) && (
					<StyledspaceNotSet>
						<Icon
							name="WarningIcon"
							color="secondary"
							size="20px"
						/>
						<span className="text">
							{t("app.BCFLogin.spaceNotSet")}
						</span>
					</StyledspaceNotSet>
				)}
		</div>
	);
};

/* Custom Button component Oauth is needed. */
const StyledCustomButton = styled.div<{ disabled?: boolean }>`
	background: ${({ theme, disabled }) =>
		disabled ? theme.colors.grey700 : theme.colors.secondary};
	width: 150px;
	margin-top: 10px;
	color: white;
	border-radius: 4px;
	text-align: center;
	height: 40px;
	line-height: 40px;
`;

const StyledHegiasSalesPageLink = styled.div`
	margin-top: 10px;

	.link {
		color: ${({ theme }) => theme.colors.primary};
	}
`;

const StyledspaceNotSet = styled.div`
	display: grid;
	grid-gap: 5px;
	grid-template-columns: 0.1fr 1fr;
	margin-top: 10px;
`;

const StyledOuterDiv = styled.div<{ disabled?: boolean }>`
	pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};

	&:hover {
		cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	}
`;

export default BCFOAuthForm;
