import { notification } from "antd";
import React, { useCallback, useMemo } from "react";
import { Icon } from "@hegias/ui-components";
import styled, { createGlobalStyle } from "styled-components/macro";
import { ToasterContext } from "./ToasterContext";
import { ToasterProps } from "./types";

const { useNotification } = notification;

interface StyledIconProps extends React.HTMLAttributes<HTMLDivElement> {
	status: string;
}

export const StyledTypeIcon = styled.div<StyledIconProps>`
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: grid;
	justify-items: center;
	align-items: center;
	margin-left: -6px;
	margin-top: 2px;
	background-color: ${({ theme, status }) => {
		switch (status) {
			case "success":
				return theme.colors.primary;
			case "error":
				return theme.colors.red;
			case "warning":
				return theme.colors.yellow;
			default:
				return theme.colors.blue;
		}
	}};
`;

const ToasterStyles = createGlobalStyle`
	.ant-notification{
		.ant-notification-notice-description{
			color: ${({ theme }) => theme.colors.grey800};
		}
		.ant-notification-notice-message{
			font-weight: ${({ theme }) => theme.typography.weight.bold};
			color: ${({ theme }) => theme.colors.grey800};
		}
		a {
			border: none;
    		max-width: 15px;
			border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey700}`};
			padding-bottom: 2px;
			svg{
				rect, polygon, path, circle, ellipse, ellipse{
					fill: ${({ theme }) => theme.colors.grey800};
				}
			}
		}
		.ant-notification-notice-close{
			border-bottom: none;
			padding-bottom: 2px;
		}
	}
`;

const ToasterProvider: React.FunctionComponent = ({ children }) => {
	const renderTypeIcon = (type: string) => {
		let typeIcon = null;
		switch (type) {
			case "success":
				typeIcon = (
					<Icon
						name="CorrectIcon"
						color="white"
						hoverColor="white"
						size="15px"
					/>
				);
				break;
			case "warning":
				typeIcon = (
					<Icon
						name="ExclamationIcon"
						color="white"
						hoverColor="white"
						size="15px"
					/>
				);
				break;
			case "error":
				typeIcon = (
					<Icon
						name="CloseIcon"
						color="white"
						hoverColor="white"
						size="15px"
					/>
				);
				break;
			case "info":
			default:
				typeIcon = (
					<Icon
						name="QuestionIcon"
						color="white"
						hoverColor="white"
						size="15px"
					/>
				);
				break;
		}
		return <StyledTypeIcon status={type}>{typeIcon}</StyledTypeIcon>;
	};

	const [api, contextHolder] = useNotification();

	// Toaster used with different configuration
	const toaster = useCallback(
		({ type = "info", duration = 5, ...otherProps }: ToasterProps) => {
			if (type) {
				api[type]({
					...otherProps,
					duration,
					closeIcon: (
						<Icon
							name="CloseIcon"
							color="grey300"
							hoverColor="primary"
						/>
					),
					icon: renderTypeIcon(type),
				});
			}
		},
		[api],
	);

	// Info icon on toaster
	const info = useCallback(
		({ duration = 5, ...otherProps }: ToasterProps) => {
			api.info({
				duration,
				...otherProps,

				// key: `info${message}${description}`,
				closeIcon: (
					<Icon
						name="CloseIcon"
						color="grey300"
						hoverColor="primary"
					/>
				),
				icon: renderTypeIcon("info"),
			});
		},
		[api],
	);

	// Success icon on toaster
	const success = useCallback(
		({ duration = 5, ...otherProps }: ToasterProps) => {
			api.success({
				...otherProps,

				duration,
				// key: `"success"${message}${description}`,
				closeIcon: (
					<Icon
						name="CloseIcon"
						color="grey300"
						hoverColor="primary"
					/>
				),
				icon: renderTypeIcon("success"),
			});
		},
		[api],
	);

	// Warning icon of toaster
	const warning = useCallback(
		({
			message,
			description,
			duration = 5,
			...otherProps
		}: ToasterProps) => {
			api.warning({
				...otherProps,
				message,
				description,
				duration,
				// key: `warning${message}${description}`,
				closeIcon: (
					<Icon
						name="CloseIcon"
						color="grey300"
						hoverColor="primary"
					/>
				),
				icon: renderTypeIcon("warning"),
			});
		},
		[api],
	);

	// Error icon of toaster
	const error = useCallback(
		({ duration = 5, ...otherProps }: ToasterProps) => {
			api.error({
				...otherProps,
				duration,
				// key: `error${message}${description}`,
				closeIcon: (
					<Icon
						name="CloseIcon"
						color="grey300"
						hoverColor="primary"
					/>
				),
				icon: renderTypeIcon("error"),
			});
		},
		[api],
	);

	const providerValue = useMemo(
		() => ({ error, warning, info, toaster, success }),
		[error, warning, info, toaster, success],
	);

	return (
		<ToasterContext.Provider value={providerValue}>
			<ToasterStyles />
			{contextHolder}
			{children}
		</ToasterContext.Provider>
	);
};

export default ToasterProvider;
