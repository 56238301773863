import React from "react";
import { Slider } from "antd";
import styled from "styled-components/macro";

type SliderProps = React.ComponentProps<typeof Slider>;

const HegiasSlider = (props: SliderProps) => {
	return (
		<StyledSlider {...props} />
	);
};

const StyledSlider = styled(Slider)`
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	.ant-slider-track {
		background-color: ${({ theme }) => theme.colors.primary};
	}
	.ant-slider-handle {
		border: solid 2px ${({ theme }) => theme.colors.primary};
		&:focus{
			box-shadow: 0 0 0 5px rgb(145, 164, 54, 12%);
		}
	}
	&:hover {
		.ant-slider-track {
			background-color: ${({ theme }) => theme.colors.primary};
		}
		.ant-slider-handle:not(.ant-tooltip-open) {
			border-color:  ${({ theme }) => theme.colors.primary};
		}
		.ant-slider-track,
		.ant-slider-handle:not(.ant-tooltip-open) {
			filter: brightness(120%);
		}
	}
`;

export default HegiasSlider;
