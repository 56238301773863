import React, { lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import LibraryProvider from "contexts/Library";
import * as ROUTES from "./common/routes.constants";
import useAuth from "./contexts/Auth/useAuth";
import { Projects as ProjectsView } from "./views";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

// Lazy loaded views
const LibraryView = lazy(() => import("./views/Library"));
const AdminView = lazy(() => import("./views/Admin"));
const TrashView = lazy(() => import("./views/Trash"));
const AccountView = lazy(() => import("./views/Account"));
const LoginView = lazy(() => import("./views/Login"));
const ForgotPasswordView = lazy(() => import("./views/ForgotPassword"));
const ErrorPageView = lazy(() => import("./views/Error"));
const RegisterView = lazy(() => import("./views/Register"));
const RegisterSuccessView = lazy(() => import("./views/RegisterSuccess"));
const VerifyView = lazy(() => import("./views/Verify"));
const SetNewPasswordView = lazy(() => import("./views/SetNewPassword"));
const SharedSceneView = lazy(() => import("./views/SharedScene"));
const SharedSceneViewPStreaming = lazy(() => import("./views/SharedScene/SharedScenePStreaming"));
const SharedObjectView = lazy(() => import("./views/SharedObject"));
const WelcomePopup = lazy(() => import("./views/WelcomePopup"));
const ExpiredSubscriptionView = lazy(
	() => import("./views/ExpiredSubscription"),
);

const CreditCardView = lazy(() => import("./views/CreditCard"));
const OauthCallbackView = lazy(() => import("./views/OauthCallback"));
const CreateOrganizationView = lazy(() => import("./views/CreateOrganization"));
const CreateOrganizationSuccessView = lazy(
	() => import("./views/CreateOrganizationSuccess"),
);

const Routes = () => {
	const { authenticated } = useAuth();

	return (
		<Switch>
			<Redirect
				exact
				from="/"
				to={authenticated ? `/${ROUTES.PROJECTS}` : `/${ROUTES.LOGIN}`}
			/>
			<ProtectedRoute
				key={ROUTES.PROJECTS}
				path={`/${ROUTES.PROJECTS}`}
				component={ProjectsView}
			/>
			<ProtectedRoute
				key={ROUTES.LIBRARY}
				path={[
					`/${ROUTES.LIBRARY}/:kind/:shortid`,
					`/${ROUTES.LIBRARY}`,
				]}
			>
				<LibraryProvider>
					<LibraryView />
				</LibraryProvider>
			</ProtectedRoute>
			<ProtectedRoute
				key={ROUTES.ACCOUNT}
				path={`/${ROUTES.ACCOUNT}`}
				component={AccountView}
			/>
			<ProtectedRoute
				key={ROUTES.ADMIN}
				path={`/${ROUTES.ADMIN}`}
				component={AdminView}
			/>
			<ProtectedRoute
				key={ROUTES.TRASH}
				path={`/${ROUTES.TRASH}`}
				component={TrashView}
			/>
			<ProtectedRoute
				key={ROUTES.EXPIRED_SUBSCRIPTION}
				path={`/${ROUTES.EXPIRED_SUBSCRIPTION}`}
				component={ExpiredSubscriptionView}
				exact
			/>
			<ProtectedRoute
				key={ROUTES.WELCOMEPOPUP}
				path={`/${ROUTES.WELCOMEPOPUP}`}
				component={WelcomePopup}
			/>
			<ProtectedRoute
				key={ROUTES.CREATE_ORGANIZATION}
				path={`/${ROUTES.CREATE_ORGANIZATION}`}
				component={CreateOrganizationView}
			/>
			<Route
				key={ROUTES.LOGIN}
				path={`/${ROUTES.LOGIN}`}
				component={LoginView}
				exact
			/>
			<Route
				key={ROUTES.FORGOT_PASSWORD}
				path={`/${ROUTES.FORGOT_PASSWORD}`}
				component={ForgotPasswordView}
				exact
			/>
			<Route
				key={ROUTES.VERIFY}
				path={`/${ROUTES.VERIFY}/:token`}
				component={VerifyView}
				exact
			/>
			<Route
				key={ROUTES.REGISTER}
				path={[`/${ROUTES.REGISTER}`, `/${ROUTES.INVITE}/:token`]}
				component={RegisterView}
				exact
			/>
			<Route
				key={ROUTES.REGISTER_SUCCESS}
				path={`/${ROUTES.REGISTER_SUCCESS}/:kind`}
				component={RegisterSuccessView}
				exact
			/>
			<Route
				// TODO deprecate this route
				key={`${ROUTES.SCENES}/view/:token`}
				path={`/${ROUTES.SCENES}/view/:token`}
				component={SharedSceneViewPStreaming}
				exact
			/>
			<Route
				key={ROUTES.VERIFY}
				path={`/${ROUTES.VERIFY}/:token`}
				component={VerifyView}
				exact
			/>
			<Route
				key={ROUTES.SET_NEW_PASSWORD}
				path={`/${ROUTES.SET_NEW_PASSWORD}/:token`}
				component={SetNewPasswordView}
				exact
			/>
			<Route
				key={`${ROUTES.SCENES}/:token`}
				path={`/${ROUTES.SCENES}/:token`}
				component={({ match }: any) => {
					const { token } = match.params;
					return token.endsWith('N') ? <SharedSceneViewPStreaming /> : <SharedSceneView />;
				  }}
				exact
			/>
			<Route
				// TODO deprecate also this
				key={`${ROUTES.SCENES}/n/:token`}
				path={`/${ROUTES.SCENES}/n/:token`}
				component={SharedSceneViewPStreaming}
				exact
			/>
			<Route
				key={`${ROUTES.OBJECTS}/:token`}
				path={`/${ROUTES.OBJECTS}/:token`}
				component={SharedObjectView}
				exact
			/>
			<Route
				key={ROUTES.CREDIT_CARD}
				path={`/${ROUTES.CREDIT_CARD}`}
				component={CreditCardView}
				exact
			/>
			<Route
				key={ROUTES.CREATE_ORGANIZATION_SUCCESS}
				path={`/${ROUTES.CREATE_ORGANIZATION_SUCCESS}`}
				component={CreateOrganizationSuccessView}
				exact
			/>
			<Route
				key={ROUTES.ERROR}
				path={`/${ROUTES.ERROR}/:errorCode`}
				component={ErrorPageView}
				exact
			/>
			<Route
				key={ROUTES.OAUTH_CB}
				path={`/${ROUTES.OAUTH_CB}`}
				component={OauthCallbackView}
				exact
			/>
			<Route component={() => <Redirect to={`/${ROUTES.ERROR}/404`} />} />
		</Switch>
	);
};

export default Routes;
