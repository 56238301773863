import styled from "styled-components/macro";
import dragLinesImg from "../../../../../assets/icons/drag-lines.svg";

export const StyledProjectLayout = styled.div`
	margin: -20px; // reset default padding
	display: flex;
	flex-wrap: nowrap;
	/* height: calc(100vh - 70px); */

	// Project Page Container
	> div {
		min-height: calc(100vh - 70px);
		> div:first-of-type {
			overflow: initial;
		}
	}
	.flexlayout__tabset_tabbar_outer {
		background-color: ${({ theme }) => theme.colors.grey400};
	}

	.flexlayout__tabset_tabbar_inner_tab_container_top {
		border-top: none;
	}

	.flexlayout__tabset_tabbar_outer_top {
		border-bottom-color: ${({ theme }) => theme.colors.white};
	}

	.flexlayout__tab_button {
		margin: 0;
		padding: 5px 10px;
	}

	.flexlayout__tab_button--selected {
		background-color: ${({ theme }) => theme.colors.white};
		&:hover {
			background-color: ${({ theme }) => theme.colors.white};
		}
		.flexlayout__tab_button_content {
			color: ${({ theme }) => theme.colors.primary};
		}
	}

	.flexlayout__tab_button_content {
		color: ${({ theme }) => theme.colors.grey700};
		font-size: ${({ theme }) => theme.typography.text.normal};
		font-weight: ${({ theme }) => theme.typography.weight.bold};
		font-family: ${({ theme }) => theme.typography.fontFamily.default};
	}

	.flexlayout__tab_button--unselected {
		color: ${({ theme }) => theme.colors.grey800};

		&:hover {
			background-color: transparent;
			.flexlayout__tab_button_content {
				color: ${({ theme }) => theme.colors.grey900};
			}
		}
	}

	.flexlayout__splitter {
		background-color: #f3f3f3;
		z-index: 1;
	}

	.flexlayout__splitter:hover {
		background-color: #ebebeb;
	}

	.flexlayout__splitter_drag {
		border-radius: 0;
		background-color: #e0e0e0;
		z-index: 1000;
	}

	.flexlayout__splitter {
		&::after {
			background-image: url("${dragLinesImg}");
			position: absolute;
			background-color: transparent;
			content: " ";
			overflow: hidden;
			height: 35px;
			width: 12px;
			overflow: visible;
			z-index: 1;
			opacity: 0.3;
		}

		&:hover {
			&::after {
				opacity: 0.5;
			}
		}

		&.flexlayout__splitter_vert::after {
			bottom: 50%;
			left: 0;
			top: 50%;
			background-repeat: no-repeat;
			background-position: center;
		}
		&.flexlayout__splitter_horz::after {
			right: 0;
			left: 0;
			background-repeat: no-repeat;
			background-position: center;
			-webkit-transform: rotate(-90deg);
			-moz-transform: rotate(-90deg);
			-ms-transform: rotate(-90deg);
			-o-transform: rotate(-90deg);
			transform: rotate(-90deg);
			margin: auto;
			top: -10px;
		}
	}

	.flexlayout__outline_rect {
		position: absolute;
		cursor: move;
		border: 2px dashed ${({ theme }) => theme.colors.secondary};
		box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		z-index: 1000;
		box-sizing: border-box;
	}

	.flexlayout__outline_rect_edge {
		cursor: move;
		border: 2px dashed ${({ theme }) => theme.colors.secondary};
		box-shadow: inset 0 0 60px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		z-index: 1000;
		box-sizing: border-box;
	}

	.flexlayout__edge_rect {
		position: absolute;
		z-index: 1000;
		box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background-color: lightgray;
	}

	.flexlayout__drag_rect {
		position: absolute;
		cursor: move;
		border: 1px solid #aaaaaa;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
		border-radius: 0;
		z-index: 1000;
		box-sizing: border-box;
		background-color: #eeeeee;
		opacity: 0.9;
		text-align: center;

		display: flex;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
		padding: 10px;
		word-wrap: break-word;
	}
`;
