import React, { lazy } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@hegias/ui-components";
import { CookiesProvider } from "react-cookie";

import AppNotifications from "components/AppNotifications/AppNotifications";
import ProjectsProvider from "contexts/Projects";
import FetchProvider from "contexts/FetchProvider/FetchProvider";
import DevToolsProvider from "contexts/DevTools";
import PageTitles from "components/PageTitles";
import Routes from "./Routes";
import TenantProvider from "./contexts/Tenant";
import AuthProvider from "./contexts/Auth";
import OrganizationsProvider from "./contexts/Organizations";
import { ToasterProvider } from "./contexts/Toaster";
import { ModalProvider } from "./contexts/Modal";
import { FullpageSpinner } from "./components";
import { AppGlobalStyle } from "./styles/AppGlobalStyle";

import "./styles/index.css";

const WelcomeModal = lazy(() => import("./views/WelcomePopup"));

const App = () => (
	<Router basename={process.env.PUBLIC_URL}>
		<DevToolsProvider>
			<Providers>
				<AppGlobalStyle />
				<PageTitles />
				<Routes />
				<WelcomeModal />
			</Providers>
		</DevToolsProvider>
	</Router>
);

const Providers: React.FC = ({ children }) => (
	<CookiesProvider>
		<FetchProvider>
			<TenantProvider>
				<AuthProvider>
					<OrganizationsProvider>
						<ProjectsProvider>
							<ThemeProvider>
								<ToasterProvider>
									<ModalProvider>
										<React.Suspense
											fallback={<FullpageSpinner />}
										>
											{children}
											<AppNotifications />
										</React.Suspense>
									</ModalProvider>
								</ToasterProvider>
							</ThemeProvider>
						</ProjectsProvider>
					</OrganizationsProvider>
				</AuthProvider>
			</TenantProvider>
		</FetchProvider>
	</CookiesProvider>
);

export default App;
